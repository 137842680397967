import { makeStyles, spacing, themeSpacings } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      textWrapStyles: {
        whiteSpace: 'normal',
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: spacing(9),
        margin: 0,
      },
      formSectionStyles: {
        paddingTop: themeSpacings.m,
      },
      optionalTextStyles: {
        marginTop: `${themeSpacings.xl} !important`,
      },
      tooltipHost: {
        display: 'block',
      },
    }) as const,
);
