import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation, useSearchParams } from 'react-router-dom';
import i18n from 'i18next';

import { useAppSelector } from 'hooks';
import type { AnalyticsUserActionEvent, UserAction } from './events';
import type { AnalyticsMetadata } from './helper';
import { useMetadata } from './helper';
import type { AnalyticsPageView } from './pages';
import { matchPage } from './pages';

export function initializeGTM(gtmId: string) {
  return TagManager.initialize({
    gtmId,
  });
}

export function useGTM() {
  const metadata = useMetadata();

  return {
    gtmPageView(event: AnalyticsPageView) {
      const data = generatePageViewData(event, metadata);
      if (data) {
        TagManager.dataLayer({
          dataLayer: data,
        });
      }
    },
    gtmAction<A extends keyof UserAction>(event: AnalyticsUserActionEvent<A>) {
      const data = generateActionData(event, metadata);
      if (data) {
        TagManager.dataLayer({
          dataLayer: data,
        });
      }
    },
  };
}

export function useGTMPageView() {
  const location = useLocation();
  const [search] = useSearchParams();
  const auth = useAppSelector((state) => state.auth);
  const metadata = useMetadata();

  const [isVirtual, setIsVirtual] = useState(false);

  const pageView = useCallback(
    (language?: string) => {
      const page = matchPage(location.pathname, { search, auth });
      if (!page) {
        return;
      }

      const data = generatePageViewData(
        { page, isVirtual },
        { ...metadata, language: language ? language : metadata.language },
      );
      if (data) {
        TagManager.dataLayer({
          dataLayer: data,
        });
      }
    },
    [auth, isVirtual, location.pathname, metadata, search],
  );

  useEffect(() => {
    pageView();
    if (!isVirtual) {
      setIsVirtual(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    i18n.off('languageChanged', pageView);
    i18n.on('languageChanged', pageView);
    return () => {
      i18n.off('languageChanged', pageView);
    };
  }, [pageView]);
}

function generatePageViewData(
  { isVirtual = false, page }: AnalyticsPageView,
  { isNative, language, userLocation }: AnalyticsMetadata,
) {
  const pageForGTM = page.replace('/', '|');
  return {
    event: 'metadata_ready',
    page_group: pageForGTM.split('|')[0],
    page_contentpath: pageForGTM,
    page_environment: isNative ? 'embedded' : 'web',
    page_virtual: isVirtual,
    page_language: language,
    page_country: userLocation,
    user_geolocation: userLocation,
  };
}

function generateActionData<A extends keyof UserAction>(
  { action, method }: AnalyticsUserActionEvent<A>,
  { isNative, language, userLocation }: AnalyticsMetadata,
) {
  const actionForGTM = action === 'signUp' ? 'sign_up' : action === 'signIn' ? 'sign_in' : action;
  return {
    event: actionForGTM,
    method,
    page_environment: isNative ? 'embedded' : 'web',
    page_language: language,
    user_geolocation: userLocation,
  };
}
