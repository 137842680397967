import type { IStackStyles, ITextStyles } from '@teamviewer/ui-library';
import { mergeClasses, Stack, TextSecondary } from '@teamviewer/ui-library';

import { spacingTokens, useDividerStyles } from './Divider.styles';

export interface IDividerStyles {
  container?: IStackStyles;
  text?: ITextStyles;
}

interface DividerProps {
  text?: string;
  customStyles?: IDividerStyles;
}

const Divider = ({ text, customStyles }: DividerProps) => {
  const classes = useDividerStyles();
  return (
    <Stack
      horizontal
      className={mergeClasses(classes.dividerWrapperStyles, customStyles?.container)}
      tokens={spacingTokens}
      data-testid="divider"
    >
      {text ? (
        <>
          <Stack.Item className={classes.dividerStyles}>
            <hr className={classes.seperatorStyle} />
          </Stack.Item>
          <Stack.Item>
            <TextSecondary
              className={mergeClasses(classes.dividerTextStyles, customStyles?.text)}
              data-testid="divider-text"
            >
              {text}
            </TextSecondary>
          </Stack.Item>
        </>
      ) : null}
      <Stack.Item className={classes.dividerStyles}>
        <hr className={classes.seperatorStyle} />
      </Stack.Item>
    </Stack>
  );
};

export default Divider;
