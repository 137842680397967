import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  () =>
    ({
      spinnerStyles: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%, -50%)',
      },
    }) as const,
);
