import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { DefaultButton } from '@teamviewer/ui-library';
import Cookies from 'js-cookie';

import { api } from 'api';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useConfirmationToast, useTracking } from 'hooks';
import { ClientType } from 'models';

import { useLoginButtonStyle } from './SocialLoginButton.styles';

export enum IButtonType {
  Google,
  Microsoft,
  Apple,
}

interface ISocialLoginButton {
  buttonType?: IButtonType;
  tabIndex?: number;
  'data-testid'?: string;
}

const SocialLoginButton = ({ buttonType, tabIndex, ...buttonProps }: ISocialLoginButton) => {
  const { t } = useTranslation('sociallogin');
  const { trackAction } = useTracking();
  const [searchParams] = useSearchParams();
  const redirectUri = searchParams.get('redirect_uri');
  const isNativeClient = useInitialServiceConfig().clientType === ClientType.NativeClient;
  const [errorMessage, setErrorMessage] = useState('');

  const classes = useLoginButtonStyle();

  const openSocialSite = (url: string) => {
    const isEmbeddedSocialLogin = Cookies.get('useEmbeddedSocialLogin') === '1';
    const openInNewTab = isNativeClient && !isEmbeddedSocialLogin;

    if (openInNewTab) {
      window.open(url);
    } else {
      window.location.assign(url);
    }
  };

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
    },
    resetAction: () => setErrorMessage(''),
    showError: errorMessage !== '',
  });

  const login = useCallback(() => {
    switch (buttonType) {
      case IButtonType.Google: {
        trackAction('user-account', 'social-login-continue-with-google');

        api
          .get('sociallogin/continuewithgoogle', {
            params: {
              redirectUri,
              isNativeClient,
            },
          })
          .then((response) => {
            if (response?.data?.s) {
              openSocialSite(response.data.d);
              return;
            }
            setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
          })
          .catch((error) => {
            setErrorMessage(error.message);
          });

        break;
      }
      case IButtonType.Microsoft: {
        trackAction('user-account', 'social-login-continue-with-microsoft');

        api
          .get('sociallogin/continuewithmicrosoft', {
            params: {
              redirectUri,
              isNativeClient,
            },
          })
          .then((response) => {
            if (response?.data?.s) {
              openSocialSite(response.data.d);
              return;
            }
            setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
          })
          .catch((error) => {
            setErrorMessage(error.message);
          });

        break;
      }
      case IButtonType.Apple: {
        trackAction('user-account', 'social-login-continue-with-apple');

        api
          .get('sociallogin/continuewithapple', {
            params: {
              redirectUri,
              isNativeClient,
            },
          })
          .then((response) => {
            if (response?.data?.s) {
              openSocialSite(response.data.d);
              return;
            }
            setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
          })
          .catch((error) => {
            setErrorMessage(error.message);
          });

        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectUri, buttonType, isNativeClient]);

  switch (buttonType) {
    case IButtonType.Google: {
      return (
        <DefaultButton
          className={classes.buttonStyles}
          iconProps={{ iconName: 'GoogleIcon' }}
          text={t('continueGoogle')}
          onClick={login}
          tabIndex={tabIndex}
          {...buttonProps}
        />
      );
    }
    case IButtonType.Microsoft: {
      return (
        <DefaultButton
          className={classes.buttonStyles}
          iconProps={{ iconName: 'MicrosoftIcon' }}
          text={t('continueMicrosoft')}
          onClick={login}
          tabIndex={tabIndex}
          {...buttonProps}
        />
      );
    }
    case IButtonType.Apple: {
      return (
        <DefaultButton
          className={classes.buttonStyles}
          iconProps={{ iconName: 'AppleIcon' }}
          text={t('continueApple')}
          onClick={login}
          tabIndex={tabIndex}
          {...buttonProps}
        />
      );
    }
    default: {
      return <DefaultButton tabIndex={tabIndex} {...buttonProps} />;
    }
  }
};

export default SocialLoginButton;
