import type { IStackStyles } from '@teamviewer/ui-library';
import { spacing, themeBorderRadius, themeColors, themeSpacings } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

const boxShadow = '0px 1.2px 3.6px rgba(0, 0, 0, 0.05), 0px 6.4px 14.4px rgba(0, 0, 0, 0.05)';
export const bannerWrapperStyles: Partial<IStackStyles> = {
  root: {
    flexBasis: '292px',
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      width: '100%',
      flexBasis: 'auto',
    },
  },
};

export const modalWrapperStyles: Partial<IStackStyles> = {
  root: {
    width: '818px',
    minHeight: '560px',
    borderRadius: `${themeBorderRadius.l}`,
    overflow: 'hidden',
    margin: `${themeSpacings.l} auto`,
    boxShadow,
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      margin: `${themeSpacings.l} auto`,
      minHeight: 'auto',
      minWidth: 'auto',
      maxWidth: '548px',
      width: '100%',
    },
  },
};

export const mainStyles: Partial<IStackStyles> = {
  root: {
    padding: `0 ${themeSpacings.m}`,
  },
};

export const formWrapperStyles: Partial<IStackStyles> = {
  root: {
    flexGrow: 1,
    flexBasis: '526px',
    position: 'relative',
    padding: `${spacing(10)} ${spacing(18)} ${spacing(12)} ${spacing(18)}`,
    backgroundColor: themeColors.type.typeWhite,
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      padding: `${spacing(12)} ${spacing(6)}`,
      width: '100%',
      flexBasis: 'auto',
    },
  },
};
