import { Buffer } from 'buffer';
import { BigInteger } from 'jsbn';

import { hash } from './Parameters';

export async function H(...args: Uint8Array[]) {
  const data = UpdateHashData(0, ...args);

  const dataHashBuffer = await window.crypto.subtle.digest(hash, data);
  const dataHashByteArray = new Uint8Array(dataHashBuffer);

  return dataHashByteArray;
}

function UpdateHashData(index: number, ...args: Uint8Array[]): Uint8Array {
  const arg = args[index]!;

  if (index === args.length - 1) {
    return arg;
  }

  const data: Uint8Array = UpdateHashData(index + 1, ...args);
  const newData: Uint8Array = new Uint8Array(arg.length + data.length);
  newData.set(arg);
  newData.set(data, arg.length);

  return newData;
}

export function generateRandomBytes(length: number) {
  const byteArray = new Uint8Array(length);
  const random = window.crypto.getRandomValues(byteArray);

  return random;
}

export function Uint8ArrayToBigInteger(unit8Array: Uint8Array) {
  const buffer = Buffer.from(unit8Array);
  const hex = buffer.toString('hex');

  return new BigInteger(hex, 16);
}

export function BigIntegerToUint8Array(number: BigInteger) {
  let hex = number.toString(16);

  // If the conversion results in uneven hex it'll add 0 at the beginning of the hex to avoid
  // truncate the last digit from the Buffer library
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }

  const buffer = Buffer.from(hex, 'hex');

  return new Uint8Array(buffer);
}

export function Base64ToBigInteger(value: string) {
  const buffer = Buffer.from(value, 'base64');
  const hex = buffer.toString('hex');

  return new BigInteger(hex, 16);
}

export function BigIntegerToBase64(number: BigInteger) {
  let hex = number.toString(16);

  // If the conversion results in uneven hex it'll add 0 at the beginning of the hex to avoid
  // truncate the last digit from the Buffer library
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }

  const buffer = Buffer.from(hex, 'hex');

  return buffer.toString('base64');
}

export function BigIntegerToUtf8(number: BigInteger) {
  let hex = number.toString(16);

  // If the conversion results in uneven hex it'll add 0 at the beginning of the hex to avoid
  // truncate the last digit from the Buffer library
  if (hex.length % 2 !== 0) {
    hex = `0${hex}`;
  }

  const buffer = Buffer.from(hex, 'hex');

  return buffer.toString('utf-8');
}

export function Base64ToBase64url(input: string) {
  // Replace non-url compatible chars with base64url standard chars and remove leading =
  return input.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/g, '');
}

export function Base64ToUint8Array(input: string) {
  if (input.length === 0) {
    return new Uint8Array();
  }

  return Uint8Array.from(window.atob(input), (v) => v.charCodeAt(0));
}

export async function getKeyModulusChunksCount() {
  const modulusSizeInBits = 1024;
  const hashLengthInBytes = 20;

  return modulusSizeInBits / 8 - 2 * hashLengthInBytes - 2;
}

export function leftPadByteArray(verifier: Uint8Array, targetedSize: number): Uint8Array {
  if (verifier.length >= targetedSize) {
    return verifier;
  }

  const paddedArray = new Uint8Array(targetedSize);
  paddedArray.set(verifier, targetedSize - verifier.length);

  return paddedArray;
}
