import type React from 'react';
import type { ITextStyles } from '@teamviewer/ui-library';
import { makeStyles, spacing, themeColors, themeSpacings, themeTypography } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';
import { BannerColor } from './Banner.model';

const getBannerColor = (bannerColor: BannerColor) => {
  switch (bannerColor) {
    case BannerColor.secondary:
      return themeColors.theme.themeSecondary;
    case BannerColor.darker:
      return themeColors.theme.themeDarker;
    case BannerColor.darkerAlt:
      return themeColors.theme.themeDarkerAlt;
  }
};

export const bannerWrapperStyles = (bannerColor: BannerColor) => ({
  root: {
    backgroundColor: getBannerColor(bannerColor),
    paddingTop: spacing(8),
    paddingBottom: spacing(8),
    width: '100%',
    height: '100%',
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      padding: spacing(8),
    },
  },
});

export const bannerImgStyle: React.CSSProperties = {
  width: '292px',
  height: '268px',
};

export const logoImgStyle: React.CSSProperties = {
  marginTop: themeSpacings.m,
  marginBottom: themeSpacings.m,
};

export const bannerTitleStyles: ITextStyles = {
  root: {
    padding: `${spacing(9)} ${spacing(8)} ${themeSpacings.m} ${spacing(8)}`,
    color: themeColors.type.typeWhite,
    fontSize: themeTypography.fontSizes.size20,
    fontWeight: themeTypography.fontWeights.semibold,
    textAlign: 'center',
    lineHeight: '28px',
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      padding: '0',
      width: '100%',
      marginBottom: themeSpacings.s,
    },
  },
};

export const bannerSubtitleStyles: ITextStyles = {
  root: {
    padding: `0 ${spacing(9)} 0 ${spacing(9)}`,
    color: themeColors.type.typeWhite,
    textAlign: 'center',
    lineHeight: '20px',
    opacity: '90%',
    whiteSpace: 'break-spaces',
    [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
      padding: '0',
    },
  },
};

export const useTextStyles = makeStyles(
  ({ tokens }) =>
    ({
      textUnderlineStyles: {
        color: tokens.colors.type.typeWhite,
        lineHeight: '20px',
        position: 'relative',
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.regular,
        selectors: {
          ':after': {
            content: '""',
            position: 'absolute',
            bottom: '-4px',
            left: 0,
            height: '3px',
            width: '100%',
            border: `solid 1px ${tokens.colors.type.typeWhite}`,
            borderColor: `${tokens.colors.type.typeWhite} transparent transparent transparent`,
            borderRadius: '50%',
          },
        },
      },
    }) as const,
);
