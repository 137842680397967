import { makeStyles } from '@teamviewer/ui-library';

export const useIconButtonStyles = makeStyles(
  ({ tokens }) =>
    ({
      container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        padding: '32px',
        borderRadius: '16px',
        maxWidth: '900px',
      },
      header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
      body: {
        marginTop: '16px',
      },
      iconStyle: {
        '.ms-Button-flexContainer i': {
          fontSize: tokens.typography.fontSizes.size16,
        },
      },
    }) as const,
);
