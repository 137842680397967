import { useCallback } from 'react';
import type { NavigateOptions, To } from 'react-router-dom';
import { parsePath, useNavigate, useSearchParams } from 'react-router-dom';

import { useConfigManager } from 'config/useConfigManager';

interface INavigateWithRedirect {
  (to: To, options?: NavigateOptions): void;
}
const useNavigateWithRedirect = (): INavigateWithRedirect => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const configManager = useConfigManager();

  return useCallback(
    (to, options) => {
      const redirectURI = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
      const defaultSearchParams = new URLSearchParams();
      defaultSearchParams.set('redirect_uri', redirectURI);
      const newTo = typeof to === 'string' ? parsePath(to) : to;
      const newSearchParams = new URLSearchParams(newTo.search?.toString());
      newTo.search = defaultSearchParams.merge(newSearchParams).toString();
      return navigate(newTo, options);
    },
    [configManager, navigate, searchParams],
  );
};

export default useNavigateWithRedirect;
