import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';

import { api } from 'api';
import epic from './rootEpic';
import reducer from './rootReducer';

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, EpicDeps>({
  dependencies: { api },
});

export function setupStore(preloadedState?: Partial<RootState>) {
  const store = configureStore({
    reducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(epicMiddleware),
  });

  epicMiddleware.run(epic);

  return store;
}

export default setupStore();
