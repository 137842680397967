export enum OperatingSystem {
  NotDetected,
  Android,
  IOS,
  Linux,
  MacOS,
  Windows,
}

export enum Context {
  NotDetected,
  NativeContainer,
  WebBrowser,
}
