import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
      },
      optionalTextStyles: {
        marginTop: tokens.spacings.l,
      },
      checkboxStyles: {
        marginTop: tokens.spacings.m,
        marginBottom: tokens.spacings.m,
      },
    }) as const,
);
