import type { IStackStyles, IStackTokens } from '@teamviewer/ui-library';
import { makeStyles, themeSpacings } from '@teamviewer/ui-library';

export const dividerWrapperStyles: IStackStyles = {
  root: {
    padding: `${themeSpacings.m} 0`,
  },
};

export const useDividerStyles = makeStyles(
  ({ tokens }) =>
    ({
      dividerStyles: {
        backgroundColor: tokens.colors.dividers.contentDivider,
        color: tokens.colors.dividers.contentDivider,
        height: '1px',
        marginTop: 'auto',
        marginBottom: 'auto',
        flex: 1,
      },
      dividerWrapperStyles: {
        padding: `${tokens.spacings.m} 0`,
      },
      dividerTextStyles: {
        fontSize: tokens.typography.fontSizes.size12,
      },
      seperatorStyle: {
        border: 'none',
      },
    }) as const,
);

export const spacingTokens: IStackTokens = {
  childrenGap: themeSpacings.s,
};
