import axios from 'axios';

// We know the backend is always at the root path, while the frontend is hosted under `/Frontend/(...)`.
const API_URL = '/';

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json, text/javascript, */*; q=0.01',
  },
});

export default api;
