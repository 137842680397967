import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Image, Stack, TextSecondary } from '@teamviewer/ui-library';

import { FullPageLayout } from 'components';

import { useStyles } from './NotFound.styles';

const NotFound = () => {
  const { t } = useTranslation('notfound');
  const navigate = useNavigate();
  const { containerStyles, helpStyles, imgStyle } = useStyles();

  return (
    <FullPageLayout data-testid="not-found-container">
      <Stack className={containerStyles}>
        <Image
          src="/assets/not_found_error.svg"
          className={imgStyle}
          alt={t('imgAccessibility')}
          data-testid="not-found-image"
        />
        <FullPageLayout.Title data-testid="not-found-title">{t('title')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="not-found-subtitle">{t('explanation')}</FullPageLayout.Description>
        <Stack>
          <TextSecondary data-testid="not-found-help-text" className={helpStyles}>
            {t('help')}
          </TextSecondary>
        </Stack>
        <FullPageLayout.Button data-testid="not-found-turn-btn" isPrimary={false} onClick={() => navigate(-1)}>
          {t('buttonText')}
        </FullPageLayout.Button>
      </Stack>
    </FullPageLayout>
  );
};

export default NotFound;
