import type { TFunction } from 'i18next';

import { cascadeValidationFunc } from './cascadeValidationFunc';
import type { IValidationRules, ValidateProp, ValidationFunc } from './validation-rules';

/**
 * Decorates an existing factory for a *validation rules object* (`IValidationRules`) to include an additional validation check against links
 * @param origFactory original validation rules object factory to be decorated
 * @param invalidMsgId the string ID used for translation on failing validation
 * @returns new decorated validation rules object factory
 */
export function withValidationAgainstLinks(
  origFactory: ValidationRulesFactory,
  invalidMsgId: string,
): ValidationRulesFactory {
  return (t: TFunction): IValidationRules => {
    const origValRules = origFactory(t);
    const decoratedValRules: IValidationRules = {
      ...origValRules,
      validate: addValidationAgainstLinks(t, origValRules.validate, invalidMsgId),
    };
    return decoratedValRules;
  };
}

type ValidationRulesFactory = (t: TFunction) => IValidationRules;

/**
 *
 * @param t translation function for translating the string ID
 * @param val `validate` property of validation rules object
 * @param invalidMsgId the string ID used for translation on faling validation
 * @returns
 */
function addValidationAgainstLinks(
  t: TFunction,
  val: ValidateProp,
  invalidMsgId: string,
): Exclude<ValidateProp, undefined> {
  return cascadeValidationFunc(val, getValidationAgainstLinksFunc(t, invalidMsgId), 'hasNoLinks');
}

export const getValidationAgainstLinksFunc = (t: TFunction, stringId: string): ValidationFunc => {
  const matcherFor = (regexp: RegExp) => regexp.test.bind(regexp);
  const invalidationCheckFor = (isInvalid: ReturnType<typeof matcherFor>, message: string) => (input: string) =>
    !isInvalid(input) || t(message).toString();

  const checkAgainst = (re: RegExp) => invalidationCheckFor(matcherFor(re), `${stringId}`);

  // add checks to perform here
  const checks = [checkAgainst(wwwDomainRegex), ...schemaRegexps.map((re) => checkAgainst(re))];

  // combine all check functions into a single one
  return (input: string) => checks.reduce((res: string | true, check) => (res !== true ? res : check(input)), true);
};

const wwwDomainRegex = /^(.*[^a-z]+)?www\.\S+\.\S+/iu;

const schemaRegexps = ['http', 'https', 'mailto', 'ftp', 'ssh', 'file'].map((schema) =>
  RegExp(`^(.*[^a-z]+)?${schema}://`, 'iu'),
);
