import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { api } from 'api';
import type { AjaxResponse } from 'models/types/AjaxResponse';
import { mapToAjaxResponseObject } from 'utils/utils';

type AuthorizeDeviceDataType = {
  token: string;
  version: string;
};

export const authorizeDevice = createAsyncThunk<AjaxResponse, AuthorizeDeviceDataType>(
  'trustdevice/authorizedevice',
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post('/device/authorizedevice', data);

      return mapToAjaxResponseObject(response.data);
    } catch (error) {
      return rejectWithValue(axios.isAxiosError(error) && error.message);
    }
  },
);
