import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { Translatable } from 'utils/translation';
import type { ITrustDeviceState } from '../types';
import { TrustDeviceStatus } from '../types';
import { authorizeDevice } from './trustDeviceAsyncActions';

const initialState: ITrustDeviceState = {
  status: TrustDeviceStatus.Initial,
  error: {
    isError: false,
  },
};

const trustDeviceSlice = createSlice({
  name: 'trustdevice',
  initialState,
  reducers: {
    setTrustDeviceStatus(state, { payload }: PayloadAction<TrustDeviceStatus>) {
      state.status = payload;
    },
    declineDevice(state) {
      state.status = TrustDeviceStatus.Declined;
      state.error = {
        isError: true,
        messageCreator: Translatable.fromStringId('trustdevice:trustDeviceDeclined'),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authorizeDevice.pending, (state, _action) => {
      state.status = TrustDeviceStatus.Verifying;
    });
    builder.addCase(authorizeDevice.fulfilled, (state, { payload }) => {
      if (payload.isSuccess) {
        state.status = TrustDeviceStatus.Successed;
      } else {
        state.status = TrustDeviceStatus.Failed;
        state.error = {
          isError: true,
          responseCode: payload.responseCode,
          errorCode: payload.errorCode,
          responseCodeType: payload.responseCodeType,
          messageCreator: generateStringIdForErrorMessage({
            responseCodeType: payload.responseCodeType,
            responseCode: payload.responseCode,
          }),
        };
      }
    });
    builder.addCase(authorizeDevice.rejected, (state, _action) => {
      state.error = {
        isError: true,
        messageCreator: Translatable.fromStringId('error:unexpectedError'),
      };
    });
  },
});

export const trustDeviceActions = trustDeviceSlice.actions;
export const trustDeviceReducer = trustDeviceSlice.reducer;
