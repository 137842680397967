import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

interface IAccountState {
  showStayTunedDialog: boolean;
}

const stayTunedDialogInitialState: IAccountState = {
  showStayTunedDialog: false,
};

const stayTunedDialogSlice = createSlice({
  name: 'stayTunedDialog',
  initialState: stayTunedDialogInitialState,
  reducers: {
    setShowStayTunedDialog: (state, action: PayloadAction<boolean>) => {
      state.showStayTunedDialog = action.payload;
    },
  },
});

export const { setShowStayTunedDialog } = stayTunedDialogSlice.actions;

export default stayTunedDialogSlice.reducer;
