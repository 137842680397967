import { TextButton, TextPrimary } from '@teamviewer/ui-library';

import { LinkWithRedirect } from 'components';

export function isFalsyOrWhitespace(value: undefined | null): true;
export function isFalsyOrWhitespace(value?: string): boolean;
export function isFalsyOrWhitespace(value?: string | null): boolean {
  if (!value) {
    return true;
  }
  return value.trim() === '';
}

export const splitAndStyleString = (
  str: string,
  regex: RegExp,
  className?: string,
  link?: string,
  tabIndex?: number,
  target = '_blank',
  testId?: string,
  disabled = false,
): JSX.Element => (
  <>
    {str.split(regex).map((item, index) => {
      if (index % 2) {
        if (link) {
          const dataTestId = testId ? `${testId}-link` : null;
          return (
            <LinkWithRedirect
              // eslint-disable-next-line react/no-array-index-key -- The order will stay the same
              key={index}
              href={link}
              target={target}
              className={className}
              tabIndex={tabIndex}
              disabled={disabled}
              data-testid={dataTestId}
            >
              {item}
            </LinkWithRedirect>
          );
        }
        const dataTestId = testId ? `${testId}-text` : null;
        return (
          // eslint-disable-next-line react/no-array-index-key -- The order will stay the same
          <TextPrimary key={index} className={className} data-testid={dataTestId}>
            {item}
          </TextPrimary>
        );
      } else {
        return item;
      }
    })}
  </>
);

export const splitAndStyleStringWithClickHandler = (
  str: string,
  regex: RegExp,
  className: string,
  onClick: any,
  tabIndex?: number,
  testId?: string,
  disabled = false,
): JSX.Element => (
  <>
    {str.split(regex).map((item, index) => {
      const dataTestId = testId ? `${testId}-link` : null;
      if (index % 2) {
        return (
          <TextButton
            // eslint-disable-next-line react/no-array-index-key -- The order will stay the same
            key={index}
            onClick={onClick}
            className={className}
            data-testid={dataTestId}
            tabIndex={tabIndex}
            disabled={disabled}
            as="a"
          >
            {item}
          </TextButton>
        );
      } else {
        return item;
      }
    })}
  </>
);
