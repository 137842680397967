export enum OAuthResponseCode {
  InvalidClientId = 0,
  NotFoundClientId = 1,
  RedirectUriMismatch = 2,
  RedirectUriWithoutSsl = 3,
  RedirectUriInvalid = 4,
  TrialLicenseFailed = 5,
  RequestedScopeExceedsClientScope = 6,
  PermissionsNotAllowedImplicitGrantFlow = 7,
  GrantAccessNotAllowed = 8,
}
