import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
  ICaptchaResult,
  IEncryptedSecretKeyWithPasswordResult,
  IGetAccountResetPasswordDetailsResponse,
  ResetPasswordType,
} from 'models';

import { api } from 'api';
import { ResetPasswordStatus } from 'models';
import * as Auth from 'modules/Auth/Auth';

export const sendForgotPasswordEmailAction = createAsyncThunk(
  'resetPassword/sendEmail',
  async (
    payload: { passwordType: ResetPasswordType; email: string; redirectUri: string } & ICaptchaResult,
    { rejectWithValue },
  ) => {
    const { passwordType, email, redirectUri, 'captcha-response': captchaResponse } = payload;
    try {
      const response = await api.post('/password/forgotpassword', {
        passwordType,
        email,
        redirectUri,
        'captcha-response': captchaResponse,
      });

      if (!response.data.s) {
        return rejectWithValue({
          message: response?.data?.m,
          responseCodeType: response?.data?.t,
          responseCode: response?.data?.e,
          errorCode: response?.data?.ec,
        });
      }

      return {
        status: ResetPasswordStatus.EmailSent,
      };
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const checkAccountRecoveryStatusAction = createAsyncThunk(
  'resetPassword/accountRecoveryStatus',
  async (payload: { token: string; language: string }, { rejectWithValue }) => {
    const { token, language } = payload;

    if (token.length === 0 || language.length === 0) {
      return rejectWithValue({
        status: ResetPasswordStatus.InvalidToken,
      });
    }

    try {
      const response = await api.post('/password/checkaccountrecoverystatus', {
        token,
        lng: language,
      });

      if (!response?.data?.s) {
        return rejectWithValue({
          status: ResetPasswordStatus.InvalidToken,
          responseCodeType: response?.data?.t,
          responseCode: response?.data?.e,
          errorCode: response?.data?.ec,
          message: response?.data.m,
        });
      }

      return {
        status: ResetPasswordStatus.ValidToken,
        isRecoveryCodeRequired: response?.data?.d?.isShelteredKeyEncrypted,
      };
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const resetPasswordAction = createAsyncThunk(
  'resetPassword/resetPassword',
  async (payload: { token: string; newPassword: string; recoveryCode: string }, { rejectWithValue }) => {
    const { token, newPassword, recoveryCode } = payload;

    try {
      // step 1:
      const step1Response = await api.post('/password/getaccountresetpassworddetails', {
        token,
      });

      if (!step1Response?.data?.s) {
        return rejectWithValue({
          status: ResetPasswordStatus.Failed,
          message: step1Response?.data?.m,
          responseCodeType: step1Response?.data?.t,
          responseCode: step1Response?.data?.e,
          errorCode: step1Response?.data?.ec,
        });
      }

      if (step1Response?.data?.d?.IsTfaRequired) {
        return {
          status: ResetPasswordStatus.TfaSecurityCodeRequired,
        };
      }

      const step1ResponseData: IGetAccountResetPasswordDetailsResponse = {
        AccountVersion: step1Response.data.d.AccountVersion,
        AccountKey: step1Response.data.d.AccountKey,
        AccountId: step1Response.data.d.AccountId,
      };

      // step 2:
      let encryptedSecretKey: IEncryptedSecretKeyWithPasswordResult;
      try {
        encryptedSecretKey = await Auth.encryptSecretKeyForPasswordReset(
          step1ResponseData.AccountId,
          newPassword,
          step1ResponseData.AccountKey,
          recoveryCode,
        );
      } catch (error: any) {
        return rejectWithValue({
          status: ResetPasswordStatus.AuthenticationFailed,
          message: 'Failed to complete authentication.',
        });
      }

      const step2Response = await api.post('/password/accountresetpassword', {
        AccountKey: encryptedSecretKey.AccountKey,
        Salt: encryptedSecretKey.NewPasswordSalt,
        Verifier: encryptedSecretKey.NewPasswordVerifier,
        Token: token,
        AccountId: step1ResponseData.AccountId,
        AccountVersion: step1ResponseData.AccountVersion,
      });

      if (!step2Response?.data?.s) {
        return rejectWithValue({
          status: ResetPasswordStatus.Failed,
          message: step2Response.data.m,
          responseCodeType: step2Response?.data?.t,
          responseCode: step2Response?.data?.e,
          errorCode: step2Response?.data?.ec,
        });
      }

      return {
        status: ResetPasswordStatus.Successed,
      };
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);

export const resetPasswordTfaAction = createAsyncThunk(
  'resetPassword/resetPasswordTfa',
  async (payload: { tfaCode: string; token: string; password: string; recoveryCode?: string }, { rejectWithValue }) => {
    const { tfaCode, token, password, recoveryCode } = payload;

    try {
      // step 1:
      const step1Response = await api.post('/password/getaccountresetpassworddetailswithtfa', {
        Token: token,
        TfaCode: tfaCode,
      });

      if (!step1Response?.data?.s) {
        return rejectWithValue({
          status: ResetPasswordStatus.Failed,
          errorCode: step1Response?.data?.ec,
          responseCodeType: step1Response?.data?.t,
          responseCode: step1Response?.data?.e,
          message: step1Response?.data?.m,
        });
      }

      const step1ResponseData: IGetAccountResetPasswordDetailsResponse = {
        AccountVersion: step1Response.data.d.AccountVersion,
        AccountKey: step1Response.data.d.AccountKey,
        AccountId: step1Response.data.d.AccountId,
      };

      // step 2:
      let encryptedSecretKey: IEncryptedSecretKeyWithPasswordResult;
      try {
        encryptedSecretKey = await Auth.encryptSecretKeyForPasswordReset(
          step1ResponseData.AccountId,
          password,
          step1ResponseData.AccountKey,
          recoveryCode,
        );
      } catch (error: any) {
        return rejectWithValue({
          status: ResetPasswordStatus.AuthenticationFailed,
          message: 'Failed to complete authentication.',
        });
      }

      const step2Response = await api.post('/password/accountresetpasswordwithtfa', {
        AccountKey: encryptedSecretKey.AccountKey,
        Salt: encryptedSecretKey.NewPasswordSalt,
        Verifier: encryptedSecretKey.NewPasswordVerifier,
        Token: token,
        AccountId: step1ResponseData.AccountId,
        AccountVersion: step1ResponseData.AccountVersion,
        TfaCode: tfaCode,
      });

      if (!step2Response?.data?.s) {
        return rejectWithValue({
          status: ResetPasswordStatus.Failed,
          message: step2Response?.data?.m,
          responseCodeType: step2Response?.data?.t,
          responseCode: step2Response?.data?.e,
          errorCode: step2Response?.data?.ec,
        });
      }

      return { status: ResetPasswordStatus.Successed };
    } catch (error: any) {
      return rejectWithValue({
        message: error.message,
      });
    }
  },
);
