import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { TFunction } from 'i18next';

import { identityT } from 'utils/translation';
import type { BackendError } from '../models';
import { defaultErrorMsgId } from '../utils/ErrorPayloadHandling';
import useAppSelector from './useAppSelector';

export type { BackendError };

type First<T extends unknown[]> = T[0];
type useAppSelectorFuncParamType = First<Parameters<typeof useAppSelector>>;
type AppState = First<Parameters<useAppSelectorFuncParamType>>;

type Options = {
  /**
   * the `Translatable` that will be used if no suitable mapping was found from the received response codes
   */
  defaultMessageCreator?: (t: TFunction) => string;
};

/**
 * Hook for generating a user-suitable error message derived from the backend response codes
 * @param errorSelector selector for the error state from the application state (similar to `useAppSelector`'s selector)
 * @param param1 options
 * @returns object with properties `errorMessage`, `setErrorMessage` and `errorCode`
 */
export default function useErrorMessage<E extends BackendError>(
  errorSelector: (state: AppState) => E,
  { defaultMessageCreator }: Options = {},
) {
  const error = useAppSelector(errorSelector);
  return useErrorMessageInternal(error, defaultMessageCreator);
}

export function useErrorMessageInternal(
  error: BackendError,
  defaultMessageCreator = (t: TFunction) => t(defaultErrorMsgId),
) {
  const [state, setState] = useState<State>({ mode: 'compute' });

  const { t } = useTranslation(['error', 'backendResponse']);

  const [errorMessage, errorCode] = useMemo((): [string, string] => {
    if (error.isError) {
      // possibly new error
      switch (state.mode) {
        case 'compute':
          return computeErrorMessage(error, t, defaultMessageCreator);
        case 'overriden':
          if (state.savedError !== error) {
            setState({ mode: 'compute' });
            return computeErrorMessage(error, t, defaultMessageCreator);
          } else {
            return [state.errorMessage, ''];
          }
      }
    } else {
      // no error
      if (state.mode === 'compute') {
        // just clear the error
        return ['', ''];
      } else {
        // keep the manually set error until it's clear manually
        return [state.errorMessage, ''];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, error, t]);

  const setErrorMessage = useCallback(
    (errorMessage: string) => {
      setState({ mode: 'overriden', errorMessage, savedError: error });
    },

    [error],
  );

  return {
    errorMessage,
    setErrorMessage,
    errorCode,
  };
}

function computeErrorMessage(
  error: Extract<BackendError, { isError: true }>,
  t: TFunction,
  defaultMessageCreator: (t: TFunction) => string,
): [string, string] {
  const { messageCreator, errorCode } = error;

  const stringId = messageCreator?.(identityT) ?? '';
  const translatedErrorMessage =
    stringId && stringId !== defaultErrorMsgId ? messageCreator(t) : defaultMessageCreator(t);

  return [translatedErrorMessage, errorCode ?? ''];
}

type Compute = { mode: 'compute' };
type Overriden = { mode: 'overriden'; errorMessage: string; savedError: BackendError };
type State = Compute | Overriden;
