import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { useConfigManager } from 'config/useConfigManager';
import { useConfirmationToast, useErrorMessage } from 'hooks';
import RegisterAccountNameAndEmail from './RegisterAccountNameAndEmail/RegisterAccountNameAndEmail';
import RegisterAccountPassword from './RegisterAccountPassword/RegisterAccountPassword';

enum RegisterAccountStep {
  EmailAndName,
  Password,
}

const RegisterAccount = () => {
  const { t } = useTranslation(['createAccount', 'common', 'validation', 'login', 'banner', 'toastMessage']);

  const [searchParams, setSearchParams] = useSearchParams();
  const [registerAccountStep, setRegisterAccountStep] = useState<RegisterAccountStep>(RegisterAccountStep.EmailAndName);
  const { errorMessage, setErrorMessage, errorCode } = useErrorMessage((state) => state.registerAccount.error);
  const configManager = useConfigManager();

  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const email = searchParams.get('email') || '';
  const name = searchParams.get('name') || '';

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    resetAction: () => setErrorMessage(''),
    showError: errorMessage !== '',
  });

  useEffect(() => {
    if (email && name) {
      setRegisterAccountStep(RegisterAccountStep.Password);
    }
  }, [email, name]);

  switch (registerAccountStep) {
    case RegisterAccountStep.EmailAndName:
      return (
        <RegisterAccountNameAndEmail
          t={t}
          email={searchParams.get('email')}
          name={searchParams.get('name')}
          onContinue={(email: string, name: string) => {
            setSearchParams({ redirect_uri: redirectUri, email, name });
            setRegisterAccountStep(RegisterAccountStep.Password);
          }}
        />
      );
    case RegisterAccountStep.Password:
      return (
        <RegisterAccountPassword
          t={t}
          email={email}
          name={name}
          onGoBack={() => setRegisterAccountStep(RegisterAccountStep.EmailAndName)}
        />
      );
  }
};

export default RegisterAccount;
