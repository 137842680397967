import { makeStyles, themeSpacings } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        whiteSpace: 'normal',
        margin: 0,
      },
      subtitleStyles: {
        paddingTop: '8px',
        color: tokens.colors.type.typeSecondary,
        whiteSpace: 'normal',
      },
      optionalTextStyles: {
        marginTop: `${themeSpacings.l}`,
      },
      tooltipHost: {
        display: 'block',
      },
    }) as const,
);
