import { createAsyncThunk } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import type { TFunction } from 'i18next';
import type {
  IAccountCreateResponse,
  IAccountRegisterFormModel,
  IAccountRegisterRequestModel,
  IAccountSetInitialPasswordModel,
  IRegisterAccountState,
} from 'models';

import { api } from 'api';
import { getConfigManager } from 'config/config-manager';
import { RegisterAccountStatus } from 'models';
import * as Auth from 'modules/Auth/Auth';
import { Translatable } from 'utils/translation';

export const registerAccountAsyncAction = createAsyncThunk<
  IRegisterAccountState,
  { payload: IAccountRegisterFormModel; t: TFunction },
  { rejectValue: IRegisterAccountState }
>('auth/register', async ({ payload }, { rejectWithValue }) => {
  const configManager = await getConfigManager();

  try {
    // step 1: create account
    const { privateKey, publicKey, groupKey, shelteredKey, meetingKey } = await Auth.generateAccountKeysOnRegister(
      payload.Password,
      configManager.get('masterPublicKey'),
    );

    const accountRegisterSrp: IAccountRegisterRequestModel = {
      DisplayName: payload.DisplayName,
      Email: payload.Email,
      SubscribeNewsletter: true,
      PrivateKey: privateKey,
      PublicKey: publicKey,
      GroupKey: groupKey,
      ShelteredKey: shelteredKey,
      MeetingKey: meetingKey,
      SocialLoginIdentityToken: payload.SocialLoginIdentityToken,
      RedirectUri: payload.RedirectUri,
      'invisiblecaptcha-response': payload['invisiblecaptcha-response'],
    };

    const createAccountResponse = await api.post('/account/createaccount', accountRegisterSrp);
    if (!createAccountResponse?.data?.s) {
      return rejectWithValue({
        email: payload.Email,
        registerAccountStatus: RegisterAccountStatus.Failed,
        error: {
          isError: true,
          errorCode: createAccountResponse?.data?.ec,
          messageCreator: Translatable.tryLiterallyOrElse(createAccountResponse?.data?.m, () => ''),
          responseCodeType: createAccountResponse?.data?.t,
          responseCode: createAccountResponse?.data?.e,
          embeddedStringId: createAccountResponse?.data?.e === 5 ? 'invalidEmailPassword' : '',
        },
      });
    }

    // step 2: set initial password
    const createAccountResponseData: IAccountCreateResponse = createAccountResponse.data.d;
    const { salt: passwordSalt, verifier: passwordVerifier } = await Auth.generateAccountPasswordSaltAndVerifier(
      createAccountResponseData.AccountId,
      payload.Password,
    );

    const setInitialPasswordPayload: IAccountSetInitialPasswordModel = {
      AccountId: createAccountResponseData.AccountId,
      PasswordToken: createAccountResponseData.PasswordToken,
      PasswordSalt: Buffer.from(passwordSalt).toString('base64'),
      PasswordVerifier: Buffer.from(passwordVerifier).toString('base64'),
      TargetInstanceNumber: createAccountResponseData.TargetInstanceNumber,
    };

    const setInitialPasswordResponse = await api.post('/account/setinitialpassword', setInitialPasswordPayload);
    if (!setInitialPasswordResponse?.data?.s) {
      return rejectWithValue({
        email: payload.Email,
        registerAccountStatus: RegisterAccountStatus.Failed,
        error: {
          isError: true,
          embeddedStringId: 'failedToSetAccountInitialPassword',
          messageCreator: Translatable.tryLiterallyOrElse(setInitialPasswordResponse?.data?.m, () => ''),
          responseCodeType: setInitialPasswordResponse?.data?.t,
          responseCode: setInitialPasswordResponse?.data?.e,
          errorCode: setInitialPasswordResponse?.data?.ec,
        },
      });
    }

    return {
      registerAccountStatus: RegisterAccountStatus.Successed,
      email: payload.Email,
      error: {
        isError: false,
        message: '',
      },
    };
  } catch (err) {
    return rejectWithValue({
      email: payload.Email,
      registerAccountStatus: RegisterAccountStatus.Failed,
      error: {
        isError: true,
        embeddedStringId: 'failedToCompleteAccountCreation',
        messageCreator: () => '',
      },
    });
  }
});
