export enum ResponseCodeType {
  Unspecified = 0,
  Common = 1,
  Login = 2,
  Social = 3,
  Service = 4,
  Captcha = 5,
  Master = 6,
  OAuth = 7,
}
