import { Buffer } from 'buffer';

export enum BCommandClass {
  SsoDataCommand = 66,
}

// #region CommandTypes
export enum SsoDataCommandTypes {
  LoginTokenDataSerialized = 204,
}
// #endregion

export interface BCommand {
  CommandClass: BCommandClass;
  CommandType: number;
  Parameters: Map<number, Uint8Array>;
}

export function convertBCommandToByteArray(command: BCommand): Uint8Array {
  const commandClass = command.CommandClass;
  const commandType = command.CommandType;
  const parameters = command.Parameters;

  parameters.set(254, new Uint8Array([commandClass]));

  let data = new Array<any>();

  data.push(commandType);
  data.push(parameters.size);

  parameters.forEach((v, k) => {
    data.push(k);

    const paramLenBuf = Buffer.allocUnsafe(4);
    paramLenBuf.writeUInt32LE(v.length);

    data = data.concat(...paramLenBuf).concat(...v);
  });

  return new Uint8Array(data);
}

export function createBCommand(src: Uint8Array): BCommand {
  let commandClass = -1;

  const commandType = src[0]!;

  const numParameters = src[1]!;
  const parameters = new Map<number, Uint8Array>();

  for (let i = 2, n = 0; n < numParameters; n++) {
    const paramId = src[i]!;
    i = i + 1;

    const paramLen = Buffer.from(src.slice(i, i + 4)).readUInt32LE(0);
    i = i + 4;

    const paramData = src.slice(i, i + paramLen);
    i = i + paramLen;

    if (paramId === 254) {
      commandClass = paramData[0]!;
    } else {
      parameters.set(paramId, paramData);
    }
  }

  return {
    CommandClass: commandClass,
    CommandType: commandType,
    Parameters: parameters,
  };
}
