import { makeStyles } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      textWrapStyles: {
        whiteSpace: 'normal',
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        whiteSpace: 'pre-line',
      },
      descriptionStyles: {
        marginTop: `${tokens.spacings.s} !important`,
        marginBottom: `${tokens.spacing(6)} !important`,
        whiteSpace: 'pre-line',
        textAlign: 'left',
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.regular,
      },
      optionalTextStyles: {
        marginTop: `${tokens.spacings.l}`,
      },
      emailIconStyle: {
        maxWidth: tokens.spacing(16),
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      descriptionTextStyles: {
        lineHeight: tokens.spacing(5),
        [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
          textAlign: 'center',
        },
      },
      emailTextStyles: {
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(5),
        color: tokens.colors.type.typePrimary,
      },
      checkSpamContainerStyles: {
        marginTop: `${tokens.spacings.m} !important`,
      },
    }) as const,
);
