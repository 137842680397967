import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, Image, PrimaryButton, Stack } from '@teamviewer/ui-library';

import { useAppDispatch } from 'hooks';
import { setShowStayTunedDialog } from './stayTunedDialogSlice';

import {
  dialogStyles,
  primaryButtonStyles,
  stayTunedImageStyles,
  textStyles,
  titleStyles,
} from './StayTunedDialog.styles';

interface IProps {
  hidden: boolean;
  toggle: () => void;
}

export const StayTunedDialog: FC<IProps> = ({ hidden, toggle }) => {
  const { t } = useTranslation('staytuned');
  const dispatch = useAppDispatch();
  const styles = primaryButtonStyles();
  const hideDialog = () => {
    toggle();
  };

  return (
    <Dialog
      modalProps={{
        onDismissed: () => dispatch(setShowStayTunedDialog(false)),
      }}
      dialogContentProps={{
        title: (
          <Image
            styles={stayTunedImageStyles}
            src="/assets/stay-tuned-logo.svg"
            data-testid="stay-tuned-dialog-title-image"
          />
        ),
      }}
      hidden={hidden}
      onDismiss={toggle}
      styles={dialogStyles}
    >
      <Stack data-testid="stay-tuned-dialog">
        <Stack.Item styles={titleStyles}>{t('title')}</Stack.Item>
        <Stack.Item styles={textStyles} data-testid="stay-tuned-dialog-description">
          {t('description_1')}
        </Stack.Item>
        <Stack horizontal horizontalAlign="end">
          <PrimaryButton onClick={hideDialog} className={styles.buttonStyle} data-testid="stay-tuned-dialog-close-btn">
            {t('got_it')}
          </PrimaryButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};
