import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
  AccountLoginMethodResponse,
  IAccountChallangeSuccessResponse,
  IAccountLoginModel,
  IAccountVerifySuccessPayload,
  IAccountVerifySuccessResponse,
  ICheckTfaPayload,
  ICheckTfaResponse,
  IEnforcedTfaPayload,
  ISetLoginTokenAndLoginSuccessResponse,
  ISetLoginTokenSuccessResponse,
  ISsoLoginTokenResponse,
  ISsoProviderData,
} from 'models';

import { api } from 'api';
import { AuthProtocol, GetEncryptedSsoPermanentLoginTokenErrorCode, LoginMethod, LoginStatus } from 'models';
import * as Auth from 'modules/Auth/Auth';
import { authActions } from 'store';
import { getBrowserId } from 'utils';

export const getAccountLoginMethodAction = createAsyncThunk(
  'auth/accountLoginMethod',
  async (data: { username: string; redirectUri?: string }, { rejectWithValue }) => {
    const { username, redirectUri } = data;

    if (username.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'invalidArguments',
      });
    }

    const getAccountLoginMethodResponse = await api.post('/account/getaccountloginmethod', {
      username,
      redirectUri,
    });

    if (!getAccountLoginMethodResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        responseCodeType: getAccountLoginMethodResponse?.data?.t,
        responseCode: getAccountLoginMethodResponse?.data?.e,
        errorCode: getAccountLoginMethodResponse?.data?.ec,
        embeddedStringId: 'failedToKnowLoginType',
      });
    }

    const responsePayload = getAccountLoginMethodResponse.data.d as AccountLoginMethodResponse;

    if (
      responsePayload.AccountLoginMethod === LoginMethod.Sso &&
      (!responsePayload.SsoServiceUrl || responsePayload.SsoServiceUrl.length === 0)
    ) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'ssoProviderDataMissing',
      });
    }

    if (responsePayload.AccountLoginMethod === LoginMethod.SocialLogin && !responsePayload.SocialLoginIssuer) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'failedSocialLoginInfo',
      });
    }

    return responsePayload;
  },
);

export const loginAction = createAsyncThunk(
  'auth/login',
  async (credentials: IAccountLoginModel, { rejectWithValue, dispatch, getState }) => {
    const {
      username,
      password,
      redirectUri,
      ssoLoginTokenId,
      ssoVerificationToken,
      socialLoginIdentityToken,
      'invisiblecaptcha-response': captchaResponse,
    } = credentials;

    const { accountInfo, authProtocol, loginMethod } = (getState() as RootState).auth;

    if (username.length === 0 || password.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'missingCredentialData',
      });
    }

    if (ssoVerificationToken === undefined || ssoVerificationToken.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'invalidSsoToken',
      });
    }

    const { clientSecretEphemeral, publicKey } = Auth.loginAuthenticateChallenge(username, password);

    if (publicKey.length === 0 || publicKey === '0') {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'failedToCompleteAuthentication',
      });
    }

    const challengeResponse = await api.post('/login/authenticateaccountchallenge', {
      Email: username,
      ClientChallenge: publicKey,
      RedirectUri: redirectUri,
      LoginMethod: loginMethod,
      SsoLoginTokenId: ssoLoginTokenId,
      BrowserId: getBrowserId(),
      SocialLoginIdentityToken: socialLoginIdentityToken,
      'invisiblecaptcha-response': captchaResponse,
    });

    if (!challengeResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Denied,
        message: challengeResponse.data.m,
        responseCodeType: challengeResponse?.data?.t,
        responseCode: challengeResponse?.data?.e,
        errorCode: challengeResponse?.data?.ec,
      });
    }

    const challangeResponseData: IAccountChallangeSuccessResponse = challengeResponse.data.d;
    const challangeResponsePayload = challangeResponseData.Payload;

    const keysMapIn = new Map<string, string>();
    keysMapIn.set('sessionSecretHash', '');

    const sessionKey = await Auth.loginAuthenticateVerify(
      challangeResponsePayload.AccountId,
      password, // password can also be ssoLoginTokenSecret
      challangeResponsePayload.Salt,
      clientSecretEphemeral,
      challangeResponsePayload.ServerChallenge,
      keysMapIn,
    );

    if (sessionKey.secret.length === 0 || sessionKey.secret === '0') {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'failedToCompleteAuthentication',
      });
    }

    const sessionSecretHash = sessionKey.keysMapOut.get('sessionSecretHash') as string;

    const verifyResponse = await api.post('/login/authenticateaccountverify', {
      AccountId: challangeResponsePayload.AccountId,
      AccountAuthState: challangeResponsePayload.AccountAuthState,
      SessionSecretHash: sessionSecretHash,
      SessionSecret: sessionKey.secret,
      SsoVerificationToken: ssoVerificationToken, // ssoToken is always provided even when it is not enabled
      RedirectUri: redirectUri,
      TargetInstanceNumber: challangeResponseData.TargetInstanceNumber,
      LoginMethod: loginMethod,
      LoginTokenId: ssoLoginTokenId,
    });

    if (!verifyResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Denied,
        message: verifyResponse.data.m,
        responseCodeType: verifyResponse?.data?.t,
        responseCode: verifyResponse?.data?.e,
        errorCode: verifyResponse?.data?.ec,
      });
    }

    const verifyResponseData: IAccountVerifySuccessResponse = verifyResponse.data.d;

    if (verifyResponseData.IsEnforcedTfa) {
      const payload = verifyResponseData.Payload as IEnforcedTfaPayload;
      return {
        loginStatus: LoginStatus.EnforcedTfa,
        enforcedTfaInfo: {
          enforcedTfaToken: payload.enforcedTfaToken,
        },
      };
    }

    if (verifyResponseData.AccountValidationPending) {
      return {
        loginStatus: LoginStatus.VerifyAccountPending,
      };
    }

    if (verifyResponseData.IsDeviceAuthorizationRequired) {
      return {
        loginStatus: LoginStatus.TrustDevicePending,
        message: verifyResponseData.Message,
      };
    }

    const verifyResponsePayload = verifyResponseData.Payload as IAccountVerifySuccessPayload;
    if (verifyResponseData.TfaSecurityCodeRequired) {
      dispatch(
        authActions.setAccountInfo({
          ...accountInfo,
          username,
          password,
          accountId: challangeResponsePayload.AccountId,
          sessionSecret: sessionKey.secret,
          accountAuthState: verifyResponsePayload.AccountAuthState,
        }),
      );

      return {
        loginStatus: LoginStatus.TfaSecurityCodeRequired,
        TargetInstanceNumber: challangeResponseData.TargetInstanceNumber,
      };
    }

    dispatch(
      authActions.setAccountInfo({
        username,
        password,
        accountId: verifyResponsePayload.AccountId,
        sessionId: verifyResponsePayload.AccountSessionId,
        sessionSecret: sessionKey.secret,
        encryptedAccountSecret: verifyResponsePayload.EncryptedAccountSecret,
        accountAuthState: verifyResponsePayload.AccountAuthState,
      }),
    );

    dispatch(
      loginTokenAction({
        accountId: verifyResponsePayload.AccountId,
        email: username,
        password,
        sessionId: verifyResponsePayload.AccountSessionId,
        sessionSecret: sessionKey.secret,
        encryptedAccountSecret: verifyResponsePayload.EncryptedAccountSecret,
        accountAuthState: verifyResponsePayload.AccountAuthState,
        isSsoLogin: loginMethod === LoginMethod.Sso,
        isSetLoginTokenAndLoginRequired: verifyResponseData.IsSetLoginTokenAndLoginRequired,
        authProtocol, // TvAuth or OAuth
        ssoLoginTokenId,
        targetInstanceNumber: challangeResponseData.TargetInstanceNumber ?? 0,
      }),
    );

    return {
      loginStatus: LoginStatus.Authenticated,
    };
  },
);

export const tfaAction = createAsyncThunk(
  'auth/tfa',
  async (
    tfaData: {
      tfaSecurityCode: string;
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const { tfaSecurityCode } = tfaData;
    const authState = (getState() as RootState).auth;

    if (
      tfaSecurityCode.length === 0 ||
      authState.accountInfo.sessionSecret.length === 0 ||
      authState.accountInfo.accountId.length === 0 ||
      authState.accountInfo.accountAuthState.length === 0
    ) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'tfaRequestDataMissing',
      });
    }

    const tfaResponse = await api.post('/login/authenticateaccountchecktfa', {
      AccountId: authState.accountInfo.accountId,
      TfaSecurityCode: tfaSecurityCode,
      SessionSecret: authState.accountInfo.sessionSecret,
      AccountAuthState: authState.accountInfo.accountAuthState,
      TargetInstanceNumber: authState.targetInstanceNumber,
      LoginMethod: authState.loginMethod,
    });

    if (!tfaResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        message: tfaResponse.data.m,
        responseCodeType: tfaResponse?.data?.t,
        responseCode: tfaResponse?.data?.e,
        errorCode: tfaResponse?.data?.ec,
      });
    }

    const checkTfaResponseData: ICheckTfaResponse = tfaResponse.data.d;

    if (checkTfaResponseData.AccountValidationPending) {
      return {
        loginStatus: LoginStatus.VerifyAccountPending,
      };
    }

    const checkTfaResponsePayload = checkTfaResponseData.Payload as ICheckTfaPayload;

    dispatch(
      authActions.setAccountInfo({
        ...authState.accountInfo,
        accountId: checkTfaResponsePayload.AccountId,
        sessionId: checkTfaResponsePayload.AccountSessionId,
        accountAuthState: checkTfaResponsePayload.AccountAuthState,
        encryptedAccountSecret: checkTfaResponsePayload.EncryptedAccountSecret,
      }),
    );

    dispatch(
      loginTokenAction({
        accountId: checkTfaResponsePayload.AccountId,
        email: authState.accountInfo.username,
        password: authState.accountInfo.password,
        sessionId: checkTfaResponsePayload.AccountSessionId,
        sessionSecret: authState.accountInfo.sessionSecret,
        encryptedAccountSecret: checkTfaResponsePayload.EncryptedAccountSecret,
        accountAuthState: checkTfaResponsePayload.AccountAuthState,
        isSsoLogin: false,
        isSetLoginTokenAndLoginRequired: checkTfaResponseData.IsSetLoginTokenAndLoginRequired,
        authProtocol: authState.authProtocol,
        targetInstanceNumber: authState.targetInstanceNumber,
      }),
    );

    return {
      loginStatus: LoginStatus.Authenticated,
    };
  },
);

export const ssoReturnUrlAction = createAsyncThunk(
  'auth/ssoReturnUrlAction',
  async (data: ISsoProviderData, { rejectWithValue, dispatch }) => {
    if (data.RedirectUri.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'redirectURLIsMissing',
      });
    }

    if (data.Username.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'credentialDataMissing',
      });
    }

    if (
      data.SsoVerificationToken === null ||
      data.SsoVerificationToken.length === 0 ||
      data.CustomerId === null ||
      data.CustomerId.length === 0
    ) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'credentialDataMissing',
      });
    }

    const ssoLoginTokenResponse = await api.post('/sso/getencryptedssologintoken', {
      SsoVerificationToken: data.SsoVerificationToken,
    });

    if (ssoLoginTokenResponse.data.e === GetEncryptedSsoPermanentLoginTokenErrorCode.NotFound) {
      return {
        loginStatus: LoginStatus.SsoOneTimePasswordLoginRequired,
        ssoVerificationToken: data.SsoVerificationToken,
        customerId: data.CustomerId,
      };
    }

    if (!ssoLoginTokenResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        message: ssoLoginTokenResponse.data.m,
        responseCodeType: ssoLoginTokenResponse?.data?.t,
        responseCode: ssoLoginTokenResponse?.data?.e,
        errorCode: ssoLoginTokenResponse?.data?.ec,
      });
    }

    const ssoLoginTokenResponseData: ISsoLoginTokenResponse = ssoLoginTokenResponse.data.d;

    const ssoLoginTokenSecret = await Auth.decryptSsoLoginTokenSecret(
      ssoLoginTokenResponseData.EncryptedToken,
      data.CustomerId,
    );

    // dispatch(authActions.setIsSsoLogin(true));

    dispatch(
      loginAction({
        username: data.Username,
        password: ssoLoginTokenSecret,
        redirectUri: data.RedirectUri,
        ssoLoginTokenId: ssoLoginTokenResponseData.TokenId.toString(),
        ssoVerificationToken: data.SsoVerificationToken,
        ssoCustomerId: data.CustomerId,
      }),
    );

    return {
      ssoVerificationToken: data.SsoVerificationToken,
      ssoLogoutUrl: data.SsoLogoutUrl,
    };
  },
);

export const loginTokenAction = createAsyncThunk(
  'auth/loginToken',
  async (
    data: {
      accountId: string;
      email: string;
      password: string;
      sessionId: string;
      sessionSecret: string;
      encryptedAccountSecret: string;
      accountAuthState: string;
      isSsoLogin: boolean;
      isSetLoginTokenAndLoginRequired: boolean;
      authProtocol: AuthProtocol;
      ssoLoginTokenId?: string | undefined;
      targetInstanceNumber: number;
    },
    { rejectWithValue, dispatch, getState },
  ) => {
    const {
      accountId,
      password,
      sessionId,
      sessionSecret,
      encryptedAccountSecret,
      accountAuthState,
      email,
      isSetLoginTokenAndLoginRequired,
      authProtocol,
      targetInstanceNumber,
    } = data;

    const { accountInfo, ssoInfo, loginMethod } = (getState() as RootState).auth;

    let setLoginTokenAndLoginResponsePayload;
    // In case of login with LoginToken (currently never the case for LoginService) and SSO login,
    // the flag is false becuase no need to create another permenant loginToken
    if (isSetLoginTokenAndLoginRequired) {
      const generateSecretKeyWithTokenData = await Auth.generateSecretKeyWithToken(
        accountId,
        password,
        encryptedAccountSecret,
      );

      const setLoginTokenAndLoginResponse = await api.post('/login/authenticateaccountsetlogintokenandlogin', {
        AccountId: accountId,
        AccountKey: encryptedAccountSecret,
        TokenSalt: generateSecretKeyWithTokenData.tokenSalt,
        TokenVerifier: generateSecretKeyWithTokenData.tokenVerifier,
        LoginTokenSecret: generateSecretKeyWithTokenData.loginTokenSecret,
        AccountAuthState: accountAuthState,
        sessionSecret,
        TargetInstanceNumber: targetInstanceNumber,
        LoginMethod: loginMethod,
      });

      if (!setLoginTokenAndLoginResponse.data.s) {
        return rejectWithValue({
          loginStatus: LoginStatus.Failed,
          message: setLoginTokenAndLoginResponse.data.m,
          responseCodeType: setLoginTokenAndLoginResponse?.data?.t,
          responseCode: setLoginTokenAndLoginResponse?.data?.e,
          errorCode: setLoginTokenAndLoginResponse?.data?.ec,
        });
      }

      const setLoginTokenAndLoginResponseData: ISetLoginTokenAndLoginSuccessResponse =
        setLoginTokenAndLoginResponse.data.d;
      setLoginTokenAndLoginResponsePayload = setLoginTokenAndLoginResponseData.Payload;
    }

    if (
      accountId.length === 0 ||
      accountId === '0' ||
      (!setLoginTokenAndLoginResponsePayload && (sessionId.length === 0 || sessionId === '0'))
    ) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        embeddedStringId: 'failedToCompleteAuthentication',
      });
    }

    dispatch(
      authActions.setAccountInfo({
        ...accountInfo,
        sessionId: !setLoginTokenAndLoginResponsePayload
          ? sessionId
          : setLoginTokenAndLoginResponsePayload.AccountSessionId,
      }),
    );

    if (ssoInfo.isNewSsoLoginTokenRequired) {
      const secretKeyWithSsoToken = await Auth.generateSecretKeyWithToken(accountId, password, encryptedAccountSecret);

      const setSsoLoginTokenResponse = await api.post('/login/setaccountlogintoken', {
        AccountId: accountId,
        SessionSecret: sessionSecret,
        SessionId: !setLoginTokenAndLoginResponsePayload
          ? sessionId
          : setLoginTokenAndLoginResponsePayload.AccountSessionId,
        AccountKey: secretKeyWithSsoToken.accountKey,
        TokenSalt: secretKeyWithSsoToken.tokenSalt,
        TokenVerifier: secretKeyWithSsoToken.tokenVerifier,
        LoginTokenSecret: secretKeyWithSsoToken.loginTokenSecret,
        isSsoLoginToken: true,
        TargetInstanceNumber: targetInstanceNumber,
      });

      if (!setSsoLoginTokenResponse.data.s) {
        return rejectWithValue({
          loginStatus: LoginStatus.Failed,
          message: setSsoLoginTokenResponse.data.m,
          responseCodeType: setSsoLoginTokenResponse?.data?.t,
          responseCode: setSsoLoginTokenResponse?.data?.e,
          errorCode: setSsoLoginTokenResponse?.data?.ec,
        });
      }

      const setSsoLoginTokenResponseData: ISetLoginTokenSuccessResponse = setSsoLoginTokenResponse.data.d;
      const setSsoLoginTokeResponsePayload = setSsoLoginTokenResponseData.Payload;

      if (ssoInfo.ssoCustomerId?.length === 0 || ssoInfo.ssoVerificationToken?.length === 0) {
        return rejectWithValue({
          loginStatus: LoginStatus.Failed,
          embeddedStringId: 'invalidSsoData',
        });
      }

      const encryptedSsoLoginToken = await Auth.encryptSsoLoginTokenSecret(
        secretKeyWithSsoToken.loginToken,
        ssoInfo.ssoCustomerId,
      );

      const storeSsoLoginTokenResponse = await api.post('/sso/storessopermanentlogintoken', {
        SsoVerificationToken: ssoInfo.ssoVerificationToken,
        LoginTokenId: setSsoLoginTokeResponsePayload.LoginTokenId,
        EncryptedLoginToken: encryptedSsoLoginToken,
      });

      if (!storeSsoLoginTokenResponse.data.s) {
        return rejectWithValue({
          loginStatus: LoginStatus.Failed,
          message: storeSsoLoginTokenResponse.data.m,
          responseCodeType: storeSsoLoginTokenResponse?.data?.t,
          responseCode: storeSsoLoginTokenResponse?.data?.e,
          errorCode: storeSsoLoginTokenResponse?.data?.ec,
        });
      }
    }

    // Create another loginToken to redirect back to the client
    const generateSecretKeyWithRedirectToken = await Auth.generateSecretKeyWithToken(
      accountId,
      password,
      encryptedAccountSecret,
    );

    const setRedirectLoginTokenResponse = await api.post('/login/setaccountlogintoken', {
      AccountId: accountId,
      SessionSecret: sessionSecret,
      SessionId: !setLoginTokenAndLoginResponsePayload
        ? sessionId
        : setLoginTokenAndLoginResponsePayload.AccountSessionId,
      AccountKey: generateSecretKeyWithRedirectToken.accountKey,
      TokenSalt: generateSecretKeyWithRedirectToken.tokenSalt,
      TokenVerifier: generateSecretKeyWithRedirectToken.tokenVerifier,
      LoginTokenSecret: generateSecretKeyWithRedirectToken.loginTokenSecret,
      AuthProtocol: authProtocol,
      // Only send the loginToken to the BE in case of OAuth.
      LoginToken: authProtocol === AuthProtocol.OAuth ? generateSecretKeyWithRedirectToken.loginToken : undefined,
      Email: email,
      RememberMe: false,
      TargetInstanceNumber: targetInstanceNumber,
    });

    if (!setRedirectLoginTokenResponse.data.s) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        message: setRedirectLoginTokenResponse.data.m,
        responseCodeType: setRedirectLoginTokenResponse?.data?.t,
        responseCode: setRedirectLoginTokenResponse?.data?.e,
        errorCode: setRedirectLoginTokenResponse?.data?.ec,
      });
    }

    // In case of OAuth the logout will be triggered after the flow is finished
    if (authProtocol === AuthProtocol.OAuth) {
      return { loginStatus: LoginStatus.ReadyToRedirect };
    }

    const setLoginTokenResponseData: ISetLoginTokenSuccessResponse = setRedirectLoginTokenResponse.data.d;
    const setLoginTokenResponsePayload = setLoginTokenResponseData.Payload;

    // After the temporary loginToken is generated, LoginService logged in session and
    // it's permanent loginToken need to be deleted
    await api.post('/logout');

    return {
      loginStatus: LoginStatus.Successed,
      accountId,
      tokenId: setLoginTokenResponsePayload.LoginTokenId,
      token: generateSecretKeyWithRedirectToken.loginToken,
    };
  },
);

export const getSsoVerificationToken = createAsyncThunk(
  'auth/getSsoVerificationToken',
  async (email: string, { rejectWithValue }) => {
    if (email.length === 0) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        message: 'Email is missing.',
      });
    }

    const response = await api.post('/sso/getssoverificationtoken', {
      email,
    });

    if (!response.data.s || !response.data.d) {
      return rejectWithValue({
        loginStatus: LoginStatus.Failed,
        message: 'Failed to obtain SSO login information.',
      });
    }

    return response.data.d;
  },
);
