import { makeStyles, spacing } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      containerStyles: {
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '700px',
        padding: '48px 24px',
      },
      troubleShootingTextStyles: {
        marginTop: spacing(10),
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: tokens.typography.fontSizes.size16,
      },
      troubleShootingLinkStyles: {
        marginTop: spacing(10),
        display: 'inline',
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: tokens.typography.fontSizes.size16,
        borderBottom: 'none',
      },
    }) as const,
);
