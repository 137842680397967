import { useEffect } from 'react';
import i18n from 'i18next';

import { isProd } from 'config/config-manager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';

export function useCookieBanner({ hide = false }: { hide?: boolean }) {
  const onLoad = () => {
    (window as any).Optanon?.changeLanguage(mapLanguages(i18n.language));
  };

  const onLanguageChange = (lang: string) => {
    (window as any).Optanon?.changeLanguage(mapLanguages(lang));
  };

  const initialServiceConfig = useInitialServiceConfig();

  useEffect(() => {
    if (hide) {
      return;
    }

    i18n.on('loaded', onLoad);
    i18n.on('languageChanged', onLanguageChange);

    const cookieConsentScript = document.createElement('script');
    cookieConsentScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
    cookieConsentScript.type = 'text/javascript';
    cookieConsentScript.setAttribute('data-document-language', 'true');
    cookieConsentScript.setAttribute(
      'data-domain-script',
      `9c53b460-d634-4756-b40e-d03f1508a38f${!isProd(initialServiceConfig.environmentName) ? '-test' : ''}`,
    );

    document.body.appendChild(cookieConsentScript);

    return () => {
      i18n.off('loaded', onLoad);
      i18n.off('languageChanged', onLanguageChange);
    };
  }, [hide, initialServiceConfig.environmentName]);
}

// Helpers
function mapLanguages(lang: string) {
  switch (lang) {
    case 'pl':
      return 'en';
    case 'pt':
      return 'pt-br';
    case 'zh-CN':
      return 'zh';
    default:
      return lang;
  }
}
