import { makeStyles } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      containerStyles: {
        alignItems: 'center',
      },
      buttonStyles: {
        marginTop: tokens.spacing(10),
      },
      buttonContainerStyles: {
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
        },
      },
    }) as const,
);
