import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { mergeClasses, Stack, TextButton, TextSecondary, themeSpacings } from '@teamviewer/ui-library';

import { FullPageLayout } from 'components';
import { useConfigManager } from 'config/useConfigManager';
import { useNavigateWithRedirect, useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';
import { useLinkStyles } from 'utils/commonStyles';
import { validateIsCustomProtocol } from 'utils/validationFunctions';
import { useStyles } from './LaunchTeamViewer.Styles';

const LaunchTeamViewer = () => {
  const { screenSize } = useResponsive();
  const { t } = useTranslation('launchteamviewer');
  const navigate = useNavigateWithRedirect();
  const [searchParams] = useSearchParams();
  const [isUseWebClient, setIsUseWebClient] = useState<boolean>(false);
  const isSocialLogin = searchParams.get('is_social_login')?.toLowerCase() === 'true';
  const isError = searchParams.get('is_error')?.toLowerCase() === 'true';
  const errorCode = searchParams.get('error_code') ?? '';
  const errorType = searchParams.get('error_type') ?? '';
  const socilLoginCustomUri = 'tvoneweblogin://sociallogin';
  const configManager = useConfigManager();
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const errorMessage = searchParams.get('error_message') || 'Unexpected error. Please try again later.';
  const socialLoginStep = searchParams.get('step');
  const email = searchParams.get('email');
  const name = searchParams.get('name');
  const idProvider = searchParams.get('id_provider');
  const idToken = searchParams.get('id_token');
  const username = searchParams.get('username');
  const accountid = searchParams.get('accountid');
  const tokenid = searchParams.get('tokenid');
  const logintoken = searchParams.get('logintoken');
  const ssoverificationtoken = searchParams.get('ssoverificationtoken');
  const keepmesignedin = searchParams.get('keepmesignedin');

  const { linkStyles } = useLinkStyles();

  const { containerStyles, troubleShootingTextStyles, troubleShootingLinkStyles } = useStyles();

  useEffect(() => {
    if (isUseWebClient) {
      return;
    }

    if (isSocialLogin && isError) {
      const clientRedirectUrlParam = new URLSearchParams({
        redirect_uri: redirectUri,
        is_error: isError.toString(),
        error_code: errorCode,
        error_type: errorType,
        error_message: errorMessage,
      });
      const clientRedirectUrl = `/?${clientRedirectUrlParam}`;

      const socialLoginCustomUriParam = new URLSearchParams({
        path: clientRedirectUrl,
      });

      window.location.assign(`${socilLoginCustomUri}?${socialLoginCustomUriParam}`);
      return;
    }

    if (isSocialLogin && email && name && idProvider && idToken) {
      const clientRedirectUrlParam = new URLSearchParams({
        redirect_uri: redirectUri,
        email,
        name,
        id_provider: idProvider,
        id_token: idToken,
      });
      const clientRedirectUrl = `/sociallogin/${socialLoginStep}?${clientRedirectUrlParam}`;

      const socialLoginCustomUriParam = new URLSearchParams({
        path: clientRedirectUrl,
      });

      window.location.assign(`${socilLoginCustomUri}?${socialLoginCustomUriParam}`);
      return;
    }

    if (
      redirectUri &&
      username &&
      accountid &&
      tokenid &&
      logintoken &&
      keepmesignedin &&
      validateIsCustomProtocol(redirectUri)
    ) {
      const search = new URLSearchParams({
        username,
        accountid,
        tokenid,
        logintoken,
        ssoverificationtoken: ssoverificationtoken || '', // FIXME: please verify if this is always sent to client
        keepmesignedin,
      });
      const url = new URL(redirectUri);
      url.search = url.searchParams.merge(search).toString();
      window.location.assign(url.href);
      return;
    }

    navigate(
      {
        pathname: '/',
        search: new URLSearchParams({
          is_error: 'true',
          error_message: 'Link provided is not a valid TeamViewer link.',
        }).toString(),
      },
      {
        replace: true,
      },
    );
  }, [
    isUseWebClient,
    isSocialLogin,
    isError,
    errorCode,
    errorType,
    accountid,
    email,
    errorMessage,
    idProvider,
    idToken,
    keepmesignedin,
    logintoken,
    name,
    redirectUri,
    socialLoginStep,
    ssoverificationtoken,
    tokenid,
    username,
    navigate,
  ]);

  const redirectToWebClient = useCallback(() => {
    setIsUseWebClient(true);
    const webclientUrl = new URL(configManager.get('webClientUri'));
    const redirectUrl = new URL(redirectUri);
    webclientUrl.pathname = redirectUrl.pathname;
    webclientUrl.search = redirectUrl.search;
    if (isSocialLogin && isError) {
      const clientRedirectUrlParam = new URLSearchParams({
        redirect_uri: webclientUrl.href,
        is_error: isError.toString(),
        error_message: errorMessage,
      });

      navigate(
        {
          pathname: '/',
          search: clientRedirectUrlParam.toString(),
        },
        {
          replace: true,
        },
      );

      return;
    }

    if (isSocialLogin && email && name && idProvider && idToken) {
      const clientRedirectUrlParam = new URLSearchParams({
        redirect_uri: webclientUrl.href,
        email,
        name,
        id_provider: idProvider,
        id_token: idToken,
      });

      navigate(
        {
          pathname: `/sociallogin/${socialLoginStep}`,
          search: clientRedirectUrlParam.toString(),
        },
        {
          replace: true,
        },
      );

      return;
    }

    if (username && accountid && tokenid && logintoken && keepmesignedin) {
      const search = new URLSearchParams({
        email: username,
        redirect_uri: configManager.get('defaultRedirectUri'),
      });

      const useWpsInBrowserUrl = new URL(window.location.origin);
      useWpsInBrowserUrl.search = useWpsInBrowserUrl.searchParams.merge(search).toString();

      window.location.replace(useWpsInBrowserUrl.href);

      return;
    }

    navigate(
      {
        pathname: '/',
        search: new URLSearchParams({
          is_error: 'true',
          error_message: 'Link provided is not a valid TeamViewer link.',
        }).toString(),
      },
      {
        replace: true,
      },
    );
  }, [
    accountid,
    configManager,
    email,
    errorMessage,
    idProvider,
    idToken,
    isError,
    isSocialLogin,
    keepmesignedin,
    logintoken,
    name,
    navigate,
    redirectUri,
    socialLoginStep,
    tokenid,
    username,
  ]);

  return (
    <FullPageLayout data-testid="launch-teamviewer-container">
      <Stack className={containerStyles}>
        <img src="/assets/laptop_frontal.svg" alt="Expired" data-testid="launch-teamviewer-image" />
        <Stack>
          <FullPageLayout.Title data-testid="launch-teamviewer-title">{t('launchingTeamviewer')}</FullPageLayout.Title>
          <FullPageLayout.Description data-testid="launch-teamviewer-description">
            {t('explanation')}
          </FullPageLayout.Description>
          <Stack
            horizontal={screenSize > ScreenSize.Laptop}
            tokens={{ childrenGap: themeSpacings.xs }}
            horizontalAlign="center"
            verticalAlign="center"
          >
            <TextSecondary className={troubleShootingTextStyles} data-testid="launch-teamviewer-trouble-shooting">
              {t('troubleLaunching')}
            </TextSecondary>
            <TextButton
              className={mergeClasses(linkStyles, troubleShootingLinkStyles)}
              tabIndex={1}
              data-testid="launch-teamviewer-link"
              onClick={redirectToWebClient}
            >
              {t('useBrowser')}
            </TextButton>
          </Stack>
        </Stack>
      </Stack>
    </FullPageLayout>
  );
};

export default LaunchTeamViewer;
