import { Buffer } from 'buffer';

import { hash, ssoParams } from './Parameters';

export async function deriveExportKey(
  data: Uint8Array,
  salt: Uint8Array,
  iterations: number,
  keySize: number,
  keyUsages: Iterable<KeyUsage>,
) {
  const crypto = window.crypto.subtle;
  const dataHashBuffer = await crypto.digest(hash, data);

  const baseKey = await crypto.importKey('raw', dataHashBuffer, 'PBKDF2', false, ['deriveKey']);
  const key = await crypto.deriveKey(
    {
      name: 'PBKDF2',
      hash,
      salt,
      iterations,
    },
    baseKey,
    {
      name: 'AES-CBC',
      length: keySize * 8,
    },
    false,
    keyUsages,
  );

  return key;
}

export async function deriveSsoKey(data: Uint8Array, salt: Uint8Array, keyUsages: Iterable<KeyUsage>) {
  const crypto = window.crypto.subtle;
  const importKey = await crypto.importKey('raw', data, ssoParams.deriveKeyAlgorithm, false, ['deriveKey']);

  return await crypto.deriveKey(
    {
      name: ssoParams.deriveKeyAlgorithm,
      hash: ssoParams.hash,
      salt,
      iterations: ssoParams.iterations,
    },
    importKey,
    {
      name: ssoParams.algorithm,
      length: ssoParams.size.key,
    },
    false,
    keyUsages,
  );
}

export async function decryptAccountKey(
  password: Uint8Array,
  salt: Uint8Array,
  iterations: number,
  keySize: number,
  encryptedData: Uint8Array,
): Promise<Uint8Array> {
  const crypto = window.crypto.subtle;
  const secretKey = await deriveExportKey(password, salt, iterations, keySize, ['decrypt']);

  const ivSize = 16;
  const iv = encryptedData.slice(0, ivSize);
  const encryptedSecret = encryptedData.slice(ivSize, encryptedData.byteLength);

  const accountKey: ArrayBuffer = await crypto.decrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    secretKey,
    encryptedSecret,
  );

  return new Uint8Array(accountKey);
}

export async function encryptAccountKey(
  loginTokenOrPassword: Uint8Array,
  salt: Uint8Array,
  iterations: number,
  keySize: number,
  iv: Uint8Array,
  accountKey: Uint8Array,
): Promise<Uint8Array> {
  const crypto = window.crypto.subtle;
  const secretKey = await deriveExportKey(loginTokenOrPassword, salt, iterations, keySize, ['encrypt']);

  const encryptedAccountKey: ArrayBuffer = await crypto.encrypt(
    {
      name: 'AES-CBC',
      iv,
    },
    secretKey,
    accountKey,
  );
  return new Uint8Array(encryptedAccountKey);
}

export function unwrapEncryptedAccountSecret(encryptAccountSecret: string) {
  const byteArray = new Uint8Array(Buffer.from(encryptAccountSecret, 'base64'));

  const headerSize = new DataView(byteArray.buffer, 0, 2).getUint16(0, true);
  const iterations = new DataView(byteArray.buffer, 2, 2).getUint16(0, true);
  const keySize = new DataView(byteArray.buffer, 4, 2).getUint16(0, true);
  const saltSize = new DataView(byteArray.buffer, 6, 2).getUint16(0, true);

  const salt = byteArray.slice(headerSize, headerSize + saltSize);
  const encryptedData = byteArray.slice(headerSize + saltSize, byteArray.byteLength);

  return {
    headerSize,
    iterations,
    keySize,
    salt,
    encryptedData,
  };
}

export function wrapEncryptedAccountSecret(
  encryptedAccountKey: Uint8Array,
  iv: Uint8Array,
  headerSize: number,
  iterations: number,
  keySize: number,
  salt: Uint8Array,
) {
  const headerByteArray = new Uint8Array(headerSize);

  const headerDataView = new DataView(headerByteArray.buffer);
  headerDataView.setUint16(0, headerSize, true);
  headerDataView.setUint16(2, iterations, true);
  headerDataView.setUint16(4, keySize, true);
  headerDataView.setUint16(6, salt.byteLength, true);

  const byteArray = new Uint8Array(headerSize + salt.byteLength + iv.byteLength + encryptedAccountKey.byteLength);
  byteArray.set(headerByteArray);
  byteArray.set(salt, headerSize);
  byteArray.set(iv, headerSize + salt.byteLength);
  byteArray.set(encryptedAccountKey, headerSize + salt.byteLength + iv.byteLength);

  return byteArray;
}

export async function decryptSsoLoginTokenSecret(
  encryptedSsoLoginTokenSecret: Uint8Array,
  customerId: Uint8Array,
): Promise<Uint8Array> {
  const salt = encryptedSsoLoginTokenSecret.slice(0, ssoParams.size.salt);
  const iv = encryptedSsoLoginTokenSecret.slice(ssoParams.size.salt, ssoParams.size.salt + ssoParams.size.iv);
  const encryptedData = encryptedSsoLoginTokenSecret.slice(
    ssoParams.size.salt + ssoParams.size.iv,
    encryptedSsoLoginTokenSecret.byteLength,
  );
  const crypto = window.crypto.subtle;
  const key = await deriveSsoKey(customerId, salt, ['decrypt']);

  const decryptedData = await crypto.decrypt(
    {
      name: ssoParams.algorithm,
      iv,
    },
    key,
    encryptedData,
  );

  return new Uint8Array(decryptedData);
}

export async function encryptSsoLoginTokenSecret(ssoLoginTokenSecret: Uint8Array, customerId: Uint8Array) {
  const crypto = window.crypto.subtle;
  const salt = new Uint8Array(ssoParams.size.salt);
  const iv = new Uint8Array(ssoParams.size.iv);
  const key = await deriveSsoKey(customerId, salt, ['encrypt']);

  const encryptedData = await crypto.encrypt(
    {
      name: ssoParams.algorithm,
      iv,
    },
    key,
    ssoLoginTokenSecret,
  );

  return Buffer.concat([Buffer.from(salt), Buffer.from(iv), Buffer.from(encryptedData)]);
}

async function constructCommonKeyHeader(
  commonKeyHeaderFlags: number,
  commonKeyHeaderKeyType: number,
  modulusSize: number,
  publicExponentSize: number,
  secretExponentSize: number,
  symmetricKeySize: number,
) {
  const commonKeyHeaderSize = 8;
  const rsaKeyHeaderSize = 8;

  let commonKeyHeaderPayloadSize = 0;
  if (commonKeyHeaderKeyType === 1) {
    // RSA
    commonKeyHeaderPayloadSize = rsaKeyHeaderSize + modulusSize + publicExponentSize + secretExponentSize;
  } else if (commonKeyHeaderKeyType === 2) {
    // AES
    commonKeyHeaderPayloadSize = symmetricKeySize;
  }

  const commonKeyHeaderByteArray = new Uint8Array(commonKeyHeaderSize);

  const commonHeaderDataView = new DataView(commonKeyHeaderByteArray.buffer);
  commonHeaderDataView.setUint16(0, commonKeyHeaderSize, true);
  commonHeaderDataView.setUint16(2, commonKeyHeaderPayloadSize, true);
  commonHeaderDataView.setUint16(4, commonKeyHeaderFlags, true);
  commonHeaderDataView.setUint16(6, commonKeyHeaderKeyType, true);

  return { commonKeyHeaderSize, commonKeyHeaderByteArray };
}

async function constructRsaKeyHeader(modulusSize: number, publicExponentSize: number, secretExponentSize: number) {
  const rsaKeyHeaderSize = 8;
  const rsaKeyHeaderByteArray = new Uint8Array(rsaKeyHeaderSize);

  const rsaKeyHeaderDataView = new DataView(rsaKeyHeaderByteArray.buffer);
  rsaKeyHeaderDataView.setUint16(0, rsaKeyHeaderSize, true);
  rsaKeyHeaderDataView.setUint16(2, modulusSize, true);
  rsaKeyHeaderDataView.setUint16(4, publicExponentSize, true);
  if (secretExponentSize > 0) {
    rsaKeyHeaderDataView.setUint16(6, secretExponentSize, true);
  }

  return { rsaKeyHeaderSize, rsaKeyHeaderByteArray };
}

export async function exportAccountKey(accountKey: CryptoKey, commonKeyHeaderFlags: number) {
  const jsonWebKeyBase = await window.crypto.subtle.exportKey('jwk', accountKey);

  const modulous = new Uint8Array(Buffer.from(jsonWebKeyBase.n ?? '', 'base64'));
  const publicExponent = new Uint8Array(Buffer.from(jsonWebKeyBase.e ?? '', 'base64'));
  const secretExponent = new Uint8Array(Buffer.from(jsonWebKeyBase.d ?? '', 'base64'));

  const modulusSize = modulous.length;
  const publicExponentSize = publicExponent.length;
  const secretExponentSize = secretExponent.length;

  if (commonKeyHeaderFlags === 3) {
    if (secretExponentSize === undefined) {
      throw new Error('undefined secret exponent data for private key');
    }
  }

  const { commonKeyHeaderSize, commonKeyHeaderByteArray } = await constructCommonKeyHeader(
    commonKeyHeaderFlags,
    1,
    modulusSize,
    publicExponentSize,
    secretExponentSize,
    0,
  );

  const { rsaKeyHeaderSize, rsaKeyHeaderByteArray } = await constructRsaKeyHeader(
    modulusSize,
    publicExponentSize,
    secretExponentSize,
  );

  const keyByteArray = new Uint8Array(
    commonKeyHeaderSize + rsaKeyHeaderSize + modulusSize + publicExponentSize + secretExponentSize,
  );
  keyByteArray.set(commonKeyHeaderByteArray);
  keyByteArray.set(rsaKeyHeaderByteArray, commonKeyHeaderSize);
  keyByteArray.set(modulous, commonKeyHeaderSize + rsaKeyHeaderSize);
  keyByteArray.set(publicExponent, commonKeyHeaderSize + rsaKeyHeaderSize + modulusSize);
  keyByteArray.set(secretExponent, commonKeyHeaderSize + rsaKeyHeaderSize + modulusSize + publicExponentSize);

  return keyByteArray;
}

export async function exportSymmetricKey(symmetricKey: CryptoKey, commonKeyHeaderFlags: number) {
  const jsonWebKeyBase = await window.crypto.subtle.exportKey('jwk', symmetricKey);

  const k = new Uint8Array(Buffer.from(jsonWebKeyBase.k ?? '', 'base64'));
  const symmetricKeyMaterialSize = k.length;

  const { commonKeyHeaderSize, commonKeyHeaderByteArray } = await constructCommonKeyHeader(
    commonKeyHeaderFlags,
    2,
    0,
    0,
    0,
    symmetricKeyMaterialSize,
  );

  const keyByteArray = new Uint8Array(commonKeyHeaderSize + symmetricKeyMaterialSize);
  keyByteArray.set(commonKeyHeaderByteArray);
  keyByteArray.set(k, commonKeyHeaderSize);

  return keyByteArray;
}

export async function encryptMaterialWithPublicKey(handleKey: CryptoKey, material: Uint8Array, algorithm: string) {
  const crypto = window.crypto.subtle;
  const encryptedKeyResult = await crypto.encrypt(
    {
      name: algorithm,
    },
    handleKey,
    material,
  );

  return new Uint8Array(encryptedKeyResult);
}

export async function encryptMaterialWithMasterPublicKey(
  handleKey: CryptoKey,
  material: Uint8Array,
  algorithm: string,
  chunks: number,
  resultChunkSize: number,
): Promise<Uint8Array> {
  const crypto = window.crypto.subtle;

  if (chunks < 1 || material.length < chunks) {
    throw new Error('Invalid arguments for encryption with master key');
  }

  // Stretch chunk size to result chunk size
  const resultLength = resultChunkSize * Math.ceil(material.length / chunks);
  const result = new Uint8Array(resultLength);
  let offset = 0;

  for (let i = 0; i < material.length; i += chunks) {
    const chunk = material.slice(i, i + chunks);
    const encryptedChunk = await crypto.encrypt(
      {
        name: algorithm,
      },
      handleKey,
      chunk,
    );
    result.set(new Uint8Array(encryptedChunk), offset);
    offset += encryptedChunk.byteLength;
  }

  return result;
}

export async function importMasterPublicKey(envMasterPublicJsonWebKey: { kty: string; e: string; n: string }) {
  const crypto = window.crypto.subtle;

  return await crypto.importKey(
    'jwk',
    envMasterPublicJsonWebKey,
    {
      name: 'RSA-OAEP',
      hash: 'SHA-1',
    },
    true,
    ['encrypt'],
  );
}
