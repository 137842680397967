import { makeStyles } from '@teamviewer/ui-library';

export const useSumbitButtonStyles = makeStyles(
  ({ tokens }) =>
    ({
      buttonStyles: {
        borderRadius: tokens.borderRadius.m,
      },
      spinnerStyles: {
        '.ms-Spinner-circle': {
          border: `2px solid ${tokens.colors.theme.themeLighterAlt}`,
          borderTopColor: tokens.colors.theme.themeDarkerAlt,
        },
      },
      textStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
      },
      checkmarkStyles: {
        marginTop: '0.4em',
        marginRight: '0.2em',
        svg: {
          height: '1.4em',
          width: '1.4em',
          fill: tokens.colors.greys.white,
        },
      },
      checkmarkStyleInvalid: {
        svg: {
          fill: tokens.colors.greys.grey60,
        },
      },
      additionalInfoContainerStyles: {
        marginTop: `${tokens.spacings.s} !important`,
      },
      additionalInfoTextStyles: {
        fontSize: tokens.typography.fontSizes.size12,
        lineHeight: tokens.typography.lineHeights.size16,
      },
      iconStyles: {
        margin: 'auto 0',
        fontSize: tokens.typography.fontSizes.size10,
        color: tokens.colors.type.typeBluePrimary,
        marginRight: tokens.spacing(1.25),
        paddingTop: tokens.spacing(0.5),
      },
    }) as const,
);
