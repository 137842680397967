import { Trans } from 'react-i18next';
import { Icon, Stack, TextPrimary, themeColors, themeSpacings } from '@teamviewer/ui-library';
import type { Namespace, TFunction } from 'i18next';

import { lowerCaseLetterRegex, numberRegex, specialCharacterRegex, upperCaseLetterRegex } from 'utils/validationRegex';

import { useStyles } from '../RegisterAccount.styles';

interface PasswordRecomendationProps {
  t: TFunction<Namespace<'en' | 'de'>, undefined>;
  password: string;
  testid?: string;
  touched?: boolean;
}

const PasswordRecomendation = ({ testid, t, password, touched }: PasswordRecomendationProps) => {
  const rules = [
    {
      regex: lowerCaseLetterRegex,
      text: t('lowerCaseLetter'),
      test: 'lowercase-letter',
    },
    {
      regex: upperCaseLetterRegex,
      text: t('uppercaseLetter'),
      test: 'uppercase-letter',
    },
    {
      regex: specialCharacterRegex,
      text: t('specialCharacter'),
      test: 'special-character',
    },
    {
      regex: numberRegex,
      text: t('numbers'),
      test: 'number',
    },
  ];

  const { listIconStyles, passwordRecomendationsContainerStyles, passwordRequirementsHeaderStyles } = useStyles();

  return (
    <Stack
      data-testid={testid}
      tokens={{ childrenGap: themeSpacings.s }}
      className={passwordRecomendationsContainerStyles}
    >
      <Stack.Item>
        <TextPrimary as="h3" variant="large" className={passwordRequirementsHeaderStyles}>
          {t('passwordRequirementsHeader')}
        </TextPrimary>
      </Stack.Item>
      <Stack.Item>
        <Trans
          t={t}
          i18nKey="passwordRequirementsText"
          components={{
            AtLeastXCharacters: (
              <span
                style={{
                  color:
                    password.length >= 6
                      ? themeColors.theme.themePrimary
                      : touched
                        ? themeColors.status.statusErrorTypeOrIcon
                        : themeColors.theme.themePrimary,
                }}
              />
            ),
            UsageOfCommonWords: (
              <span
                style={{
                  color:
                    validFieldsPassword(password) > 1
                      ? themeColors.theme.themePrimary
                      : touched
                        ? themeColors.status.statusErrorTypeOrIcon
                        : themeColors.theme.themePrimary,
                }}
              />
            ),
          }}
          shouldUnescape
          tOptions={{ interpolation: { escapeValue: true } }}
        />
      </Stack.Item>
      <Stack.Item>
        <Stack>
          {rules.map((element) => (
            <Stack.Item
              key={element.test}
              data-testid={`registration-${element.test}-password`}
              style={{
                color: password.match(element.regex)
                  ? themeColors.theme.themePrimary
                  : touched
                    ? validFieldsPassword(password) < 2
                      ? themeColors.status.statusErrorTypeOrIcon
                      : themeColors.greys.grey130
                    : themeColors.greys.grey130,
              }}
            >
              <Stack verticalAlign="center" horizontal>
                <Stack.Item align="center">
                  <Icon
                    className={listIconStyles}
                    iconName="RadioBullet"
                    style={{
                      color: password.match(element.regex)
                        ? themeColors.status.statusSuccessTypeOrIcon
                        : touched
                          ? validFieldsPassword(password) < 2
                            ? themeColors.status.statusErrorTypeOrIcon
                            : themeColors.greys.grey130
                          : themeColors.greys.grey130,
                    }}
                  />
                </Stack.Item>
                <Stack.Item>{element.text}</Stack.Item>
              </Stack>
            </Stack.Item>
          ))}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

const validFieldsPassword = (password: string): number => {
  const rules = [lowerCaseLetterRegex, upperCaseLetterRegex, specialCharacterRegex, numberRegex];
  let amountValidPasswords = 0;
  rules.forEach((rule) => {
    if (password.match(rule)) {
      amountValidPasswords++;
    }
  });
  return amountValidPasswords;
};

export default PasswordRecomendation;
