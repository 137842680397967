import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Toast } from '@teamviewer/ui-library';

import { useAppDispatch, useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';

interface ConfirmationProps {
  icon: string;
  message: string;
  hideProgressBar?: boolean;
}

interface IUseConfirmationToastProps {
  onToastToggle?: () => void;
  successProps?: ConfirmationProps;
  errorProps?: ConfirmationProps & Partial<Record<'errorCode', string>>;
  resetAction?: any;
  showSuccess?: boolean;
  showError?: boolean;
}

const useConfirmationToast = ({
  onToastToggle,
  successProps,
  errorProps,
  resetAction,
  showError = false,
  showSuccess = false,
}: IUseConfirmationToastProps) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('error');
  const { screenSize } = useResponsive();
  const position = screenSize > ScreenSize.Mobile ? 'top-right' : 'top-center';

  useEffect(() => {
    if (showSuccess || showError) {
      onToastToggle && onToastToggle();
      resetAction && dispatch(resetAction);
    }

    if (successProps && showSuccess) {
      toast(
        <Toast
          variant="action"
          iconName={successProps.icon}
          message={successProps.message}
          data-testid="toast-confirmation-success"
        />,
        {
          hideProgressBar: true,
          autoClose: 10000,
          position,
        },
      );
    }

    if (errorProps && showError) {
      const { errorCode } = errorProps;
      const errorCodeInfo = {
        showText: t('showErrorCode'),
        hideText: t('hideErrorCode'),
        errorCode: errorCode ?? '',
      };

      toast(
        <Toast
          variant="error"
          iconName={errorProps.icon}
          message={errorProps.message}
          errorInfo={errorCodeInfo}
          data-testid="toast-confirmation-error"
        />,
        {
          hideProgressBar: true,
          autoClose: 10000,
          position,
        },
      );
    }
  }, [showSuccess, showError, successProps, errorProps, onToastToggle, resetAction, dispatch, t, position]);
};

export default useConfirmationToast;
