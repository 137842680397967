import type { IIconStyles, IStackStyles, IStackTokens, ITextStyles } from '@teamviewer/ui-library';
import { spacing, themeSpacings, themeTypography } from '@teamviewer/ui-library';

export const spacingTokens: IStackTokens = {
  childrenGap: themeSpacings.s,
};

export const seperatorStyle: IStackStyles = {
  root: {
    margin: spacing(0.5),
  },
};

export const boxTextStyles: ITextStyles = {
  root: {
    fontSize: themeTypography.fontSizes.size14,
  },
};

export const iconStyles: IIconStyles = {
  root: {
    marginTop: themeSpacings.xs,
    fontSize: themeTypography.fontSizes.size12,
    paddingRight: spacing(1.5),
    marginLeft: themeSpacings.l,
  },
};

export const titleStyles: Partial<ITextStyles> = {
  root: {
    fontSize: themeTypography.fontSizes.size14,
    fontWeight: themeTypography.fontWeights.semibold,
    marginLeft: themeSpacings.l,
    marginTop: themeSpacings.l,
    marginBottom: themeSpacings.m,
    marginRight: themeSpacings.l,
  },
};
