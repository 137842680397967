import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  i18nextToTeamViewerLanguageCode,
  supportedLanguages,
  teamViewerToI18nextLanguageCode,
} from '@teamviewer/tv-i18next';
import { Dropdown, FooterBar, FooterBarLink, Icon, Stack } from '@teamviewer/ui-library';

import { CopyrightModal } from 'components/Copyright';

import { changeRegion, footerContainerStyles } from './Footer.styles';

const Footer = () => {
  const { t, i18n } = useTranslation('common');
  const [isCopyrightModalOpen, setCopyrightModalOpen] = useState(false);
  return (
    <>
      <CopyrightModal isOpen={isCopyrightModalOpen} close={() => setCopyrightModalOpen(false)} />
      <Stack data-testid="footer" styles={footerContainerStyles}>
        <FooterBar
          copyrightFooterText={
            <Trans
              t={t}
              ns="common"
              i18nKey="copyrightYear"
              values={{ year: new Date().getFullYear() }}
              shouldUnescape
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          }
          blur={false}
        >
          <FooterBarLink href={t('imprintFooterHref')} target="_blank" data-testid="footer-imprint-link">
            {t('imprint')}
          </FooterBarLink>
          <FooterBarLink href={t('privacyPolicyFooterHref')} target="_blank" data-testid="footer-privacy-policy-link">
            {t('privacyPolicy')}
          </FooterBarLink>
          <FooterBarLink data-testid="footer-copyright-link" onClick={() => setCopyrightModalOpen(true)}>
            {t('copyright')}
          </FooterBarLink>
          <FooterBarLink className="optanon-toggle-display" data-testid="footer-cookie-settings-link">
            {t('cookieSettings')}
          </FooterBarLink>
          <Stack>
            <Dropdown
              selectedKey={i18nextToTeamViewerLanguageCode(i18n.language)}
              ariaLabel={t('changeRegion')}
              styles={changeRegion}
              placeholder={t('changeRegion')}
              options={Object.entries(supportedLanguages).map(([key, value]) => ({
                key,
                text: value,
                title: key,
              }))}
              onRenderTitle={(options) => (
                <div style={{ display: 'flex', height: '16px', marginBottom: '6px' }}>
                  <Icon style={{ marginTop: '3px' }} iconName="Globe" aria-hidden="true" title="Globe" />
                  <div style={{ paddingLeft: '6px', color: '#5f6067', marginTop: '1px' }}>
                    {options && options.length > 0 ? t('changeRegion') : ''}
                  </div>
                </div>
              )}
              onChange={(_event, option) =>
                i18n.changeLanguage(option?.key ? teamViewerToI18nextLanguageCode(option.key as string) : 'en')
              }
              data-testid="footer-language-dropdown"
            />
          </Stack>
        </FooterBar>
      </Stack>
    </>
  );
};

export default Footer;
