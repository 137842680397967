import type { TFunction } from 'i18next';

/** represents an arbitrary object (but usually a `string`) that will eventually be translated when a `TFunction` is applied on the UI */
export type Translatable<T = string> = (t: TFunction) => T;

export const Translatable = {
  /**
   * `Translatable` constuctor
   * @param strId string ID  that will passed to the `TFunction` on translatio */
  fromStringId:
    (strId: string): Translatable =>
    (t: TFunction) =>
      t(strId),

  /**
   * Constructor for a `Translatable` that ignores the applied `TFunction`
   * @param textAfterTranslation exact text that will appear after applying any `TFunction`
   * @returns a `Translatable` that will ignore the translation function  */
  literally:
    (textAfterTranslation: string): Translatable =>
    (_: TFunction) =>
      textAfterTranslation,

  /**
   * Tries to construct a `Translatable` that ignores the applied `TFunction`
   * @param textAfterTranslation exact text that will appear after applying any `TFunction`
   * @returns a `Translatable` that will ignore the translation function or `undefined` if `textAfterTranslation` is the empty string or `undefined`  */
  tryLiterally,

  /**
   *
   * @param fallback what be returned if `textAfterTranslation` is the empty string or `undefined`
   * @returns either a `Translatable` constructed from `textAfterTranslation` or `fallback`
   */
  tryLiterallyOrElse: (textAfterTranslation: string | undefined, fallback: Translatable): Translatable => {
    const translatable = tryLiterally(textAfterTranslation);
    return translatable ? translatable : fallback;
  },

  /**
   * A `Translatable` that always results in the empty string when translated
   */
  empty: (_: TFunction) => '',
} as const;

function tryLiterally(textAfterTranslation: undefined): undefined;
function tryLiterally(textAfterTranslation: string): Translatable | undefined;
function tryLiterally(textAfterTranslation: string | undefined): Translatable | undefined;
function tryLiterally(textAfterTranslation: string | undefined): Translatable | undefined {
  return textAfterTranslation ? () => textAfterTranslation : undefined;
}

/** transforms an eventually translated object into another
 * @param mappingFn function that will be applied in the context of an alreay-translated object
 */
export const map = <A, B>(translatable: Translatable<A>, mappingFn: (a: A) => B): Translatable<B> =>
  function newMappedTranslatable(t: TFunction) {
    const translated = translatable(t);
    return mappingFn(translated);
  };

/**
 * transform an eventually translated object into another by applying a function whose result will be also translated
 * @param chainingFn function that will be applied in the context of an alreay-translated object and returns in another `Translatable`
 */
export const chain = <A, B>(translatable: Translatable<A>, chainingFn: (a: A) => Translatable<B>): Translatable<B> =>
  function newChainedTranslatable(t: TFunction) {
    const translated = translatable(t);
    return chainingFn(translated)(t);
  };

const identity = <T>(input: T) => input;
export const identityT = identity as TFunction;
