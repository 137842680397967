import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IActionStatistic, IViewStatistic } from 'models';

import { api } from 'api';

export const trackActionEvent = createAsyncThunk('tracking/action', async (action: IActionStatistic) => {
  api
    .post('statistics/action', {
      actionStatistic: action,
    })
    .then(() => {
      // TODO: log statistics event sent
    })
    .catch(() => {
      // TODO: log error messages
    });
});

export const trackViewEvent = createAsyncThunk('tracking/view', async (view: IViewStatistic) => {
  api
    .post('statistics/view', {
      viewStatistic: view,
    })
    .then(() => {
      // TODO: log statistics event sent
    })
    .catch(() => {
      // TODO: log error messages
    });
});
