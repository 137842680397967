import Axios from 'axios';

// noinspection JSDeprecatedSymbols
import { generateApiClientPlatform2Options } from './generate-api-client-platform2-options';

export const platform2Axios = Axios.create({ baseURL: '' });

export type ApiPlatform2Options = {
  ignoreP2Auth?: boolean;
};

export default function ApiFactoryPlatform2<T>(
  apiType: new (...args: any[]) => T,
  basePath: string,
  apiOptions?: ApiPlatform2Options,
) {
  return (state: unknown): T => {
    // noinspection JSDeprecatedSymbols
    const options = generateApiClientPlatform2Options(state, basePath, apiOptions);
    return new apiType(options, options.basePath, platform2Axios);
  };
}
