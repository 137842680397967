import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { CheckboxFormInput, Stack, TextPrimary, TextSecondary, themeSpacings } from '@teamviewer/ui-library';

import { InfoContainer, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import Banner from 'components/Banner/Banner';
import { IconSize } from 'components/InfoContainer/InfoContainer.model';
import { useAppDispatch, useNavigateWithRedirect } from 'hooks';
import { LoginStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { authActions } from 'store';
import { splitAndStyleString } from 'utils/stringUtils';
import { getCheckBoxRequiredRules } from 'utils/validationRules';

import { useStyles } from './CheckVerifyAccountEmail.styles';

const CheckVerifyAccountEmail = () => {
  const { t } = useTranslation(['checkemail', 'toastMessage']);
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();

  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const {
    control,
    formState: { isValid },
  } = useForm<any>({ mode: 'onChange', reValidateMode: 'onChange' });

  const { virtualPageView } = useAnalytics();

  useEffect(() => {
    if (!email) {
      navigate('/');
    }
  }, [email, navigate]);

  useEffect(() => {
    virtualPageView({ action: 'sign_up', method: 'check_email' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    buttonStyles,
    checkboxStyles,
    descriptionTextStyles,
    emailTextStyles,
    optionalTextStyles,
    titleStyles,
    textWrapStyles,
  } = useStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <Stack>
            <TextPrimary data-testid="registration-verify-title" className={titleStyles}>
              {t('checkEmailTitle')}
            </TextPrimary>
          </Stack>
          <Stack tokens={{ childrenGap: themeSpacings.xl }}>
            <Stack.Item>
              <InfoContainer
                icon="/assets/email_inbox.svg"
                iconAccessibility={t('accountVerified')}
                iconSize={IconSize.big}
                data-testid="registration-verify-info-container"
                text={
                  <TextSecondary
                    variant="medium"
                    data-testid="registration-verify-email"
                    className={descriptionTextStyles}
                  >
                    {splitAndStyleString(
                      t('checkEmailDescription', { email: `<b>${email}</b>` }),
                      /<b>|<\/b>/,
                      emailTextStyles,
                    )}
                  </TextSecondary>
                }
                textWrapStyles={textWrapStyles}
              />
            </Stack.Item>
          </Stack>
          <Stack>
            <CheckboxFormInput
              className={checkboxStyles}
              name="hasVerifiedAccount"
              control={control}
              label={t('hasVerifiedAccountCheckbox')}
              ariaLabel={t('hasVerifiedAccountCheckbox')}
              rules={getCheckBoxRequiredRules()}
              tabIndex={3}
              data-testid="registration-verify-done-checkbox"
            />
          </Stack>
          <SubmitButton
            className={buttonStyles}
            isValid={isValid}
            isLoading={false}
            isCheckmarkVisible={false}
            label={t('signIn')}
            onClick={() => {
              dispatch(authActions.setLoginStatus(LoginStatus.ReadyForLogin));
              navigate(`/?email=${searchParams.get('email') || ''}`, { replace: true });
            }}
            tabIndex={4}
            data-testid="registration-verify-signin-btn"
          />
          <Stack>
            <TextPrimary variant="medium" className={optionalTextStyles} data-testid="registration-verify-spam-text">
              {t('checkSpamFolder')}
            </TextPrimary>
          </Stack>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.CreateAccount />
      </ModalBanner>
    </Modal>
  );
};

export default CheckVerifyAccountEmail;
