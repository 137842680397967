import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      formSectionStyles: {
        marginTop: tokens.spacing(6),
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(9),
        margin: 0,
      },
      subtitleStyles: {
        whiteSpace: 'normal',
        paddingTop: tokens.spacing(2),
      },
      alreadyHaveAccountStyles: {
        display: 'block',
      },
    }) as const,
);
