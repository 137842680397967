// Page Views [PageView]
import type { PathPattern } from 'react-router';
import { matchPath } from 'react-router';
import { findKey } from 'lodash';
import type { IAuthState } from 'models';

import { LoginMethod } from 'models';

export interface AnalyticsPageView {
  page: string;
  isVirtual?: boolean;
}

interface PageState {
  search: URLSearchParams;
  auth: IAuthState;
}

type PageMatcher = {
  [key in string]: {
    pattern: PathPattern<string> | string;
    extraMatcher: (props: PageState) => boolean;
  };
};

export function matchPage(path: string, state: PageState): string | undefined {
  return findKey(
    PageMatches,
    ({ extraMatcher, pattern }) => !!matchPath(pattern, path) && (extraMatcher ? extraMatcher(state) : true),
  );
}

const PageMatches: PageMatcher = {
  'sign_in/start': {
    pattern: { end: true, path: '/' },
    extraMatcher({ auth, search }) {
      return !search.get('email') && !auth.accountInfo.username;
    },
  },
  'sign_in/enter_password': {
    pattern: { end: true, path: '/' },
    extraMatcher({ auth, search }) {
      return !!search.get('email') || !!auth.accountInfo.username;
    },
  },
  'sign_in/untrusted_device': {
    pattern: { end: true, path: '/untrustedDevice' },
    extraMatcher() {
      return true;
    },
  },
  'sign_up/start': {
    pattern: { end: true, path: '/register' },
    extraMatcher({ search }) {
      return !search.get('email') || !search.get('name');
    },
  },
  'sign_up/create_password': {
    pattern: { end: true, path: '/register' },
    extraMatcher({ search }) {
      return !!search.get('email') && !!search.get('name');
    },
  },
  'sso_google/sign_in': {
    pattern: 'sociallogin/signin',
    extraMatcher({ search }) {
      return search.get('id_provider')?.toLowerCase() === 'google';
    },
  },
  'sign_up/set_encryption_key_info': {
    pattern: 'sociallogin/register',
    extraMatcher({ search }) {
      return search.get('id_provider')?.toLowerCase() === 'google';
    },
  },
  'sso_microsoft/sign_in': {
    pattern: 'sociallogin/signin',
    extraMatcher({ search }) {
      return search.get('id_provider')?.toLowerCase() === 'microsoft';
    },
  },
  'sso_microsoft/sign_up': {
    pattern: 'sociallogin/register',
    extraMatcher({ search }) {
      return search.get('id_provider')?.toLowerCase() === 'google';
    },
  },
  'sso_tensor/one_time_password': {
    pattern: { end: true, path: '/' },
    extraMatcher({ auth, search }) {
      return auth.loginMethod === LoginMethod.Sso && (!!search.get('email') || !!auth.accountInfo.username);
    },
  },
};
