import { useTranslation } from 'react-i18next';
import { Stack, TextPrimary, TextSecondary, themeSpacings } from '@teamviewer/ui-library';

import { Banner, InfoContainer, Modal, ModalBanner, ModalMain } from 'components';
import { IconSize } from 'components/InfoContainer/InfoContainer.model';
import { useAppSelector, useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';

import { useStyles } from './ForgotPasswordEmail.styles';

const ResetEmailSent = () => {
  const { t } = useTranslation(['forgotPassword', 'toastMessage']);
  const { screenSize } = useResponsive();
  const { email } = useAppSelector((state) => state.resetPasswod);
  const { linkStyles } = useLinkStyles();

  const {
    checkSpamContainerStyles,
    descriptionTextStyles,
    emailTextStyles,
    optionalTextStyles,
    titleStyles,
    textWrapStyles,
  } = useStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack tokens={{ childrenGap: themeSpacings.s }}>
          <TextPrimary data-testid="forgot-password-verify-title" className={titleStyles}>
            {t('checkEmailTitle')}
          </TextPrimary>
          <Stack>
            <InfoContainer
              icon="/assets/email_inbox.svg"
              iconAccessibility={t('emailSent')}
              iconSize={IconSize.big}
              text={
                <TextSecondary
                  variant="medium"
                  data-testid="forgot-password-verify-email"
                  className={descriptionTextStyles}
                >
                  {splitAndStyleString(
                    t('checkEmailDescription', { email: `<b>${email}</b>` }),
                    /<b>|<\/b>/,
                    emailTextStyles,
                  )}
                </TextSecondary>
              }
              vertical={screenSize <= ScreenSize.Laptop}
              textWrapStyles={textWrapStyles}
            />
          </Stack>
          <Stack className={checkSpamContainerStyles}>
            <Stack.Item>{t('checkSpamFolder')}</Stack.Item>
          </Stack>
          <Stack>
            <Stack.Item className={optionalTextStyles}>
              {splitAndStyleString(
                t('rememberedThePassword', {
                  link: `<>${t('signIn')}</>`,
                }),
                /<>|<\/>/,
                linkStyles,
                `/?email=${email}`,
                2,
                '_self',
                'forgot-password-verify-remembered',
              )}
            </Stack.Item>
          </Stack>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default ResetEmailSent;
