import { useCallback, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import i18n from 'i18next';

import { useConfigManager } from 'config/useConfigManager';
import { useAppSelector } from 'hooks';
import type { AnalyticsPageActionEvent, AnalyticsUserActionEvent, PageAction, UserAction } from './events';
import { useMetadata } from './helper';
import { matchPage } from './pages';

export function useAdobeAnalytics() {
  const metadata = useMetadata();
  return {
    adobeAction<A extends keyof UserAction>(event: AnalyticsUserActionEvent<A>) {
      document.dispatchEvent(
        new CustomEvent(event.action, {
          detail: { method: event.method },
        }),
      );
    },
    virtualPageView<A extends keyof PageAction>(event: AnalyticsPageActionEvent<A>) {
      document.dispatchEvent(
        new CustomEvent('virtualPageView', {
          detail: {
            page: {
              name: `${event.action}/${event.method}`,
              group: `${event.action}`,
              platform: metadata.isNative ? 'login_service|embedded' : 'login_service|web',
              trigger: 'spa',
            },
          },
        }),
      );
    },
  };
}

export function useAdobeAnalyticsPageView() {
  const location = useLocation();
  const [search] = useSearchParams();
  const auth = useAppSelector((state) => state.auth);
  const metadata = useMetadata();
  const configManager = useConfigManager();

  const [isVirtual, setIsVirtual] = useState(false);

  const pageView = useCallback(
    (language?: string) => {
      const page = matchPage(location.pathname, { search, auth });
      if (!page) {
        return;
      }

      const trigger = isVirtual ? 'spa' : 'pageload';
      const platform = metadata.isNative ? 'login_service|embedded' : 'login_service|web';

      const dataLayerElement = document.createElement('script');

      dataLayerElement.innerHTML = `
        var pageData = {
          global: {
            page_language: "${language ? language : metadata.language}",
            page_country: "${metadata.userLocation}",
            geolocation_country: "${metadata.userLocation}"
          },
          page: {
            name: "${page}",
            group: "${page.split('/')[0]}",
            platform: "${platform}",
            trigger: "${trigger}"
          },
          user: {
            id: "",
            login_status: "logged_out"
          }
        }`;
      document.head.appendChild(dataLayerElement);

      const adobeDtmUrl = configManager.get('adobeDtmUrl');
      const script = document.createElement('script');
      script.src = adobeDtmUrl;
      script.async = true;
      document.head.appendChild(script);
    },
    [
      auth,
      configManager,
      isVirtual,
      location.pathname,
      metadata.isNative,
      metadata.language,
      metadata.userLocation,
      search,
    ],
  );

  useEffect(() => {
    pageView();
    if (!isVirtual) {
      setIsVirtual(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    i18n.off('languageChanged', pageView);
    i18n.on('languageChanged', pageView);
    return () => {
      i18n.off('languageChanged', pageView);
    };
  }, [pageView]);
}
