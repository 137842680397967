import type { PropsWithChildren } from 'react';
import type { IIconStyles, IStackStyles } from '@teamviewer/ui-library';
import { Icon, mergeClasses, Stack, TextButton } from '@teamviewer/ui-library';

import LinkWithRedirect from 'components/LinkWithRedirect';
import { useLinkStyles } from 'utils/commonStyles';

import { useIconLinkStyles } from './IconLink.styles';

interface IIconLink {
  iconName: string;
  link: string;
  linkText?: string;
  customContainerStyles?: IStackStyles;
  customIconStyles?: IIconStyles;
  onClick?: () => void;
  tabIndex?: number;
  disabled?: boolean;
  as?: 'TextButton' | 'LinkWithRedirect';
  'data-testid'?: string;
}

const IconLink = ({
  children,
  iconName,
  link,
  linkText,
  customIconStyles,
  onClick,
  tabIndex,
  disabled = false,
  as = 'LinkWithRedirect',
  ...iconLinkProps
}: PropsWithChildren<IIconLink>) => {
  const SelectedLink = as === 'TextButton' ? TextButton : LinkWithRedirect;
  const classes = useIconLinkStyles();

  const { linkStyles } = useLinkStyles();

  return (
    <Stack horizontal verticalAlign="center" data-testid="icon-link-container">
      <SelectedLink
        disabled={disabled}
        href={link}
        className={linkStyles}
        onClick={onClick}
        tabIndex={tabIndex}
        data-testid="icon-link-link"
        {...iconLinkProps}
      >
        <Icon
          iconName={iconName}
          className={mergeClasses(classes.iconStyles, customIconStyles)}
          data-testid="icon-link-icon"
        />
        {linkText}
      </SelectedLink>
      {children}
    </Stack>
  );
};

export default IconLink;
