import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { MessageBarType } from '@teamviewer/ui-library';

interface IMessageBar {
  show: boolean;
  text: string;
  type: MessageBarType;
}

const messageBarInitialState: IMessageBar = {
  show: false,
  text: '',
  type: MessageBarType.Info,
};

const messageBarSlice = createSlice({
  name: 'messageBar',
  initialState: messageBarInitialState,
  reducers: {
    showMessage: (state, action: PayloadAction<Omit<IMessageBar, 'show'>>) => {
      state.show = true;
      state.text = action.payload.text;
      state.type = action.payload.type;
    },
    hideMessage: (state) => {
      state.show = false;
      state.text = '';
      state.type = MessageBarType.Info;
    },
  },
});

export const { showMessage, hideMessage } = messageBarSlice.actions;

export const messageBarReducer = messageBarSlice.reducer;
