import { makeStyles, spacing, themeSpacings } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      titleStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        paddingBottom: '8px',
        whiteSpace: 'normal',
      },
      subtitleStyles: {
        color: tokens.colors.type.typeSecondary,
        whiteSpace: 'normal',
      },
      formSectionStyles: {
        paddingTop: spacing(4),
      },
      optionalTextStyles: {
        marginTop: `${themeSpacings.xl} !important`,
      },
    }) as const,
);
