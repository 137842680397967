import { makeStyles, themeSpacings, themeTypography } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      contentStyles: {
        height: '100%',
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        whiteSpace: 'pre-line',
        marginBottom: themeSpacings.s,
      },
      logoImgStyle: {
        width: '88px',
        height: '88px',
        marginBottom: themeSpacings.l,
      },
      permissionInfoStyles: {
        backgroundColor: tokens.colors.lightMode.lightMode100,
        borderRadius: '12px',
        padding: themeSpacings.s,
        marginTop: themeSpacings.m,
      },
      accordionContainerStyles: {
        paddingTop: themeSpacings.xl,
      },
      accordionItemStyles: {
        width: '100%',
        borderBottom: `1px solid ${tokens.colors.dividersAndBorder.bodyDivider}`,
      },
      accordionHeaderStyles: {
        cursor: 'pointer',
      },
      accordionTextStyles: {
        color: tokens.colors.type.typePrimary,
        fontSize: themeTypography.fontSizes.size16,
        fontWeight: themeTypography.fontWeights.regular,
        padding: `${themeSpacings.s} ${themeSpacings.m}`,
      },
      permissionsListStyles: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: `${themeSpacings.xs} 0 ${themeSpacings.sm} 0`,
      },
      permissionStyles: {
        color: tokens.colors.type.typeSecondary,
        fontSize: tokens.typography.fontSizes.size14,
        fontStyle: 'normal',
        fontWeight: tokens.typography.fontWeights.regular,
        lineHeight: '20px',
      },
      buttonsContainerStyles: {
        paddingTop: themeSpacings.m,
        gap: themeSpacings.s,
      },
    }) as const,
);
