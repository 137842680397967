import type { PropsWithChildren } from 'react';
import { DefaultButton, PrimaryButton, Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { useFullPageLayotStyles } from './FullPageLayout.styles';

interface ButtonProps {
  onClick?: () => void;
  tabIndex?: number;
  isPrimary?: boolean;
}

export const FullPageLayoutTitle = ({ children, ...fullPageTitleProps }: PropsWithChildren<{}>) => {
  const classes = useFullPageLayotStyles();
  return (
    <Stack>
      <TextPrimary className={classes.titleStyles} as="h1" data-testid="full-page-layout-title" {...fullPageTitleProps}>
        {children}
      </TextPrimary>
    </Stack>
  );
};

export const FullPageLayoutDescription = ({ children, ...fullPageSubtitleProps }: PropsWithChildren<{}>) => {
  const classes = useFullPageLayotStyles();
  return (
    <Stack className={classes.descriptionStackSyles}>
      <TextSecondary
        className={classes.descriptionStyles}
        data-testid="full-page-layout-description-text"
        {...fullPageSubtitleProps}
      >
        {children}
      </TextSecondary>
    </Stack>
  );
};

export const FullPageLayoutButton = ({
  children,
  isPrimary = true,
  ...buttonProps
}: PropsWithChildren<ButtonProps>) => {
  const classes = useFullPageLayotStyles();
  return (
    <Stack className={classes.buttonContainerStyles}>
      {isPrimary ? (
        <PrimaryButton className={classes.buttonStyles} data-testid="full-page-layout-primary-button" {...buttonProps}>
          {children}
        </PrimaryButton>
      ) : (
        <DefaultButton
          className={classes.buttonStyles}
          data-testid="full-page-layout-secondary-button"
          {...buttonProps}
        >
          {children}
        </DefaultButton>
      )}
    </Stack>
  );
};

export const FullPageLayoutOptionalText = ({ children, ...optionalTextProps }: PropsWithChildren<{}>) => {
  const classes = useFullPageLayotStyles();
  return (
    <Stack>
      <TextSecondary
        className={classes.optionalTextStyles}
        data-testid="full-page-layout-optional-text"
        {...optionalTextProps}
      >
        {children}
      </TextSecondary>
    </Stack>
  );
};

const FullPageLayout = ({ children, ...props }: PropsWithChildren<{}>) => {
  const classes = useFullPageLayotStyles();
  return (
    <>
      <Stack className={classes.fullPageLayoutStyles} as="main" data-testid="full-page-layout-container" {...props}>
        {children}
      </Stack>
    </>
  );
};

FullPageLayout.Title = FullPageLayoutTitle;
FullPageLayout.Description = FullPageLayoutDescription;
FullPageLayout.Button = FullPageLayoutButton;
FullPageLayout.OptionalText = FullPageLayoutOptionalText;
export default FullPageLayout;
