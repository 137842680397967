import type { FormEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  MaskedFormInput,
  PasswordFormInput,
  Stack,
  TextFormInput,
  TextPrimary,
  themeSpacings,
} from '@teamviewer/ui-library';

import { Banner, IconLink, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import { getEmailValidationRules, getPasswordValidationRules, getRecoveryCodeRules } from 'utils/validationRules';

import { useStyles } from './DeactivateTwoFactor.styles';

const DeactivateTwoFactor = () => {
  const { t } = useTranslation(['deactivateTwoFactor', 'common', 'validation', 'banner']);

  const {
    control,
    handleSubmit,
    formState: { isValid, dirtyFields, submitCount },
  } = useForm<any>({ mode: 'onSubmit', reValidateMode: 'onChange' });

  const isCheckmarkVisible = false;
  const isLoading = false;

  const { titleStyles, formSectionStyles, needHelpStyles } = useStyles();

  // Empty Submit function to trigger form validation
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit((_data) => {})();
  };

  const { linkStyles } = useLinkStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <IconLink
            iconName="Back"
            tabIndex={1}
            link="/"
            disabled={isLoading}
            data-testid="deactivate-tfa-go-back-link"
          >
            <TextPrimary as="h1" className={titleStyles} data-testid="deactivate-tfa-title">
              {t('title')}
            </TextPrimary>
          </IconLink>
        </Stack>
        <form data-testid="deactivate-tfa-form" onSubmit={onSubmit}>
          <Stack className={formSectionStyles} tokens={{ childrenGap: themeSpacings.s }}>
            <TextFormInput
              name="email"
              control={control}
              autoFocus
              tabIndex={2}
              rules={getEmailValidationRules(t)}
              inputMode="email"
              autoComplete="email"
              label={t('common:email')}
              ariaLabel={t('common:email')}
              data-testid="deactivate-tfa-email-input"
              disabled={isLoading}
            />
            <PasswordFormInput
              id="password-input"
              name="password"
              inputMode="text"
              tabIndex={3}
              control={control}
              rules={getPasswordValidationRules(t)}
              autoComplete="current-password"
              label={t('common:password')}
              ariaLabel={t('common:password')}
              data-testid="deactivate-tfa-password-input"
              disabled={isLoading}
            />
            <Stack tokens={{ childrenGap: themeSpacings.l }}>
              <Stack.Item>
                <MaskedFormInput
                  name="recoveryCode"
                  control={control}
                  autoComplete="one-time-code"
                  tabIndex={4}
                  mask="9999999999999999"
                  inputMode="numeric"
                  rules={getRecoveryCodeRules(t)}
                  label={t('common:recoveryCode')}
                  ariaLabel={t('common:recoveryCode')}
                  data-testid="deactivate-tfa-recovery-code-input"
                  disabled={isLoading}
                />
              </Stack.Item>
              <Stack.Item>
                <SubmitButton
                  label={t('deactivate')}
                  tabIndex={5}
                  isValid={isValid || (Object.values(dirtyFields).length === 3 && submitCount === 0)}
                  isCheckmarkVisible={isCheckmarkVisible}
                  isLoading={isLoading}
                  additionalInfo={t('additionalInfo')}
                  data-testid="deactivate-tfa-submit-btn"
                />
              </Stack.Item>
            </Stack>
            <Stack horizontal className={needHelpStyles}>
              {splitAndStyleString(
                t('needHelp', {
                  link: `<>${t('clickHere')}</>`,
                }),
                /<>|<\/>/,
                linkStyles,
                'https://community.teamviewer.com/kb',
                6,
                '_blank',
                'deactivate-tfa-knowledge-hub',
                isLoading,
              )}
            </Stack>
          </Stack>
        </form>
      </ModalMain>
      <ModalBanner>
        <Banner.JustAStepAway />
      </ModalBanner>
    </Modal>
  );
};

export default DeactivateTwoFactor;
