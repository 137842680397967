import { createSlice } from '@reduxjs/toolkit';
import type { ToastVariant } from '@teamviewer/ui-library';
import type { TFunction } from 'i18next';

import { createToast } from './toastNotificationSyncActions';

export type StringMap = { [key: string]: any };

export interface IToastNotification {
  type: ToastVariant;
  message?: string;
  translateFn?: (t: TFunction) => string;
  iconName?: string;
  hideProgressBar?: boolean;
}

interface IToastNotificationsState {
  toastNotification?: IToastNotification;
}

const initialState: IToastNotificationsState = {
  toastNotification: undefined,
};

export const toastNotificationsSlice = createSlice({
  name: 'toastNotification',
  initialState,
  reducers: {
    clearToast: (state) => {
      state.toastNotification = undefined;
    },
  },
  extraReducers(builder) {
    builder.addCase(createToast, (state, action) => {
      const { payload: newToastProps } = action;
      state.toastNotification = newToastProps;
    });
  },
});

export const toastNotificationReducer = toastNotificationsSlice.reducer;
