import React from 'react';
import { Icon, mergeClasses, PrimaryButton, Spinner, SpinnerSize, Stack, TextSecondary } from '@teamviewer/ui-library';

import { useSumbitButtonStyles } from './SubmitButton.styles';

interface SubmitButtonProps {
  isValid: boolean;
  isLoading: boolean;
  isCheckmarkVisible: boolean;
  label: string;
  checkedLabel?: string;
  additionalInfo?: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  'data-testid'?: string;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  isValid,
  isLoading,
  isCheckmarkVisible,
  label,
  checkedLabel,
  additionalInfo,
  onClick,
  disabled,
  className,
  tabIndex,
  ...buttonProps
}) => {
  const classes = useSumbitButtonStyles();

  return (
    <Stack styles={{ root: { margin: '0 !important' } }}>
      <PrimaryButton
        disabled={disabled}
        {...(!isValid && { disabled: true })}
        type="submit"
        className={mergeClasses(classes.buttonStyles, className)}
        onClick={onClick}
        tabIndex={tabIndex}
        {...buttonProps}
      >
        {isLoading ? (
          <Spinner data-testid="submit-loading-spinner" className={classes.spinnerStyles} size={SpinnerSize.medium} />
        ) : (
          <Stack horizontal verticalAlign="center">
            {isCheckmarkVisible && (
              <Stack.Item align="center">
                <Icon
                  data-testid="submit-checkmark"
                  iconName="CheckmarkIcon"
                  className={mergeClasses(classes.checkmarkStyles, !isValid && classes.checkmarkStyleInvalid)}
                />
              </Stack.Item>
            )}
            <Stack.Item className={classes.textStyles} data-testid="submit-label">
              {isCheckmarkVisible && checkedLabel ? checkedLabel : label}
            </Stack.Item>
          </Stack>
        )}
      </PrimaryButton>
      {additionalInfo && (
        <Stack className={classes.additionalInfoContainerStyles}>
          <TextSecondary className={classes.additionalInfoTextStyles} data-testid="submit-additional-info">
            <Icon iconName="Info" className={classes.iconStyles} />
            {additionalInfo}
          </TextSecondary>
        </Stack>
      )}
    </Stack>
  );
};
