import { Stack } from '@teamviewer/ui-library';
import type { TFunction } from 'i18next';

import { useStatusBarStyles } from 'components/OfflineScreen/StatusBar.styles';

export interface OfflineScreenProps {
  t: TFunction<'translation', undefined>;
}

export default function StatusBar({ t }: OfflineScreenProps) {
  const { statusBarIndicatorStyles } = useStatusBarStyles();

  return (
    <Stack horizontal verticalAlign="center">
      <div style={statusBarIndicatorStyles} />
      <Stack.Item>{t('status_bar.no_connection')}</Stack.Item>
    </Stack>
  );
}
