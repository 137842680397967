import { Context, OperatingSystem } from './get-platform-data.model';

export const isNative = () => getContext() === Context.NativeContainer;

export const isWeb = () => getContext() === Context.WebBrowser;

export const isAndroid = () => extractOsFromNavigator(navigator) === OperatingSystem.Android;

export const isLinux = () => extractOsFromNavigator(navigator) === OperatingSystem.Linux;

export const isMacOS = () => extractOsFromNavigator(navigator) === OperatingSystem.MacOS;

export const isWindows = () => extractOsFromNavigator(navigator) === OperatingSystem.Windows;

/**
 * @important navigator.platform is deprecated in favour of userAgentData.
 * As userAgentData is still experimental and platform still available, we will not perform chenges
 * until updates regarding this functionality.
 * @external https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
 */
let os: OperatingSystem = OperatingSystem.NotDetected;

const extractOsFromNavigator = (navigator: Navigator): OperatingSystem => {
  if (os !== OperatingSystem.NotDetected) {
    return os;
  }

  if (navigator.platform.startsWith('Win')) {
    os = OperatingSystem.Windows;
  } else if (navigator.platform.startsWith('Mac')) {
    os = OperatingSystem.MacOS;
  } else if (navigator.platform.startsWith('Linux')) {
    os = OperatingSystem.Linux;
  } else if (navigator.platform.startsWith('Android')) {
    os = OperatingSystem.Android;
  }

  if (os === OperatingSystem.NotDetected) {
    throw new Error('uncapable to detect operative system - possbly consuming deprecated features');
  }

  return os;
};

/**
 * in absence of unified build environment constants among apps,
 * this method collects the context state in a safe scope and provide it over
 * universally accessible function
 **/
let context: Context = Context.NotDetected;

export const setContext = (isNativeContainer: boolean): void => {
  context = isNativeContainer ? Context.NativeContainer : Context.WebBrowser;
};

export const getContext = (): Context => {
  if (context === Context.NotDetected) {
    throw new Error('context was called before being detected');
  }
  return context;
};
