import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IRequestTfaActivationResponse, ITfaEnforcementError } from 'models';

import { api } from 'api';
import { TfaEnforcementStatus } from 'models';

const handleTfaError = (tfaResponse: any): ITfaEnforcementError | void => {
  if (!tfaResponse?.data) {
    return;
  }

  const { ec: errorCode, e: responseCode } = tfaResponse.data;
  return {
    isError: true,
    loggedErrorCode: errorCode,
    responseCode,
  };
};

export const requestTfaActivation = createAsyncThunk(
  'tfa/requestTfaActivation',
  async (token: string, { rejectWithValue }) => {
    try {
      const tfaResponse = await api.post('/tfa/requesttfaactivationviatoken', {
        token,
      });

      if (!tfaResponse.data.s) {
        return rejectWithValue(handleTfaError(tfaResponse));
      }

      return tfaResponse.data.d as IRequestTfaActivationResponse;
    } catch (e) {
      return rejectWithValue({
        status: TfaEnforcementStatus.Failed,
      });
    }
  },
);

export const confirmTfaActivation = createAsyncThunk(
  'tfa/confirmTfaActivation',
  async (
    activationData: {
      securityCode: string;
      token: string;
      tfaState: string;
      tfaStateKeyId: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const tfaResponse = await api.post('/tfa/confirmtfaactivationviatoken', {
        ...activationData,
        multiStageData: {
          TfaState: activationData.tfaState,
          TfaStateKeyId: activationData.tfaStateKeyId,
        },
      });

      if (!tfaResponse.data.s) {
        return rejectWithValue(handleTfaError(tfaResponse));
      }

      return {
        status: TfaEnforcementStatus.Successed,
      };
    } catch (e) {
      return rejectWithValue({
        status: TfaEnforcementStatus.Failed,
      });
    }
  },
);
