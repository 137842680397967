import { combineReducers } from '@reduxjs/toolkit';

import { trustDeviceReducer } from 'pages/TrustDevice/store/trustDeviceSlice';
import { enforceTfaReducer } from 'store/enforce-tfa';
import { messageBarReducer } from 'store/message-bar';
import { authReducer } from './auth';
import { oauthReducer } from './oauth';
import { registerAccountReducer } from './register-account';
import { resetPasswordReducer } from './reset-password';
import { verifyAccountReducer } from './verify-account';

export default combineReducers({
  auth: authReducer,
  oauth: oauthReducer,
  enforceTfa: enforceTfaReducer,
  registerAccount: registerAccountReducer,
  verifyAccount: verifyAccountReducer,
  messageBar: messageBarReducer,
  resetPasswod: resetPasswordReducer,
  trustDevice: trustDeviceReducer,
});
