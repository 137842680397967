import Cookies from 'js-cookie';

import { api } from 'api';
import { ClientOs, ClientType } from 'models';

export type ClientOSType = keyof typeof ClientOs;

async function loadInitialConfig() {
  const searchParams = new URLSearchParams(document.location.search);
  const isNativeClient =
    searchParams.get('is_native_client')?.toLowerCase() === 'true' || Cookies.get('isNativeClient') === '1';

  let response;
  try {
    response = await api.post('/config/getinitialconfig');
  } catch (err: any) {
    const fatal = new Error();
    Object.assign(fatal, err);
    fatal.message = `Fatal error: Unable to retrieve initial service configuration. ${err.message}`;
    fatal.stack = err.stack;
    throw fatal;
  }

  if (!response.data?.s) {
    throw Error(
      `Fatal error: Unable to retrieve initial service configuration: ${JSON.stringify(response.data, null, 2)}`,
    );
  }

  if (!response.data.d?.EnvironmentName) {
    throw Error(
      'Fatal error: Initial service configuration from backend did not contain the EnvironmentName field: ' +
        JSON.stringify(response.data, null, 2),
    );
  }

  // Flag indicates that LoginService is loaded/embedded inside the client window
  const clientOs = ClientOs[Cookies.get('ClientOS') as ClientOSType] ?? ClientOs.Unknown;

  return {
    clientType: isNativeClient ? ClientType.NativeClient : ClientType.WebClient,
    clientOs,
    requireCaptcha: response.data.d.IsCaptchaRequired as boolean,
    isAlibabaCaptcha: response.data.d.IsAlibabaCaptcha as boolean,
    countryCode: response.data.d.CountryCode as string,
    environmentName: response.data.d.EnvironmentName as string,
  } as const;
}

export type InitialServiceConfig = Awaited<ReturnType<typeof loadInitialConfig>>;

let initialServiceConfigPromise: ReturnType<typeof loadInitialConfig> | undefined = undefined;

export const getInitialServiceConfig = async () => {
  if (initialServiceConfigPromise === undefined) {
    initialServiceConfigPromise = loadInitialConfig();
  }

  return await initialServiceConfigPromise;
};
