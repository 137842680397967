import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  () =>
    ({
      containerStyles: {
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '700px',
      },
    }) as const,
);
