import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { mergeCss, mergeStyleSets } from '@fluentui/merge-styles';
import { Stack } from '@fluentui/react';
import { useConnectionStatus } from '@teamviewer/utils';
import type { TFunction } from 'i18next';
import Router from 'Router';

import { Footer, Header } from 'components';
import { OfflineScreen } from 'components/OfflineScreen/OfflineScreen';
import { ThemeProviderFui } from 'components/Theme';
import { ConfigManagerProvider } from 'config/config-manager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useDisableZoomOnMobileDevices, useResponsive } from 'hooks';
import { useCookieBanner } from 'hooks/useCookieBanner';
import { ScreenSize } from 'hooks/useResponsive';
import { ClientOs, ClientType } from 'models';

import { footerMarginStyles, pageContentStyles, pageContentWrapperStyles, toastContainerStyles } from 'App.styles';
import 'react-toastify/dist/ReactToastify.css';

const AppWrapper = (): JSX.Element => (
  <ConfigManagerProvider>
    <App />
  </ConfigManagerProvider>
);

function App() {
  const { t } = useTranslation('offline_screen');
  const { clientType, clientOs } = useInitialServiceConfig();
  const isNativeClient = clientType === ClientType.NativeClient;
  useCookieBannerConfig(isNativeClient);
  useDisableZoomOnMobileDevices();

  const isOnline = useConnectionStatus();
  const defaultClientOs = ClientOs.Unknown;
  const unwrappedClientOs = clientOs || defaultClientOs;
  const { screenSize } = useResponsive();

  return (
    <ConfigManagerProvider>
      {renderByServiceConfigStatus(isNativeClient, screenSize, unwrappedClientOs, isOnline, t)}
    </ConfigManagerProvider>
  );
}

function useCookieBannerConfig(isNativeClient: boolean): void {
  useCookieBanner({
    hide: isNativeClient || process.env.REACT_APP_HIDE_COOKIE_BANNER === 'true',
  });
}

interface RenderProps {
  t: (key: string) => string;
  isNativeClient: boolean;
}

function renderByServiceConfigStatus(
  isNativeClient: boolean,
  screenSize: ScreenSize,
  clientOs: ClientOs,
  isOnline: boolean,
  t: TFunction,
): JSX.Element {
  const showHeader = (!isNativeClient || screenSize <= ScreenSize.Tablet) && isOnline;
  const showFooter = !isNativeClient && isOnline;
  const renderContent = (MainComponent: React.FC<RenderProps>) => (
    <Stack
      verticalAlign="start"
      styles={!isNativeClient ? mergeStyleSets(pageContentStyles, footerMarginStyles) : pageContentStyles}
    >
      {showHeader && <Header />}
      <MainComponent t={t} isNativeClient={isNativeClient} />
      {showFooter && <Footer />}
    </Stack>
  );

  const wrapWithCommonUI = (children: React.ReactNode) => (
    <BrowserRouter>
      <ThemeProviderFui>
        <Stack styles={pageContentWrapperStyles}>
          <ToastContainer
            autoClose={false}
            draggable={false}
            closeOnClick={false}
            closeButton={false}
            limit={4}
            className={mergeCss(toastContainerStyles)}
          />
          {children}
        </Stack>
      </ThemeProviderFui>
    </BrowserRouter>
  );

  if (!isOnline && ![ClientOs.iOS, ClientOs.iPadOS].includes(clientOs)) {
    return wrapWithCommonUI(renderContent(() => <OfflineScreen t={t} isNativeClient={isNativeClient} />));
  }

  return wrapWithCommonUI(renderContent(Router));
}

export default AppWrapper;
