import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { ClientType } from 'models';

// helpers
export function useMetadata() {
  const { clientType, countryCode } = useInitialServiceConfig();

  // Get i18n cookie
  const matchedI18nCookie = document.cookie.match(new RegExp('(^| )lang=([^;]+)'));
  const language = matchedI18nCookie ? matchedI18nCookie[2] : 'en';

  return {
    isNative: clientType === ClientType.NativeClient,
    language,
    userLocation: countryCode.toLowerCase(),
  };
}

export type AnalyticsMetadata = ReturnType<typeof useMetadata>;
