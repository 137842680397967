import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import type { IIconStyles } from '@teamviewer/ui-library';
import { Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';
import Lottie from 'lottie-web';

import * as animationData from 'assets/education-screen-animation.json';
import { Box, IconLink, Modal, ModalMain, SubmitButton } from 'components';
import { useTracking } from 'hooks';
import { useStyles } from './EducationScreen.Styles';

type Props = {
  setOpen: (newValue: boolean) => void;
};

const EducationScreen = ({ setOpen }: Props) => {
  const { t } = useTranslation(['educationscreen']);
  const { trackAction } = useTracking();
  const [searchParams] = useSearchParams();
  const idProvider = searchParams.get('id_provider') || '';

  const {
    animationStyle,
    backIconStyles,
    buttonStyles,
    marginStyleBoxLeft,
    marginStyleBoxRight,
    subtitleStyles,
    titleStyles,
  } = useStyles();

  useEffect(() => {
    const animationContainer = document.querySelector('#animation');

    if (!animationContainer) {
      return;
    }

    Lottie.loadAnimation({
      container: animationContainer,
      renderer: 'svg', // Required
      loop: false, // Optional
      autoplay: true, // Optional
      name: 'Education Screen Animation', // Name for future reference. Optional.
      animationData,
    });
  }, []);

  return (
    <Modal>
      <ModalMain>
        <Stack verticalAlign="space-between">
          <Stack>
            <Stack>
              <IconLink
                iconName="BackBlue"
                link="/register"
                tabIndex={1}
                linkText={t('goBack')}
                customIconStyles={backIconStyles as IIconStyles}
                onClick={() => {
                  trackAction('user-account', 'social-login-create-account-cancel');
                }}
              />
              <TextPrimary as="h1" block className={titleStyles}>
                {t('title')}
              </TextPrimary>
              <TextSecondary variant="medium" className={subtitleStyles}>
                {t('description', { service: idProvider })}
              </TextSecondary>
              <div id="animation" className={animationStyle} />
            </Stack>
            <Stack horizontal>
              <Box
                title={t('quickAndEasy')}
                text={[t('automaticEmailValidation'), t('signingWithoutDeviceApproval'), t('signedInDefaultBrowser')]}
                marginClassName={marginStyleBoxLeft}
              />
              <Box
                title={t('highlySecure')}
                text={[t('encryptionNeverLeavestourDevice'), t('endtoendEncryption'), t('protectionBruteForceAttack')]}
                marginClassName={marginStyleBoxRight}
              />
            </Stack>
          </Stack>
          <Stack.Item align="end">
            <SubmitButton
              label={t('setMyEncryptionKey')}
              tabIndex={0}
              isValid
              isCheckmarkVisible={false}
              onClick={() => setOpen(true)}
              isLoading={false}
              className={buttonStyles}
              checkedLabel={t('setMyEncryptionKey')}
            />
          </Stack.Item>
        </Stack>
      </ModalMain>
    </Modal>
  );
};

export default EducationScreen;
