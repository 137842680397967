import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      twoFactorTitleStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        paddingBottom: tokens.spacings.s,
        whiteSpace: 'normal',
      },
      twoFactorSubtitleStyles: {
        whiteSpace: 'normal',
      },
    }) as const,
);
