import { useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import type { BackendError } from 'models';

import { api } from 'api';
import { useConfigManager } from 'config/useConfigManager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useAppDispatch, useAppSelector, useConfirmationToast, useErrorMessage, useNavigateWithRedirect } from 'hooks';
import { AuthProtocol, ClientType, LoginMethod, LoginStatus, NoBackendError, ResponseCodeType, TfaFor } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { authActions } from 'store';
import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { Translatable } from 'utils/translation';
import { validateIsCustomProtocol } from 'utils/validationFunctions';
import { emailRegex } from 'utils/validationRegex';

export default function useAuthRedirect() {
  const ssoReturnUrlPath = '/sso/returnurl';
  const location = useLocation();
  const navigate = useNavigateWithRedirect();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const {
    loginMethod,
    authProtocol,
    loginStatus,
    accountInfo,
    keepMeSignedIn,
    loginTokenInfo,
    enforcedTfaInfo,
    ssoInfo,
    socialLoginInfo,
  } = useAppSelector((state) => state.auth);
  const [successMessage, setSuccessMessage] = useState('');
  const isSocialLogin = searchParams.get('is_social_login')?.toLowerCase() === 'true';
  const email = searchParams.get('email') || accountInfo.username;
  const configManager = useConfigManager();
  const redirectUri = searchParams.get('redirect_uri') || configManager.get('defaultRedirectUri');
  const isOauth = redirectUri.includes('oauth2');
  const isNativeClient = useInitialServiceConfig().clientType === ClientType.NativeClient;
  const isFromNativeClient = searchParams.get('is_from_native_client')?.toLowerCase() === 'true';

  const isError = searchParams.get('is_error')?.toLowerCase() === 'true';
  const errorType = parseInt(searchParams.get('error_type') ?? '');
  const errorCode = parseInt(searchParams.get('error_code') ?? '');
  const loggedErrorCodeParam = searchParams.get('logged_error_code');

  const error = useMemo((): BackendError => {
    if (!isError) {
      return NoBackendError;
    }

    if (errorType !== ResponseCodeType.Social) {
      return {
        isError: true,
        messageCreator: Translatable.fromStringId('error:unknownError'),
      };
    }

    return {
      isError: true,
      messageCreator: generateStringIdForErrorMessage({
        responseCodeType: errorType,
        responseCode: errorCode,
      }),
      errorCode: loggedErrorCodeParam ?? undefined,
    };
  }, [isError, errorType, errorCode, loggedErrorCodeParam]);

  const { errorMessage, errorCode: loggedErrorCode, setErrorMessage } = useErrorMessage(() => error);

  const { analyticsAction } = useAnalytics();

  useConfirmationToast({
    successProps: {
      icon: 'SendIcon',
      message: successMessage,
    },
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode: loggedErrorCode,
    },
    resetAction: () => {
      setSuccessMessage('');
      setErrorMessage('');
    },
    showSuccess: successMessage !== '',
    showError: errorMessage !== '',
  });

  useEffect(() => {
    if (location.pathname === ssoReturnUrlPath || (isSocialLogin && isFromNativeClient)) {
      return;
    }

    const rgx = new RegExp(emailRegex);
    if (email && !rgx.test(email)) {
      dispatch(authActions.setUsername(''));
      searchParams.delete('email');
      navigate({ pathname: location.pathname, search: searchParams.toString() }, { replace: true });
      return;
    }

    const params = new URLSearchParams();

    if (searchParams.get('email') && !accountInfo.username) {
      dispatch(authActions.setUsername(email));
    }

    if (!searchParams.get('email') && accountInfo.username) {
      params.append('email', accountInfo.username);
    }

    if (!searchParams.get('redirect_uri')) {
      params.append('redirect_uri', redirectUri);
    }

    // It means we added a missing param
    if (params.get('email') || params.get('redirect_uri')) {
      navigate({ pathname: location.pathname, search: params.toString() }, { replace: true });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, accountInfo, email, searchParams, location]);

  useEffect(() => {
    dispatch(authActions.setAuthProtocol(isOauth ? AuthProtocol.OAuth : AuthProtocol.TvAuth));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOauth]);

  useEffect(() => {
    if (!isSocialLogin) {
      return;
    }

    dispatch(authActions.setLoginMethod(LoginMethod.SocialLogin));

    if (isFromNativeClient) {
      navigate({
        pathname: '../launch-teamviewer',
        search: searchParams.toString(),
      });
      return;
    }

    // SsoVerificationToken is required in all cases as proof that SSO check has been made
    const ssoVerificationTokenParam = searchParams.get('sso_verification_token') ?? '';
    if (ssoVerificationTokenParam) {
      dispatch(authActions.setSsoVerificationToken(ssoVerificationTokenParam));
    }

    // If isError is true and not isFromNativeClient the error will be shown be the next useEffect
    if (isError) {
      return;
    }

    const socialLoginStep = searchParams.get('step') || '';

    if (isOauth && !socialLoginStep) {
      const idProvider = searchParams.get('id_provider');
      switch (idProvider) {
        case 'Google': {
          api
            .get('sociallogin/continuewithgoogle', {
              params: {
                redirectUri,
                isNativeClient: false,
                ssoVerificationToken: ssoVerificationTokenParam,
                email,
              },
            })
            .then((response) => {
              if (response?.data?.s) {
                window.location.assign(response.data.d);

                return;
              }
              setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
            })
            .catch((error) => {
              setErrorMessage(error.message);
            });
          break;
        }
        case 'Microsoft': {
          api
            .get('sociallogin/continuewithmicrosoft', {
              params: {
                redirectUri,
                isNativeClient: false,
                ssoVerificationToken: ssoVerificationTokenParam,
                email,
              },
            })
            .then((response) => {
              if (response?.data?.s) {
                window.location.assign(response.data.d);

                return;
              }
              setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
            })
            .catch((error) => {
              setErrorMessage(error.message);
            });
          break;
        }
        case 'Apple': {
          api
            .get('sociallogin/continuewithapple', {
              params: {
                redirectUri,
                isNativeClient: false,
                ssoVerificationToken: ssoVerificationTokenParam,
                email,
              },
            })
            .then((response) => {
              if (response?.data?.s) {
                window.location.assign(response.data.d);

                return;
              }
              setErrorMessage(response?.data?.m || 'Unkown error happened. Please try again later');
            })
            .catch((error) => {
              setErrorMessage(error.message);
            });
          break;
        }
      }
    }

    if (socialLoginStep) {
      const idToken = searchParams.get('id_token') || '';
      const name = searchParams.get('name') || '';
      const idProvider = searchParams.get('id_provider') || '';
      const socialloginPath = `/sociallogin/${socialLoginStep}`;

      navigate({
        pathname: socialloginPath,
        search: new URLSearchParams({
          email,
          name,
          id_provider: idProvider,
          id_token: idToken,
        }).toString(),
      });

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSocialLogin, email, searchParams, isError]);

  useEffect(() => {
    switch (loginStatus) {
      case LoginStatus.VerifyAccountPending:
        navigate(
          {
            pathname: '/verify-account',
            search: new URLSearchParams({
              email: accountInfo.username,
            }).toString(),
          },
          {
            replace: true,
          },
        );
        break;
      case LoginStatus.TrustDevicePending:
        navigate({ pathname: '/untrustedDevice', search: `?email=${accountInfo.username}` });
        break;
      case LoginStatus.TfaSecurityCodeRequired:
        if (location.pathname !== '/tfa') {
          navigate(
            {
              pathname: '/tfa',
              search: searchParams.toString(),
            },
            {
              state: {
                tfaFor: TfaFor.Login,
              },
            },
          );
        }
        break;
      case LoginStatus.SsoLoginRequired:
        if (ssoInfo.ssoServiceUrl.length === 0) {
          break;
        }
        analyticsAction({ action: 'signUp', method: 'tensor' });
        if (isNativeClient && Cookies.get('useEmbeddedLogin') === '0') {
          // native client setting set to open SSO login url in new window (system default brwoser)
          window.open(ssoInfo.ssoServiceUrl);
        } else {
          window.location.assign(ssoInfo.ssoServiceUrl);
        }
        break;
      case LoginStatus.SsoOneTimePasswordLoginRequired:
        if (location.pathname !== ssoReturnUrlPath) {
          break;
        }
        analyticsAction({ action: 'signUp', method: 'tensor' });
        navigate({
          pathname: '/sso/sso-link',
          search: new URLSearchParams({
            email: accountInfo.username,
            accountid: accountInfo.accountId,
            ssoverificationtoken: ssoInfo.ssoVerificationToken,
          }).toString(),
        });
        break;
      case LoginStatus.EnforcedTfa:
        if (location.pathname === '/enforce-tfa') {
          break;
        }

        navigate(
          {
            pathname: '/enforce-tfa',
            search: searchParams.toString(),
          },
          {
            state: {
              tfaFor: TfaFor.Login,
            },
          },
        );

        break;
      case LoginStatus.ReadyToRedirect: {
        const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');

        // If the path is relative, it'll add the base to it.
        const url = new URL(redirectUri, window.location.origin);

        if (authProtocol === AuthProtocol.OAuth) {
          window.location.replace(url);
        }

        if (!(accountInfo?.accountId && loginTokenInfo?.tokenId && loginTokenInfo?.token)) {
          break;
        }

        const search = new URLSearchParams({
          username: accountInfo?.username,
          accountid: accountInfo?.accountId,
          tokenid: loginTokenInfo?.tokenId,
          logintoken: loginTokenInfo?.token,
          ssoverificationtoken: ssoInfo.ssoVerificationToken,
          keepmesignedin: String(keepMeSignedIn),
        });

        // apend sso logout url if exists
        if (ssoInfo.ssoLogoutUrl) {
          search.append('ssologouturl', decodeURIComponent(ssoInfo.ssoLogoutUrl));
        }

        url.search = url.searchParams.merge(search).toString();

        if (!validateIsCustomProtocol(redirectUri)) {
          window.location.replace(url);
        } else if (isNativeClient) {
          window.location.assign(url.href);
        } else {
          navigate(
            {
              pathname: '../launch-teamviewer',
              search: search.toString(),
            },
            {
              replace: true,
            },
          );
        }
        break;
      }
      case LoginStatus.SocialAccountLoginRequired: {
        if (loginMethod !== LoginMethod.SocialLogin || socialLoginInfo.socialAccountIssuer === 'None') {
          break;
        }

        let endpointContinueUrl;
        switch (socialLoginInfo.socialAccountIssuer) {
          case 'Google':
            endpointContinueUrl = 'sociallogin/continuewithgoogle';
            break;
          case 'Microsoft':
            endpointContinueUrl = 'sociallogin/continuewithmicrosoft';
            break;
          case 'Apple':
            endpointContinueUrl = 'sociallogin/continuewithapple';
            break;
        }

        if (!endpointContinueUrl) {
          break;
        }

        api
          .get(endpointContinueUrl, {
            params: {
              redirectUri,
              isNativeClient,
              ssoVerificationToken: ssoInfo.ssoVerificationToken,
              email,
            },
          })
          .then((response) => {
            if (response?.data?.s) {
              const isEmbeddedSocialLogin = Cookies.get('useEmbeddedSocialLogin') === '1';
              const openInNewTab = isNativeClient && !isEmbeddedSocialLogin;

              if (openInNewTab) {
                window.open(response.data.d);
              } else {
                window.location.assign(response.data.d);
              }

              return;
            }
            const unknownErrorMessage = 'Unknown error. Please try again later.';
            setErrorMessage(response?.data?.m || unknownErrorMessage);
            dispatch(authActions.setLoginStatus(LoginStatus.Failed));
            dispatch(authActions.setIsLoginInProgress(false));
            return;
          })
          .catch((error) => {
            setErrorMessage(error.message);
          });
        break;
      }
    }
  }, [
    loginMethod,
    authProtocol,
    loginStatus,
    keepMeSignedIn,
    accountInfo,
    loginTokenInfo,
    searchParams,
    location,
    enforcedTfaInfo.enforcedTfaToken,
    email,
    redirectUri,
    isNativeClient,
    ssoInfo,
    socialLoginInfo,
    navigate,
    analyticsAction,
    dispatch,
    setErrorMessage,
    configManager,
  ]);

  return;
}
