export enum LoginStatus {
  ReadyForLogin = 'ReadyForLogin',
  InProgress = 'InProgress',
  SsoLoginRequired = 'SsoLoginRequired',
  SocialAccountLoginRequired = 'SocialAccountLoginRequired',
  SsoOneTimePasswordLoginRequired = 'SsoOneTimePasswordLoginRequired',
  TrustDeviceDeclined = 'TrustDeviceDeclined',
  TrustDevicePending = 'TrustDevicePending',
  VerifyAccountPending = 'VerifyAccountPending',
  TfaSecurityCodeRequired = 'TfaSecurityCodeRequired',
  Authenticated = 'Authenticated',
  Successed = 'Successed',
  ReadyToRedirect = 'ReadyToRedirect',
  EnforcedTfa = 'EnforcedTfa',
  Denied = 'Denied',
  Failed = 'Failed',
}
