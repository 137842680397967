export enum OAuthErrors {
  ClientIdMissing = 'client_id_missing',
  ReturnUrlMissing = 'return_url_missing',
  ResponseTypeMissing = 'response_type_missing',
  ClientIdInvalid = 'client_id_invalid',
  ClientIdNotFound = 'client_id_not_found',
  ReturnUrlMismatch = 'return_url_mismatch',
  ReturnUrlInvalid = 'return_url_invalid',
  ScopeInvalid = 'scope_invalid',
  ScopeTypeInvalid = 'scope_type_invalid',
  ScopeExceeds = 'request_scope_exceeds_app_scope',
  CodeChallangeMissing = 'code_challange_missing',
  CodeChallangeInvalid = 'code_challange_invalid',
  LicenseRequired = 'app_require_license',
  NotServiceLoginId = 'service_login_not_allowed',
  NotInCompany = 'account_not_in_company',
  HttpsRequired = 'https_required',
  TrialLicenseFailed = 'obtain_trial_license_failed',
  ScopeNotAllowed = 'requested_scope_not_allowed',
  GrantAccessNotAllowed = 'grant_access_not_allowed',
  AccountMissingPermissions = 'account_missing_permissions',
}
