const testConfig: Partial<AppConfig> = {
  defaultRedirectUri: 'redirectURI',
  axe: {
    analysisDelayTime: 1000,
    config: {
      rules: [
        {
          id: 'skip-link',
          enabled: true,
        },
      ],
      disableDeduplicate: true,
    },
  },
};
export default testConfig;
