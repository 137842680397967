import type { IDialogStyles, IStackItemStyles } from '@teamviewer/ui-library';
import {
  makeStyles,
  spacing,
  themeBorderRadius,
  themeColors,
  themeSpacings,
  themeTypography,
} from '@teamviewer/ui-library';

export const dialogStyles: Partial<IDialogStyles> = {
  root: {
    '.ms-Dialog-main': {
      padding: themeSpacings.xl,
      maxWidth: '560px',
      borderRadius: themeBorderRadius.l,
    },
    '.ms-Dialog-header': {
      marginBottom: themeSpacings.xl,
    },
    '.ms-Dialog-title': {
      padding: spacing(0),
      fontSize: themeTypography.fontSizes.size28,
      fontWeight: themeTypography.fontWeights.semibold,
    },
    '.ms-Dialog-inner': {
      padding: spacing(0),
    },
  },
};

export const titleStyles: IStackItemStyles = {
  root: {
    color: themeColors.type.typePrimary,
    fontSize: themeTypography.fontSizes.size28,
    fontWeight: themeTypography.fontWeights.semibold,
    marginBottom: themeSpacings.m,
  },
};

export const textStyles: IStackItemStyles = {
  root: {
    color: themeColors.type.typeSecondary,
    fontSize: themeTypography.fontSizes.size16,
  },
};

export const primaryButtonStyles = makeStyles(
  ({ tokens }) =>
    ({
      buttonStyle: {
        backgroundColor: tokens.colors.theme.themePrimary,
        marginTop: spacing(8),
      },
    }) as const,
);

export const stayTunedImageStyles = {
  root: { borderRadius: themeBorderRadius.l },
};
