import { useEffect } from 'react';
import { Trans } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { i18nextToTeamViewerLanguageCode } from '@teamviewer/tv-i18next';
import {
  Callout,
  CheckboxFormInput,
  DirectionalHint,
  PasswordFormInput,
  Stack,
  TextPrimary,
  TextSecondary,
} from '@teamviewer/ui-library';
import i18n from 'i18n';
import type { Namespace, TFunction } from 'i18next';

import { Banner, IconLink, LinkWithRedirect, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import InfoIconWithTextAndTooltip from 'components/InfoIconWithTextAndTooltip';
import { useConfigManager } from 'config/useConfigManager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useAppDispatch, useAppSelector, useFormWithCaptcha, useNavigateWithRedirect } from 'hooks';
import { usePasswordStrengthMessages } from 'hooks/usePasswordStrengthMessages.hook';
import { RegisterAccountStatus } from 'models';
import { useAnalytics } from 'modules/Analytics';
import { registerAccountAsyncAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import { getCheckBoxRequiredRules } from 'utils/validationRules';
import PasswordRecomendation from './PasswordRecommendation';

import { calloutWindowStyles, useStyles } from '../RegisterAccount.styles';

interface RegisterAccountProps {
  t: TFunction<Namespace<'en' | 'de'>, undefined>;
  email: string;
  name: string;
  onGoBack(): void;
}

const RegisterAccountPassword = ({ t, name, email, onGoBack }: RegisterAccountProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigateWithRedirect();
  const [searchParams] = useSearchParams();
  const { registerAccountStatus, error } = useAppSelector((state) => state.registerAccount);
  const { countryCode } = useInitialServiceConfig();
  const isLoading = registerAccountStatus === RegisterAccountStatus.InProgress;
  const isAccountCreated = registerAccountStatus === RegisterAccountStatus.Successed;
  const buttonId = useId('callout-password');
  const configManager = useConfigManager();
  const showPasswordRequirements = configManager.get('showPasswordRequirements');
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const languageCode = i18nextToTeamViewerLanguageCode(i18n.language);
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');

  const { linkStyles } = useLinkStyles();

  const {
    dataProcessingAgreementStyles,
    formSectionStyles,
    signInButtonStyles,
    signInStyles,
    subtitleStyles,
    titleStyles,
    tOSStyles,
  } = useStyles();

  const { analyticsAction, virtualPageView } = useAnalytics();
  useEffect(() => {
    if (registerAccountStatus === RegisterAccountStatus.Successed) {
      analyticsAction({ action: 'signUp', method: 'email' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerAccountStatus]);

  const {
    control,
    formState: { isValid },
    watch,
    trigger,
    getFieldState,
    recaptchaLoaded,
    captchaComponent,
    executeRecaptcha,
  } = useFormWithCaptcha<{ email: string; name: string; password: string; acceptedToS: boolean }>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { email, name, password: '' },
    async onSubmit({ captchaResponse, name, email, password }) {
      const { payload } = await dispatch(
        registerAccountAsyncAction({
          payload: {
            DisplayName: name,
            Email: email,
            Password: password,
            RedirectUri: redirectUri,
            'invisiblecaptcha-response': captchaResponse,
          },
          t,
        }),
      );

      if (payload?.registerAccountStatus === RegisterAccountStatus.Successed) {
        navigate({ pathname: '../check-email', search: createSearchParams({ email }).toString() });
      }
    },
  });

  const watchPasswordField = watch('password');
  const stateField = getFieldState('password').isTouched;
  const passwordStrengthMessages = usePasswordStrengthMessages();

  useEffect(() => {
    if (watchPasswordField) {
      trigger('password');
    }
  }, [watchPasswordField, trigger]);

  useEffect(() => {
    if (error?.isError) {
      if (error?.responseCode === 5) {
        onGoBack();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    virtualPageView({ action: 'sign_up', method: 'create_password' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal>
      <ModalMain>
        <IconLink
          iconName="Back"
          as="LinkWithRedirect"
          link="/register"
          disabled={isLoading}
          onClick={() => {
            onGoBack();
          }}
          tabIndex={1}
          data-testid="registration-go-back-link"
        >
          <TextPrimary as="h1" block data-testid="registration-password-title" className={titleStyles}>
            {t('titleStep2')}
          </TextPrimary>
        </IconLink>
        <Stack>
          <TextSecondary
            variant="medium"
            className={subtitleStyles}
            nowrap
            block
            data-testid="registration-password-subtitle"
          >
            {t('subtitleStep2')}
          </TextSecondary>
          <Stack.Item className={formSectionStyles}>
            <Stack as="form" data-testid="registration-password-form" onSubmit={executeRecaptcha}>
              {captchaComponent}
              <Stack.Item>
                <PasswordFormInput
                  id={buttonId}
                  disabled={isLoading}
                  control={control}
                  inputMode="text"
                  name="password"
                  autoComplete="new-password"
                  autoFocus
                  label={t('password')}
                  ariaLabel={t('password')}
                  data-testid="registration-password-input"
                  passwordStrengthMessages={passwordStrengthMessages}
                  tabIndex={2}
                />

                {isCalloutVisible && showPasswordRequirements && (
                  <Callout
                    role="dialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    directionalHint={DirectionalHint.rightTopEdge}
                    onDismiss={toggleIsCalloutVisible}
                    styles={calloutWindowStyles}
                  >
                    <PasswordRecomendation t={t} password={watchPasswordField || ''} touched={stateField} />
                  </Callout>
                )}
              </Stack.Item>
              <Stack.Item>
                <Stack className={tOSStyles}>
                  <CheckboxFormInput
                    disabled={isLoading}
                    name="acceptedToS"
                    control={control}
                    rules={getCheckBoxRequiredRules()}
                    onRenderLabel={() => (
                      <span>
                        <Trans
                          ns="common"
                          i18nKey="acceptEULAAndDPA"
                          components={{
                            link1: (
                              <LinkWithRedirect
                                className={linkStyles}
                                href={`https://www.teamviewer.com/${languageCode}/eula/`}
                                target="_blank"
                                disabled={isLoading}
                              />
                            ),
                            link2: (
                              <LinkWithRedirect
                                className={linkStyles}
                                href={`https://www.teamviewer.com/${languageCode}/eula/#dpa`}
                                target="_blank"
                                disabled={isLoading}
                              />
                            ),
                            link3: (
                              <LinkWithRedirect
                                className={linkStyles}
                                href="https://www.teamviewer.com/link/?url=180010"
                                target="_blank"
                                disabled={isLoading}
                              />
                            ),
                          }}
                          shouldUnescape
                          tOptions={{ interpolation: { escapeValue: true } }}
                        />
                      </span>
                    )}
                    ariaLabel={t('checkboxAriaLabel')}
                    tabIndex={3}
                    data-testid="registration-password-tos-checkbox"
                  />
                </Stack>
                {countryCode === 'CN' && (
                  <Stack className={dataProcessingAgreementStyles}>
                    <Trans
                      ns="common"
                      i18nKey="dataProcessingAgreement"
                      components={{
                        link1: (
                          <LinkWithRedirect
                            className={linkStyles}
                            href={`https://www.teamviewer.com/${languageCode}/eula/#dpa`}
                            target="_blank"
                            disabled={isLoading}
                          />
                        ),
                        link2: (
                          <LinkWithRedirect
                            className={linkStyles}
                            href="https://www.teamviewer.com/link/?url=601593"
                            target="_blank"
                            disabled={isLoading}
                          />
                        ),
                      }}
                      shouldUnescape
                      tOptions={{ interpolation: { escapeValue: true } }}
                    />
                  </Stack>
                )}
                <SubmitButton
                  isValid={isValid}
                  isLoading={isLoading || !recaptchaLoaded}
                  isCheckmarkVisible={isAccountCreated}
                  className={signInButtonStyles}
                  data-testid="registration-password-submit-btn"
                  label={isAccountCreated ? t('accountCreated') : t('createAccount')}
                  tabIndex={5}
                />
              </Stack.Item>
              <InfoIconWithTextAndTooltip
                showTooltip={false}
                tooltipDirection={DirectionalHint.rightCenter}
                tooltipContent={t('newsletterTooltip')}
                content={
                  <Trans
                    ns="createAccount"
                    i18nKey="newsletterSubscriptionInfo"
                    components={{
                      link1: (
                        <LinkWithRedirect
                          className={linkStyles}
                          href="https://www.teamviewer.com/link/?url=180010"
                          target="_blank"
                          disabled={isLoading}
                        />
                      ),
                    }}
                    shouldUnescape
                    tOptions={{ interpolation: { escapeValue: true } }}
                  />
                }
              />
              <Stack>
                <Stack.Item className={signInStyles}>
                  {splitAndStyleString(
                    t('alreadyHaveAnAccount', {
                      link: `<>${t('signIn')}</>`,
                    }),
                    /<>|<\/>/,
                    linkStyles,
                    '/',
                    6,
                    '_self',
                    'registration-password-already-have-account',
                    isLoading,
                  )}
                </Stack.Item>
              </Stack>
            </Stack>
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default RegisterAccountPassword;
