import { Stack } from '@teamviewer/ui-library';

import { IconSize } from './InfoContainer.model';

import {
  emailInfoContainerStyles,
  emailInfoContainerWrapper,
  iconStyles,
  infoContainerStyles,
  infoContainerTokens,
} from './InfoContainer.styles';

interface IInfoContainer {
  icon: string;
  iconAccessibility: string;
  iconSize?: IconSize;
  text: JSX.Element | string;
  vertical?: boolean;
  textWrapStyles?: string;
}

const InfoContainer = ({
  icon,
  iconAccessibility,
  iconSize = IconSize.small,
  text,
  vertical = false,
  textWrapStyles,
  ...infoContainerProps
}: IInfoContainer) => (
  <Stack
    data-testid="info-container"
    horizontal={!vertical}
    horizontalAlign={vertical ? 'center' : 'baseline'}
    styles={infoContainerStyles}
    tokens={infoContainerTokens}
    {...infoContainerProps}
  >
    <img data-testid="info-container-img" style={iconStyles(iconSize)} src={icon} alt={iconAccessibility} />
    <Stack styles={emailInfoContainerWrapper}>
      <Stack data-testid="info-container-info" className={textWrapStyles} styles={emailInfoContainerStyles}>
        {text}
      </Stack>
    </Stack>
  </Stack>
);

export default InfoContainer;
