import { Trans, useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { FullPageLayout, LinkWithRedirect } from 'components';

import { useStyles } from './OAuthError.styles';

const OAuthError = () => {
  const { t } = useTranslation(['oautherror']);
  const [searchParams] = useSearchParams();
  const error = searchParams.get('error');
  const errorCode = searchParams.get('error_code');

  const { containerStyles, errorStyle, suggestionContainerStyles, suggestionTextStyle, suggestionTitleStyle } =
    useStyles();

  return (
    <FullPageLayout data-testid="oauth-error-page">
      <Stack className={containerStyles}>
        <img src="/assets/laptop_frontal_no_connection.svg" alt="Expired" data-testid="oauth-error-image" />
        <FullPageLayout.Title data-testid="oauth-error-title">{t('title')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="oauth-error-description">
          {t('description')}
        </FullPageLayout.Description>
        {error && (
          <TextSecondary variant="mediumPlus" className={errorStyle} data-testid="oauth-error-error">
            {t('error', { error })}
          </TextSecondary>
        )}
        {errorCode && (
          <TextSecondary variant="small" className={errorStyle} data-testid="oauth-error-error-code">
            {t('errorCode', { errorCode })}
          </TextSecondary>
        )}
        <Stack className={suggestionContainerStyles} data-testid="oauth-error-suggestion-container">
          <TextPrimary variant="mediumPlus" className={suggestionTitleStyle} data-testid="oauth-error-suggestion-title">
            {t('suggestionTitle')}
          </TextPrimary>
          <TextSecondary
            variant="mediumPlus"
            className={suggestionTextStyle}
            data-testid="oauth-error-apps-and-tokens-suggestion"
          >
            <Trans
              ns="oautherror"
              i18nKey="appsAndTokensSuggestion"
              components={{
                link1: <LinkWithRedirect href="https://www.teamviewer.com/link/?url=492375" target="_blank" />,
              }}
              shouldUnescape
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          </TextSecondary>
          <TextSecondary
            variant="mediumPlus"
            className={suggestionTextStyle}
            data-testid="oauth-error-contact-us-suggestion"
          >
            <Trans
              ns="oautherror"
              i18nKey="contactUsSuggestion"
              components={{
                link2: <LinkWithRedirect href="https://www.teamviewer.com/link/?url=861071" target="_blank" />,
              }}
              shouldUnescape
              tOptions={{ interpolation: { escapeValue: true } }}
            />
          </TextSecondary>
        </Stack>
      </Stack>
    </FullPageLayout>
  );
};

export default OAuthError;
