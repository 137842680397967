import type { IStackItemStyles, IStackStyles } from '@teamviewer/ui-library';
import { themeSpacings } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const teamViewerLogoWrapperStyles: Partial<IStackStyles> = {
  root: {
    width: '100%',
    paddingLeft: '56px',
    paddingTop: themeSpacings.xl,
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      justifyContent: 'center',
      paddingLeft: '0',
    },
  },
};

export const teamViewerImageLogoStyles: Partial<IStackItemStyles> = {
  root: {
    paddingRight: themeSpacings.s,
    cursor: 'pointer',
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      paddingRight: '0',
    },
  },
};
