export enum OAuthGrantAccessPermissions {
  AccountPermissions = 'AccountPermissions',
  UserPermissions = 'UserPermissions',
  SessionPermissions = 'SessionPermissions',
  GroupsPermissions = 'GroupsPermissions',
  ConnectionsPermissions = 'ConnectionsPermissions',
  MeetingPermissions = 'MeetingPermissions',
  ContactListPermissions = 'ContactListPermissions',
  ChatApiPermissions = 'ChatApiPermissions',
  TeamViewerPolicyPermissions = 'TeamViewerPolicyPermissions',
  RemoteAccessPermissions = 'RemoteAccessPermissions',
  VendorManagementPermissions = 'VendorManagementPermissions',
  TenantManagementPermissions = 'TenantManagementPermissions',
  OemConnectionsPermissions = 'OemConnectionsPermissions',
  OemUsagePermissions = 'OemUsagePermissions',
  CustomModulePermissions = 'CustomModulePermissions',
  ClientWebApiPermissions = 'ClientWebApiPermissions',
  SsoManageDomainsPermissions = 'SsoManageDomainsPermissions',
  WebMonitoringPermissions = 'WebMonitoringPermissions',
  MonitoringPermissions = 'MonitoringPermissions',
  ReachLiveNotificationPermissions = 'ReachLiveNotificationPermissions',
  UserGroupsPermissions = 'UserGroupsPermissions',
}

export type OAuthGrantAccessPermissionsType = keyof typeof OAuthGrantAccessPermissions;
