import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { IResetPasswordState } from 'models';

import { GetResetPasswordDetailsErrorCodes, ResetPasswordStatus } from 'models';
import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import {
  checkAccountRecoveryStatusAction,
  resetPasswordAction,
  resetPasswordTfaAction,
  sendForgotPasswordEmailAction,
} from './reset-password.async-action';

const initialState: IResetPasswordState = {
  email: '',
  status: ResetPasswordStatus.Verifying,
  isRecoveryCodeRequired: false,
  error: {
    isError: false,
  },
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    setEmail(state, action: { payload: string; type: string }) {
      state.email = action.payload;
    },
    setError(state, action: { payload: IResetPasswordState['error']; type: string }) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkAccountRecoveryStatusAction.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.isRecoveryCodeRequired = action.payload.isRecoveryCodeRequired;
      })
      .addMatcher(
        isAnyOf(
          sendForgotPasswordEmailAction.fulfilled,
          resetPasswordAction.fulfilled,
          resetPasswordTfaAction.fulfilled,
        ),
        (state, action) => {
          state.status = action.payload?.status;
        },
      )
      .addMatcher(
        isAnyOf(sendForgotPasswordEmailAction.pending, resetPasswordAction.pending, resetPasswordTfaAction.pending),
        (state, _action) => {
          state.status = ResetPasswordStatus.InProgress;
        },
      )
      .addMatcher(
        isAnyOf(
          sendForgotPasswordEmailAction.rejected,
          checkAccountRecoveryStatusAction.rejected,
          resetPasswordAction.rejected,
          resetPasswordTfaAction.rejected,
        ),
        (state, action) => {
          const payload: any = action.payload;

          state.status = payload.status || ResetPasswordStatus.InvalidToken;
          state.error = {
            isError: true,
            messageCreator: payload?.messageCreator ?? (() => ''),
            responseCodeType: payload?.responseCodeType,
            responseCode: payload?.responseCode,
            errorCode: payload?.errorCode,
          };

          switch (payload.responseCode as GetResetPasswordDetailsErrorCodes) {
            case GetResetPasswordDetailsErrorCodes.TFACodeInvalid:
              state.error.embeddedStringId = 'invalidSecurityCode';
              break;
          }

          state.error.messageCreator = generateStringIdForErrorMessage({
            responseCodeType: state.error.responseCodeType,
            responseCode: state.error.responseCode,
            embeddedStringId: state.error.embeddedStringId ?? '',
          });
        },
      );
  },
});

export const resetPasswordActions = resetPasswordSlice.actions;
export const resetPasswordReducer = resetPasswordSlice.reducer;
