import { useEffect, useState } from 'react';

import { useInitialServiceConfig } from 'config/useInitialServiceConfig';

export function useScript(src: string, size: 'invisible' | 'normal' = 'invisible') {
  const { requireCaptcha } = useInitialServiceConfig();

  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(() => {
    if (size === 'invisible' && !requireCaptcha) {
      return;
    }

    if (!src) {
      setStatus('idle');
      return;
    }

    let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement;
    if (!script) {
      script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.defer = true;
      script.setAttribute('data-status', 'loading');
      document.body.appendChild(script);

      const setAttributeFromEvent = (event: any) => {
        script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
      };

      script.addEventListener('load', setAttributeFromEvent);
      script.addEventListener('error', setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute('data-status') ?? 'idle');
    }

    const setStateFromEvent = (event: any) => {
      setStatus(event.type === 'load' ? 'ready' : 'error');
    };

    script.addEventListener('load', setStateFromEvent);
    script.addEventListener('error', setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent);
        script.removeEventListener('error', setStateFromEvent);
      }
    };
  }, [src, requireCaptcha, size]);

  return status;
}
