import { makeStyles, spacing } from '@teamviewer/ui-library';

export const useIconLinkStyles = makeStyles(
  ({ tokens }) =>
    ({
      iconStyles: {
        margin: 'auto',
        fontSize: tokens.typography.fontSizes.size12,
        paddingRight: spacing(4),
      },
    }) as const,
);
