import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { IconLink } from 'components';
import { TfaFor } from 'models';
import type { ILocationState } from '../TwoFactor';

import { useStyles } from 'pages/TwoFactor/TwoFactorHeader/TwoFactorHeader.styles';

export const TwoFactorHeader = () => {
  const { t } = useTranslation('twofactor');
  const state = useLocation().state as ILocationState;

  const { twoFactorSubtitleStyles, twoFactorTitleStyles } = useStyles();

  return (
    <Stack>
      <IconLink iconName="Back" as="LinkWithRedirect" link="/" tabIndex={1}>
        <TextPrimary variant="xxLarge" nowrap block data-testid="signin-tfa-title" className={twoFactorTitleStyles}>
          {t('title', {
            for: state.tfaFor === TfaFor.ResetPassword ? t('resetPassword') : t('signIn'),
          })}
        </TextPrimary>
      </IconLink>

      <TextSecondary
        variant="medium"
        className={twoFactorSubtitleStyles}
        nowrap
        block
        data-testid="signin-tfa-subtitle"
      >
        {t('subtitle')}
      </TextSecondary>
    </Stack>
  );
};
