import type { IIconStyles, IStackStyles } from '@teamviewer/ui-library';
import { themeColors, themeSpacings, themeTypography } from '@teamviewer/ui-library';

export const newsletterInformationStackStyles: Partial<IStackStyles> = {
  root: {
    paddingTop: themeSpacings.l,
    fontSize: themeTypography.fontSizes.size28,
    fontWeight: themeTypography.fontWeights.regular,
  },
};

export const newsletterInformationItemStyles: Partial<IStackStyles> = {
  root: {
    paddingLeft: themeSpacings.s,
    fontSize: themeTypography.fontSizes.size12,
    fontWeight: themeTypography.fontWeights.regular,
    flexShrink: 1,
  },
};

export const infoIconHoveredStyles: IIconStyles = {
  root: {
    color: themeColors.type.typeBluePrimary,
    fill: themeColors.type.typeBluePrimary,
  },
};
