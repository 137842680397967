import { useTranslation } from 'react-i18next';
import { mergeClasses, Stack, TextButton } from '@teamviewer/ui-library';

import FullPageLayout from 'components/FullPageLayout/FullPageLayout';
import { useLinkStyles } from 'utils/commonStyles';

import { useStyles } from '../Verified/AccountVerified.styles';

const AccountVerificationFailed = () => {
  const { t } = useTranslation('verifyaccount');

  const { linkStyles } = useLinkStyles();
  const { containerStyles, optionalTextLinkStyles } = useStyles();

  return (
    <FullPageLayout data-testid="verify-account-failed-container">
      <Stack className={containerStyles}>
        <img src="/assets/expired.svg" alt="Invalid" data-testid="verify-account-failed-image" />
        <FullPageLayout.Title data-testid="verify-account-failed-title">{t('linkInvalid')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="verify-account-failed-subtitle">
          {t('invalidDescription')}
        </FullPageLayout.Description>
        <FullPageLayout.OptionalText data-testid="verify-account-failed-trouble-text">
          {t('troubleVerifyingAccount')}
          <TextButton
            className={mergeClasses(linkStyles, optionalTextLinkStyles)}
            tabIndex={2}
            href={t('contactUsHref', { ns: 'common' })}
            data-testid="verify-account-failed-contact-us-link"
          >
            {t('contactUs', { ns: 'common' })}
          </TextButton>
        </FullPageLayout.OptionalText>
      </Stack>
    </FullPageLayout>
  );
};

export default AccountVerificationFailed;
