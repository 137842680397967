export type SocialAccountLoginIssuer = 'None' | 'Google' | 'Microsoft' | 'Apple';

export const SocialAccountLoginIssuer = {
  fromNumber(socialAccountLoginIssuer: unknown): SocialAccountLoginIssuer {
    if (typeof socialAccountLoginIssuer === 'number') {
      switch (socialAccountLoginIssuer) {
        case 1:
          return 'Google';
        case 2:
          return 'Microsoft';
        case 3:
          return 'Apple';
      }
    }
    return 'None';
  },
} as const;
