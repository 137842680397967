import type { Translatable } from 'utils/translation';

export enum CaptchaResponseCode {
  InvalidCaptchaResponseToken = 1,
}

export enum MasterResponseCode {
  AlreadyValidatedAccount = 35,
}

export type BackendError = NoBackendError | HasBackendError;
export const NoBackendError = { isError: false as const };
export type NoBackendError = typeof NoBackendError;

export type HasBackendError = {
  isError: true;
  /**
   * construct a message for the user, possibly translating the text if it is required
   */
  messageCreator: Translatable;
  /**
   * corresponds to the `t` JSON property of the backend response
   */
  responseCodeType?: number;
  /**
   * corresponds to the `e` JSON property of the backend response
   */
  responseCode?: number;
  /**
   * corresponds to the `ec` JSON property of the backend response
   */
  errorCode?: string;
  /**
   * a string ID that will use the `error` namespace for translation
   */
  embeddedStringId?: string;
};
