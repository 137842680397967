import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type VerifyAccountStatus = 'success' | 'expired' | 'used' | 'verifying' | 'failed';

const initialState: { status: VerifyAccountStatus } = {
  status: 'verifying',
};

const verifyAccountSlice = createSlice({
  name: 'verifyAccountStatus',
  initialState,
  reducers: {
    fetch(state, _action: PayloadAction<string>) {
      state.status = 'verifying';
    },
    update(state, action: PayloadAction<VerifyAccountStatus>) {
      state.status = action.payload;
    },
    failed(state) {
      state.status = 'failed';
    },
  },
});

export const verifyAccountActions = verifyAccountSlice.actions;
export const verifyAccountReducer = verifyAccountSlice.reducer;
