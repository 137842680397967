import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@teamviewer/ui-library';

import { FullPageLayout } from 'components';
import { useAppDispatch } from 'hooks';
import { verifyAccountActions } from 'store';

import { useStyles } from './AccountVerified.styles';

const AccountVerified = () => {
  const { t } = useTranslation(['verifyaccount', 'login']);
  const dispatch = useAppDispatch();

  const { containerStyles } = useStyles();

  useEffect(() => {
    dispatch(verifyAccountActions.fetch('token'));
  }, [dispatch]);

  return (
    <FullPageLayout data-testid="account-verified-container">
      <Stack className={containerStyles}>
        <img src="/assets/success.svg" alt={t('accountVerified')} data-testid="account-verified-image" />
        <FullPageLayout.Title data-testid="account-verified-title">{t('accountVerified')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="account-verified-subtitle">
          {t('accountVerifiedDescription')}
          <br />
          {t('accountVerifiedSignInAgain')}
        </FullPageLayout.Description>
      </Stack>
    </FullPageLayout>
  );
};

export default AccountVerified;
