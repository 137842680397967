import { useMemo } from 'react';
import { createTheme, themeColors, ThemeProvider, useTeamViewerFuiThemeProperties } from '@teamviewer/ui-library';

interface ThemeProps {
  children?: React.ReactNode;
}

export const themeBreakpoints = {
  mobile: '576',
  tablet: '768',
  laptop: '960',
  desktop: '1200',
};

export const ThemeProviderFui: React.FC<ThemeProps> = ({ children }) => {
  const themeProps = useTeamViewerFuiThemeProperties();
  const theme = useMemo(
    () =>
      createTheme({
        ...themeProps,
        semanticColors: {
          ...themeProps.semanticColors,
          bodyBackground: themeColors.greys.grey10,
          bodyFrameBackground: themeColors.greys.grey10,
          bodyFrameDivider: themeColors.greys.grey10,
          bodyStandoutBackground: themeColors.greys.grey10,
        },
      }),
    [themeProps],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
