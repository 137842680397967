import { Trans, useTranslation } from 'react-i18next';
import { Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';
import { ClientType } from 'models';
import { splitAndStyleString } from 'utils/stringUtils';
import { BannerColor } from './Banner.model';

import {
  bannerImgStyle,
  bannerSubtitleStyles,
  bannerTitleStyles,
  bannerWrapperStyles,
  logoImgStyle,
  useTextStyles,
} from './Banner.styles';

interface IBanner {
  img: string;
  imgAccessibility: string;
  title: JSX.Element | string;
  subtitle: JSX.Element | string;
  bannerColor?: BannerColor;
}

export const BannerJustAStepAway = () => {
  const { t } = useTranslation('banner');

  const { textUnderlineStyles } = useTextStyles();
  return (
    <Banner
      img="/assets/connecting_dots.svg"
      imgAccessibility={t('connectingDotsAccessibility')}
      title={
        <Trans
          ns="banner"
          i18nKey="remoteSupportLikeNever"
          shouldUnescape
          tOptions={{ interpolation: { escapeValue: true } }}
        />
      }
      subtitle={splitAndStyleString(
        t('tryNewExperience', { quick: `<b>${t('quick')}</b>` }),
        /<b>|<\/b>/,
        textUnderlineStyles,
      )}
      bannerColor={BannerColor.secondary}
    />
  );
};

export const BannerCreateAccount = () => {
  const { t } = useTranslation('banner');
  const { textUnderlineStyles } = useTextStyles();

  return (
    <Banner
      img="/assets/tv_galaxy_dots.svg"
      imgAccessibility={t('tvGalaxyDotsAccessibility')}
      title={
        <Trans
          ns="banner"
          i18nKey="startYourJourney"
          shouldUnescape
          tOptions={{ interpolation: { escapeValue: true } }}
        />
      }
      subtitle={
        <>
          {splitAndStyleString(
            t('thereIsAlwaysAConnection', { always: `<b>${t('always')}</b>` }),
            /<b>|<\/b>/,
            textUnderlineStyles,
          )}
          <Trans
            ns="banner"
            i18nKey="teamviewerIsHere"
            shouldUnescape
            tOptions={{ interpolation: { escapeValue: true } }}
          />
        </>
      }
      bannerColor={BannerColor.darker}
    />
  );
};

export const BannerSocialLoginRegister = ({ service }: { service: string }) => {
  const { t } = useTranslation('banner');
  return (
    <Banner
      img="/assets/locker_dots.svg"
      imgAccessibility={t('lockerDotsAccessibility')}
      title={
        <Trans
          ns="banner"
          i18nKey="securityAtTheForefront"
          shouldUnescape
          tOptions={{ interpolation: { escapeValue: true } }}
        />
      }
      subtitle={t('yourDataIsAlwaysEncrypted', { service })}
      bannerColor={BannerColor.darkerAlt}
    />
  );
};

export const BannerSecurity = () => {
  const { t } = useTranslation('banner');
  const { textUnderlineStyles } = useTextStyles();

  return (
    <Banner
      img="/assets/locker_dots.svg"
      imgAccessibility={t('lockerDotsAccessibility')}
      title={
        <Trans
          ns="banner"
          i18nKey="securityAtTheForefront"
          shouldUnescape
          tOptions={{ interpolation: { escapeValue: true } }}
        />
      }
      subtitle={splitAndStyleString(
        t('weTickAllTheBoxes', { security: `<b>${t('security')}</b>` }),
        /<b>|<\/b>/,
        textUnderlineStyles,
      )}
      bannerColor={BannerColor.darkerAlt}
    />
  );
};

const Banner = ({ img, imgAccessibility, title, subtitle, bannerColor = BannerColor.secondary }: IBanner) => {
  const { screenSize } = useResponsive();
  const isNativeClient = useInitialServiceConfig().clientType === ClientType.NativeClient;
  const showBannerLogo = isNativeClient && screenSize >= ScreenSize.Laptop;
  const showBannerImage = screenSize > ScreenSize.Laptop;

  return (
    <Stack styles={bannerWrapperStyles(bannerColor)} horizontalAlign="center" data-testid="banner-container">
      {showBannerLogo && (
        <img src="/assets/tv-logo-white.svg" alt="TeamViewer Logo" style={logoImgStyle} data-testid="tv-logo-banner" />
      )}
      {showBannerImage && <img src={img} alt={imgAccessibility} style={bannerImgStyle} data-testid="banner-image" />}
      <TextPrimary styles={bannerTitleStyles} block data-testid="banner-title">
        {title}
      </TextPrimary>
      <TextSecondary styles={bannerSubtitleStyles} block data-testid="banner-subtitle">
        {subtitle}
      </TextSecondary>
    </Stack>
  );
};

Banner.JustAStepAway = BannerJustAStepAway;
Banner.CreateAccount = BannerCreateAccount;
Banner.SocialLoginRegister = BannerSocialLoginRegister;
Banner.Security = BannerSecurity;
export default Banner;
