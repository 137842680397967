import type { ValidateProp, ValidationFunc } from './validation-rules';

/**
 * Decorates the `validate` property of a validation rules object (`IValidationRules`)
 * @param val to decorate `validate` property of validation rules object
 * @param cascadingFunc validation function to be added
 * @param propName name of the property for the case of a validate being an object consisting of validation functions
 * @returns new `validate` property with the additional validation check
 */
export function cascadeValidationFunc(
  val: ValidateProp,
  cascadingFunc: ValidationFunc,
  propName = 'cascadedVal',
): Exclude<ValidateProp, undefined> {
  if (val === undefined) {
    return cascadingFunc;
  } else if (typeof val === 'function') {
    return (inputToValidate: string) => {
      const cascade = <T>(result: T) => {
        if (result === true) {
          return cascadingFunc(inputToValidate);
        }
        return result;
      };

      const origFuncRes = val(inputToValidate);

      if (origFuncRes instanceof Promise) {
        return origFuncRes.then(cascade);
      } else {
        return cascade(origFuncRes);
      }
    };
  } else if (typeof val === 'object') {
    if (propName in val) {
      // eslint-disable-next-line no-console -- not thought for replacing, replacing is unintentional
      console.warn(`property ${propName} already exists in validation object - overwriting`);
    }
    return {
      ...val,
      [propName]: cascadingFunc,
    };
  } else {
    // all the possible cases should have been handled above
    // this should be unreachable
    ((arg: never) => {
      // eslint-disable-next-line no-console --  useful to see the value at runtime
      console.error('Impossible value', arg);
      throw new Error('Impossible path');
    })(val);
  }
}
