import { Trans, useTranslation } from 'react-i18next';
import { Stack } from '@teamviewer/ui-library';

import { FullPageLayout, SubmitButton } from 'components';
import { useNavigateWithRedirect } from 'hooks';

import { useStyles } from 'pages/TwoFactorDeactivated/TwoFactorDeactivated.styles';

const TwoFactorDeactivated = () => {
  const { t } = useTranslation('deactivateTwoFactor');
  const navigate = useNavigateWithRedirect();

  const { buttonContainerStyles, buttonStyles, containerStyles } = useStyles();

  const handleSignIn = () => {
    navigate(
      {
        pathname: '/',
      },
      {
        replace: true,
      },
    );
  };

  return (
    <FullPageLayout>
      <Stack className={containerStyles} data-testid="tfa-deactivated-container">
        <img src="/assets/no_secure.svg" alt={t('notSecureAccessibility')} data-testid="tfa-deactivated-image" />
        <FullPageLayout.Title data-testid="tfa-deactivated-title">{t('twoFactorDeactivated')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="tfa-deactivated-subtitle">
          <Trans
            t={t}
            i18nKey="recommendActivateTwoFactorAndRelogin"
            shouldUnescape
            tOptions={{ interpolation: { escapeValue: true } }}
          />
        </FullPageLayout.Description>
        <Stack.Item className={buttonContainerStyles}>
          <SubmitButton
            isValid
            isLoading={false}
            isCheckmarkVisible={false}
            label={t('signIn')}
            className={buttonStyles}
            onClick={handleSignIn}
            tabIndex={1}
            data-testid="tfa-deactivated-signin-btn"
          />
        </Stack.Item>
      </Stack>
    </FullPageLayout>
  );
};

export default TwoFactorDeactivated;
