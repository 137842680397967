import { useCallback, useEffect, useState } from 'react';
import i18n from 'i18n';

import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useScript } from './useScript';

const currentLang = i18n.language;
export const RECAPTCHA_SCRIPT_SRC_URL = `https://www.recaptcha.net/recaptcha/api.js?hl=${currentLang}&render=explicit`;

interface IUseRecaptcha {
  containerId: string;
  sitekey: string;
  size: 'invisible' | 'normal';
  successCallback: (response: string) => void;
  expiredCallback?: () => void;
  errorCallback?: () => void;
  theme?: 'light' | 'dark';
  badge?: 'bottomright' | 'bottomleft' | 'inline';
}

export const useRecaptcha = ({
  containerId,
  sitekey,
  size,
  successCallback,
  expiredCallback,
  errorCallback,
  theme = 'light',
  badge = 'bottomright',
}: IUseRecaptcha) => {
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const [recaptchaWidget, setRecaptchaWidget] = useState<number | null>(null);
  const { isAlibabaCaptcha } = useInitialServiceConfig();

  useScript(isAlibabaCaptcha ? '' : RECAPTCHA_SCRIPT_SRC_URL, size);

  useEffect(() => {
    if (!isAlibabaCaptcha && window.grecaptcha) {
      window.grecaptcha.ready(() => {
        if (recaptchaWidget === null) {
          const widget = window.grecaptcha.render(containerId, {
            sitekey,
            size,
            theme,
            badge,
            callback: successCallback,
            'expired-callback': expiredCallback,
            'error-callback': errorCallback,
          });

          setRecaptchaWidget(widget);
          setRecaptchaLoaded(true);
        }
      });
    }
  }, [
    badge,
    containerId,
    errorCallback,
    expiredCallback,
    recaptchaWidget,
    sitekey,
    size,
    isAlibabaCaptcha,
    successCallback,
    theme,
  ]);

  const execute = useCallback(() => {
    if (recaptchaWidget) {
      window.grecaptcha.execute(recaptchaWidget);
    }
  }, [recaptchaWidget]);

  const reset = useCallback(() => {
    if (recaptchaWidget) {
      window.grecaptcha.reset(recaptchaWidget);
    }
  }, [recaptchaWidget]);

  return {
    execute,
    recaptchaLoaded,
    recaptchaWidget,
    reset,
  };
};
