import { useAppSelector } from 'hooks';
import { ResetPasswordStatus } from 'models';
import ForgotEncryptionKeyForm from './ForgotEncryptionKeyForm/ForgotEncryptionKeyForm';
import ForgotEncryptionKeyEmailSent from './ForgotPasswordEmail/ForgotEncryptionKeyEmailSent';

const ForgotEncryptionKeyWrapper = () => {
  const { status } = useAppSelector((state) => state.resetPasswod);

  return status === ResetPasswordStatus.EmailSent ? <ForgotEncryptionKeyEmailSent /> : <ForgotEncryptionKeyForm />;
};

export default ForgotEncryptionKeyWrapper;
