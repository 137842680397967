import { useCallback } from 'react';

import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { trackActionEvent, trackViewEvent } from 'store/tracking';
import { useAppDispatch } from './useAppDispatch';

export function useTracking() {
  const dispatch = useAppDispatch();
  const { clientType } = useInitialServiceConfig();

  const trackAction = useCallback(
    (category: string, action: string, value?: string) => {
      dispatch(
        trackActionEvent({
          dataSource: clientType,
          category,
          action,
          value,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientType],
  );

  const trackView = useCallback(
    (screenname: string, userInteraction = true) => {
      dispatch(
        trackViewEvent({
          dataSource: clientType,
          screenname,
          userInteraction,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientType],
  );

  return { trackAction, trackView };
}
