import type { IValidationRules as ValidationRules } from '@teamviewer/utils';
import type { TFunction } from 'i18next';

import { emailRegex, nameRegex } from 'utils/validationRegex';

export type ValidationFunc = (stringId: string) => true | string;

export const getEmailValidationRules = (t: TFunction): ValidationRules => ({
  maxLength: { value: 100, message: t('validation:emailMaxLength') },
  pattern: {
    value: emailRegex,
    message: t('validation:enterValidEmail'),
  },
  required: {
    value: true,
    message: t('validation:requiredEmail'),
  },
});

export const getPasswordValidationRules = (t: TFunction): ValidationRules => ({
  required: { value: true, message: t('validation:requiredPassword') },
});

export const getCheckBoxRequiredRules = (): ValidationRules => ({
  required: true,
});

export const getSamePasswordRules = (t: TFunction, newPassword: string): ValidationRules => ({
  validate: (value: string) => newPassword === value || t('validation:notSamePassword').toString(),
  required: {
    value: true,
    message: t('validation:requiredPassword'),
  },
});

export const getPartnerIdValidationRules = (): ValidationRules => ({
  required: true,
  minLength: 2,
});

export const getNameValidationRules = (t: TFunction): ValidationRules => ({
  minLength: { value: 2, message: t('validation:nameMinLength') },
  maxLength: { value: 50, message: t('validation:nameMaxLength') },
  pattern: {
    value: nameRegex,
    message: t('validation:enterValidName'),
  },
  required: {
    value: true,
    message: t('validation:requiredName'),
  },
});

export const getRecoveryCodeRules = (t: TFunction): ValidationRules => ({
  minLength: { value: 64, message: t('validation:validRecoveryCode') },
  maxLength: { value: 64, message: t('validation:validRecoveryCode') },
  required: {
    value: true,
    message: t('validation:requiredRecoveryCode'),
  },
});

export const getSecurityKeyRules = (t: TFunction): ValidationRules => ({
  required: {
    value: true,
    message: t('validation:requiredSecurityKey'),
  },
});
