import { useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { themeBreakpoints } from 'components/Theme';

const useResponsiveFunc = () => {
  const [isMobile, setIsMobile] = useState(
    useMediaQuery({ query: `(max-width: ${themeBreakpoints.mobile}px)` }, undefined, (matches: boolean) => {
      setIsMobile(matches);
    }),
  );

  const [isTablet, setIsTablet] = useState(
    useMediaQuery({ query: `(max-width: ${themeBreakpoints.tablet}px)` }, undefined, (matches: boolean) => {
      setIsTablet(matches);
    }),
  );

  const [isLaptop, setIsLaptop] = useState(
    useMediaQuery({ query: `(max-width: ${themeBreakpoints.laptop}px)` }, undefined, (matches: boolean) => {
      setIsLaptop(matches);
    }),
  );

  const screenSize = useMemo(
    () => mapToScreenDimension({ isMobile, isTablet, isLaptop }),
    [isMobile, isTablet, isLaptop],
  );

  return {
    screenSize,
    /**
     * @deprecated use `screenSize` instead
     */
    isMobile,
    /**
     * @deprecated use `screenSize` instead
     */
    isTablet,
    /**
     * @deprecated use `screenSize` instead
     */
    isLaptop,
    /**
     * @deprecated use `screenSize` instead
     */
    isDesktop: true,
  };
};

export function mapToScreenDimension({
  isMobile,
  isTablet,
  isLaptop,
}: {
  isMobile: boolean;
  isTablet: boolean;
  isLaptop: boolean;
}) {
  if (isMobile) {
    return ScreenSize.Mobile;
  } else if (isTablet) {
    return ScreenSize.Tablet;
  } else if (isLaptop) {
    return ScreenSize.Laptop;
  } else {
    return ScreenSize.Desktop;
  }
}

export const ScreenSize = {
  Mobile: 0,
  Tablet: 1,
  Laptop: 2,
  Desktop: 3,
} as const;

export type ScreenSize = (typeof ScreenSize)[keyof typeof ScreenSize];

export const useResponsive = Object.assign(useResponsiveFunc, { ScreenSize, mapToScreenDimension });
