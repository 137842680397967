import { useState } from 'react';

const useTimer = (callback: () => void, delay: number) => {
  const [remaining, setRemaining] = useState(delay);
  const [startTime, setStartTime] = useState(0);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const start = () => {
    const time = Date.now();
    setStartTime(time);
    setTimer(setTimeout(callback, delay));
  };

  const resume = () => {
    const time = Date.now();
    setStartTime(time);
    setTimer(setTimeout(callback, remaining));
  };

  const pause = () => {
    const time = Date.now();
    const newRemaining = remaining - time + startTime;
    setRemaining(newRemaining);
    clearTimeout(timer);
  };

  const clear = () => {
    clearTimeout(timer);
  };

  return { clear, pause, resume, start };
};

export default useTimer;
