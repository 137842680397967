import { useEffect, useState } from 'react';

import { useAppDispatch } from 'hooks';
import { LoginMethod } from 'models';
import { authActions } from 'store';
import EducationScreen from './EducationScreen';
import SocialLoginRegisterForm from './SocialLoginRegisterForm';

const SocialLoginRegister = () => {
  const [isCreateEncryptionPageOpen, setCreateEncrytpionPageOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authActions.setLoginMethod(LoginMethod.SocialLogin));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isCreateEncryptionPageOpen ? (
    <SocialLoginRegisterForm />
  ) : (
    <EducationScreen setOpen={setCreateEncrytpionPageOpen} />
  );
};

export default SocialLoginRegister;
