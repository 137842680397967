import { useContext } from 'react';

import { configContext } from 'config/config-manager';

export const useInitialServiceConfig = () => {
  const ctx = useContext(configContext);

  if (!ctx?.initialServiceConfig) {
    throw Error('Attempt to access initial service configuration before its provider has been initialized!');
  }

  return ctx.initialServiceConfig;
};
