import { makeStyles, spacing, themeBorderRadius, themeColors, themeSpacings } from '@teamviewer/ui-library';

import type { IDividerStyles } from 'components/Divider/Divider';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      subtitleStyles: {
        whiteSpace: 'normal',
        marginTop: themeSpacings.s,
      },
      formSectionStyles: {
        paddingTop: spacing(4),
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: spacing(9),
        margin: 0,
      },
      passwordRequirementsHeaderStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        whiteSpace: 'normal',
        color: themeColors.greys.black,
      },
      listIconStyles: {
        margin: 'auto',
        display: 'block',
      },
      tOSStyles: {
        marginTop: themeSpacings.l,
      },
      signInStyles: {
        marginTop: themeSpacings.l,
      },
      signInButtonStyles: {
        marginTop: themeSpacings.m,
      },
      dataProcessingAgreementStyles: {
        marginTop: themeSpacings.m,
        marginLeft: themeSpacings.l,
        display: 'block',
      },
      alreadyHaveAnAccountStyles: {
        marginTop: themeSpacings.l,
      },
      passwordRecomendationsContainerStyles: {
        padding: `${themeSpacings.m} ${themeSpacings.l} ${themeSpacings.l} ${themeSpacings.l}`,
        borderRadius: themeBorderRadius.l,
        backgroundColor: tokens.colors.type.typeWhite,
      },
    }) as const,
);

export const calloutWindowStyles = {
  root: {
    borderRadius: themeBorderRadius.l,
  },
  calloutMain: {
    borderRadius: themeBorderRadius.l,
  },
  beakCurtain: {
    borderRadius: themeBorderRadius.l,
  },
  beak: {
    marginLeft: '0.3em',
  },
};

export const dividerStyles: Partial<IDividerStyles> = {
  container: {
    root: {
      marginTop: themeSpacings.s,
    },
  },
};
