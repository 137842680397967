import type { IStackStyles } from '@fluentui/react';

import { themeBreakpoints } from 'components/Theme';

export const footerMarginStyles: IStackStyles = {
  root: {
    paddingBottom: '100px',
  },
};

export const pageContentStyles: IStackStyles = {
  root: {
    minHeight: '100vh',
  },
};

export const pageContentWrapperStyles: IStackStyles = {
  root: {
    minHeight: '100vh',
    position: 'relative',
  },
};

export const toastContainerStyles = {
  '&.Toastify__toast-container': {
    maxWidth: '448px',
    width: '100%',
    [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
      width: 'calc(100% - 8px)',
      padding: '16px',
    },
  },

  '&.Toastify__toast-container--top-right': {
    top: '42px',
    right: '4px',
  },

  '&.Toastify__toast-container--top-center': {
    top: '8px',
    left: '50%',
    transform: 'translate(-50%)',
  },

  '.Toastify__toast, .Toastify__toast-body, .Toastify__toast-body > div:last-child': {
    width: '100%',
  },

  '.Toastify__toast': {
    [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
      marginBottom: '4px',
    },
  },

  '.ms-Stack > :not(.ms-StackItem)': {
    flexShrink: 0,
  },
};
