import { Spinner, SpinnerSize } from '@teamviewer/ui-library';

import { useStyles } from './Loading.styles';

const Loading = () => {
  const { spinnerStyles } = useStyles();

  return <Spinner className={spinnerStyles} size={SpinnerSize.large} data-testid="loading-spinner" />;
};

export default Loading;
