import { BigInteger } from 'jsbn';

import { BigIntegerToUint8Array } from './utilities';

const parameters = {
  N: 'ac6bdb41324a9a9bf166de5e1389582faf72b6651987ee07fc3192943db56050a37329cbb4a099ed8193e0757767a13dd52312ab4b03310dcd7f48a9da04fd50e8083969edb767b0cf6095179a163ab3661a05fbd5faaae82918a9962f0b93b855f97993ec975eeaa80d740adbf4ff747359d041d5c33ea71d281e446b14773bca97b43a23fb801676bd207a436c6481f1d2b9078717461a5b9d32e688f87748544523b524b0d57d5ea77a2775d2ecfa032cfbdbf52fb3786160279004e57ae6af874e7303ce53299ccc041c7bc308d82a5698f3a8d0c38271ae35f8e9dbfbb694b5c803d89f7ae435de236d525f54759b65e372fcd68ef20fa7111f9e4aff73',
  g: '02',
  hash: 'SHA-512',
  saltSize: 8,
  privateKeySize: 20,
  clientProofSize: 32,
  loginTokenSize: 72, // 72 bytes so the lenght after encoding to base64 is 96.
  loginTokenSecretSize: 12, // 12 bytes so the lenght after encoding to base64 is 16.
};

export const N = new BigInteger(parameters.N, 16);
export const g = new BigInteger(parameters.g, 16);
export const hash = parameters.hash;
export const rawNSize = BigIntegerToUint8Array(N).length;
export const saltSize = parameters.saltSize;
export const privateKeySize = parameters.privateKeySize;
export const clientProofSize = parameters.clientProofSize;
export const loginTokenSize = parameters.loginTokenSize;
export const loginTokenSecretSize = parameters.loginTokenSecretSize;

export const ssoParams = {
  algorithm: 'AES-CBC',
  deriveKeyAlgorithm: 'PBKDF2',
  hash: 'SHA-1',
  size: {
    salt: 16,
    iv: 16,
    key: 256,
  },
  iterations: 1000,
};
