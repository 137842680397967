import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppWrapper from 'App';

import { initialize as initializeAnalytics } from 'modules/Analytics';
import { Loading } from 'pages';
import { store } from 'store';
import { registerCustomIcons } from 'utils/register-icons';
import reportWebVitals from './reportWebVitals';

import './index.css';

import 'utils/extensions';
import './i18n';

registerCustomIcons();

initializeAnalytics();

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
