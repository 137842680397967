import { useTranslation } from 'react-i18next';
import { Stack } from '@teamviewer/ui-library';

import { FullPageLayout } from 'components';
import { useNavigateWithRedirect } from 'hooks';
import { useStyles } from './NotAccepted.Styles';

const NotAccepted = () => {
  const { t } = useTranslation('notaccepted');
  const navigate = useNavigateWithRedirect();

  const { containerStyles } = useStyles();

  return (
    <FullPageLayout data-testid="not-accepted-container">
      <Stack className={containerStyles}>
        <img src="/assets/laptop_frontal_error.svg" alt="Expired" data-testid="not-accepted-image" />
        <FullPageLayout.Title data-testid="not-accepted-title">{t('title')}</FullPageLayout.Title>
        <FullPageLayout.Description data-testid="not-accepted-description">
          {t('explanation')}
        </FullPageLayout.Description>
        <FullPageLayout.Button
          onClick={() => {
            navigate('/');
          }}
          data-testid="not-accepted-button"
        >
          {t('tryagain')}
        </FullPageLayout.Button>
      </Stack>
    </FullPageLayout>
  );
};

export default NotAccepted;
