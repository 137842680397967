import { initReactI18next } from 'react-i18next';
import { supportedLanguages, teamViewerToI18nextLanguageCode, tvI18nextBackendOptions } from '@teamviewer/tv-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<typeof tvI18nextBackendOptions>({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    supportedLngs: Object.keys(supportedLanguages).map(teamViewerToI18nextLanguageCode),
    defaultNS: 'common',
    react: { useSuspense: true },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      lookupCookie: 'lang', // Also used by the backend and the cookie consent banner
      cookieMinutes: monthsInMinutes(12),
      cookieOptions: { path: '/', sameSite: 'lax' },
    },
    interpolation: {
      escapeValue: false,
    },
    // remove the following comment after upgrading i18next-browser-languagedetector and i18next-http-backend
    // @ts-expect-error
    backend: {
      ...tvI18nextBackendOptions,
    },
  });

function monthsInMinutes(months: number): number {
  return months * 30 * 24 * 60;
}

export default i18n;
