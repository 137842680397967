import { createSlice } from '@reduxjs/toolkit';
import type { BackendError, IRegisterAccountState } from 'models';

import { RegisterAccountStatus } from 'models';
import { generateStringIdForErrorMessage } from 'utils/ErrorPayloadHandling';
import { registerAccountAsyncAction } from './register-account.async-actions';

const initialState: IRegisterAccountState = {
  email: '',
  registerAccountStatus: RegisterAccountStatus.Initial,
  error: {
    isError: false,
  },
};

const registerAccountSlice = createSlice({
  name: 'registerAccount',
  initialState,
  reducers: {
    clear() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerAccountAsyncAction.pending, (state) => {
        state.registerAccountStatus = RegisterAccountStatus.InProgress;
      })
      .addCase(registerAccountAsyncAction.fulfilled, (state, action) => {
        const payload = action.payload;
        state.email = payload.email;
        state.registerAccountStatus = payload.registerAccountStatus;
      })
      .addCase(registerAccountAsyncAction.rejected, (state, action) => {
        const payload = action.payload;
        state.registerAccountStatus = payload?.registerAccountStatus ?? RegisterAccountStatus.Failed;

        const { responseCodeType, responseCode, errorCode, embeddedStringId } = (payload?.error ?? {}) as Extract<
          BackendError,
          { isError: true }
        >;
        state.error = {
          isError: true,
          errorCode: errorCode ?? '',
          responseCodeType: responseCodeType ?? 0,
          responseCode: responseCode ?? 0,
          embeddedStringId: embeddedStringId ?? '',
          messageCreator: generateStringIdForErrorMessage({
            responseCodeType,
            responseCode,
            embeddedStringId,
          }),
        };
      });
  },
});

export const registerAccountActions = registerAccountSlice.actions;
export const registerAccountReducer = registerAccountSlice.reducer;
