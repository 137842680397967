import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function usePasswordStrengthMessages() {
  const { t } = useTranslation();
  const passwordStrengthMessages = useMemo(
    () => ({
      weak: t('weakPassword'),
      medium: t('mediumPassword'),
      strong: t('strongPassword'),
      blocked: t('blockedPassword'),
    }),
    [t],
  );

  return passwordStrengthMessages;
}
