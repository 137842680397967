import { makeStyles } from '@teamviewer/ui-library';

export const useLinkStyles = makeStyles(
  ({ tokens }) =>
    ({
      linkStyles: {
        fontWeight: tokens.typography.fontWeights.bold,
        '&.is-disabled': {
          color: tokens.colors.type.typeDisabled,
        },
      },
    }) as const,
);
