import type { BackendError } from 'models';

export enum TrustDeviceStatus {
  Initial = 'Initial',
  InvalidRequest = 'InvalidRequest',
  Verifying = 'Verifying',
  Successed = 'Successed',
  Failed = 'Failed',
  Declined = 'Declined',
}

export interface ITrustDeviceState {
  status: TrustDeviceStatus;
  error: BackendError;
}
