import type { IStackStyles, ITextStyles } from '@teamviewer/ui-library';
import { Icon, Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { boxTextStyles, iconStyles, titleStyles } from './Box.styles';

export interface IDividerStyles {
  container?: IStackStyles;
  text?: ITextStyles;
}
interface DividerProps {
  title?: string;
  text: string[];
  marginClassName?: string;
}

const Box = ({ title, text, marginClassName }: DividerProps) => (
  <Stack className={marginClassName}>
    <TextPrimary styles={titleStyles}>{title}</TextPrimary>
    <Stack horizontal>
      {text[0] ? (
        <>
          <Stack.Item>
            <Icon iconName="CheckmarkIcon" styles={iconStyles} />
          </Stack.Item>
          <Stack.Item>
            <TextSecondary> </TextSecondary>
          </Stack.Item>
          <Stack.Item>
            <TextSecondary>{text[0]} </TextSecondary>
          </Stack.Item>
        </>
      ) : null}
    </Stack>
    <Stack horizontal>
      {text[1] ? (
        <>
          <Stack.Item>
            <Icon iconName="CheckmarkIcon" styles={iconStyles} />
          </Stack.Item>
          <Stack.Item>
            <TextSecondary> </TextSecondary>
          </Stack.Item>
          <Stack.Item>
            <TextSecondary styles={boxTextStyles}>{text[1]}</TextSecondary>
          </Stack.Item>
        </>
      ) : null}
    </Stack>
    <Stack horizontal>
      {text[2] ? (
        <>
          <Stack.Item>
            <Icon iconName="CheckmarkIcon" styles={iconStyles} />
          </Stack.Item>
          <Stack.Item>
            <TextSecondary> </TextSecondary>
          </Stack.Item>
          <Stack.Item>
            <TextSecondary styles={boxTextStyles}>{text[2]}</TextSecondary>
          </Stack.Item>
        </>
      ) : null}
    </Stack>
  </Stack>
);

export default Box;
