import type React from 'react';
import type { IStackStyles, IStackTokens } from '@teamviewer/ui-library';
import { spacing, themeBorderRadius, themeColors, themeSpacings } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';
import { IconSize } from './InfoContainer.model';

export const infoContainerStyles: IStackStyles = {
  root: {
    border: `1px solid ${themeColors.dividers.contentDivider}`,
    backgroundColor: themeColors.greys.grey10,
    borderRadius: themeBorderRadius.m,
    marginTop: themeSpacings.l,
    padding: themeSpacings.m,
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      width: '100%',
    },
  },
};

export const infoContainerTokens: IStackTokens = {
  childrenGap: 16,
};

export const iconStyles = (iconSize: IconSize): React.CSSProperties => ({ maxWidth: getIconSize(iconSize), flex: 1 });

export const emailInfoContainerWrapper = {
  root: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
  },
};

export const emailInfoContainerStyles: IStackStyles = {
  root: {
    display: 'table-cell',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    verticalAlign: 'middle',
    margin: 'auto',
    [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
      textAlign: 'center',
    },
  },
};

const getIconSize = (iconSize: IconSize) => {
  switch (iconSize) {
    case IconSize.small:
      return spacing(8);
    case IconSize.big:
      return spacing(16);
  }
};
