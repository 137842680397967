import { makeStyles } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      authCodeContainerStyles: {
        display: 'flex',
        gap: tokens.spacings.m,
        [`@media (max-width: ${themeBreakpoints.laptop}px)`]: {
          gap: tokens.spacings.s,
        },
      },
      authCodeInputStyles: {
        flex: '1',
        maxWidth: '48px',
        minWidth: '0',
        height: '56px',
        padding: 0,
        borderRadius: tokens.spacings.s,
        border: `1px solid ${tokens.colors.greys.grey60}`,
        fontSize: tokens.typography.fontSizes.size32,
        textAlign: 'center',
        color: tokens.colors.type.typePrimary,
      },
      authCodeErrorInputStyles: {
        borderColor: tokens.colors.type.typeRed,
        color: tokens.colors.type.typeRed,
      },
      twoFactorLoadingSectionStyles: {
        paddingTop: tokens.spacings.m,
      },
      twoFactorLoadingTextStyles: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        paddingLeft: tokens.spacings.s,
      },
      twoFactorTextStyles: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.regular,
        marginRight: tokens.spacings.xs,
      },
      twoFactorFormSectionStyles: {
        paddingTop: tokens.spacings.m,
      },
      twoFactorResendStyles: {
        paddingTop: tokens.spacings.l,
      },
    }) as const,
);
