import { useSearchParams } from 'react-router-dom';
import type { ITextButtonProps } from '@teamviewer/ui-library';
import { TextButton } from '@teamviewer/ui-library';

import { useConfigManager } from 'config/useConfigManager';

export interface ILinkWithRedirect extends ITextButtonProps {
  href: string;
}

const LinkWithRedirect = ({ href, ...linkProps }: ILinkWithRedirect) => {
  const [searchParams] = useSearchParams();
  const configManager = useConfigManager();
  const newURL = new URL(href, window.location.origin);
  const redirectURI = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const defaultSearchParams = new URLSearchParams();
  defaultSearchParams.append('redirect_uri', redirectURI);
  newURL.search = defaultSearchParams.merge(newURL.searchParams).toString();
  return <TextButton data-testid="link-with-redirect" {...linkProps} href={newURL.href} />;
};

export default LinkWithRedirect;
