import {
  CaptchaResponseCode,
  LoginResponseCode,
  MasterResponseCode,
  OAuthResponseCode,
  ResponseCodeType,
  SocialResponseCode,
} from 'models';
import { Translatable } from './translation';

type ErrorInfo = {
  responseCodeType: number | undefined;
  responseCode: number | undefined;
  embeddedStringId?: string;
};

const namespace = 'backendResponse';
export const defaultErrorMsgId = `${namespace}:defaultErrorMessage` as const;

export function generateStringIdForErrorMessage({
  responseCodeType,
  responseCode,
  embeddedStringId,
}: ErrorInfo): Translatable {
  // 1) was a string ID embedded on the frontend?
  if (embeddedStringId) {
    return Translatable.fromStringId(`error:${embeddedStringId}`);
  }
  // 2) try to map the string id from the backend response pair [responseCodeType, responseCode]
  if (typeof responseCodeType === 'number' && typeof responseCode === 'number') {
    const stringRepresentation = enumEntryToStringRepresentation(responseCodeType, responseCode);
    if (stringRepresentation) {
      return Translatable.fromStringId(`${namespace}:${decapitalize(stringRepresentation)}`);
    }
  }
  // 3) otherwise fall back to the most generic error message
  return Translatable.fromStringId(defaultErrorMsgId);
}

/**
 * Maps a response code type and response code pair to its string id for later generating the user representation
 * @param responseCodeType
 * @param responseCode
 * @returns either the string representation or undefined if the mapping is not possible
 */
function enumEntryToStringRepresentation(responseCodeType: ResponseCodeType, responseCode: number): string | undefined {
  switch (responseCodeType) {
    case ResponseCodeType.Login:
      return LoginResponseCode[responseCode];
    case ResponseCodeType.Social:
      return SocialResponseCode[responseCode];
    case ResponseCodeType.Captcha:
      return CaptchaResponseCode[responseCode];
    case ResponseCodeType.Master:
      return MasterResponseCode[responseCode];
    case ResponseCodeType.OAuth:
      return OAuthResponseCode[responseCode];
    default:
      return undefined;
  }
}

/**
 * Downcases the first character of the input string
 * @param input string to be processed
 * @returns input string but with the first letter in lowercase
 */
function decapitalize(input: string) {
  return `${input[0]?.toLocaleLowerCase()}${input.slice(1)}`;
}
