import { useAppSelector } from 'hooks';
import { ResetPasswordStatus } from 'models';
import ForgotPasswordEmail from './ForgotPasswordEmail/ForgotPasswordEmail';
import ForgotPasswordForm from './ForgotPasswordForm/ForgotPasswordForm';

const ForgotPasswordWrapper = () => {
  const { status } = useAppSelector((state) => state.resetPasswod);

  return status === ResetPasswordStatus.EmailSent ? <ForgotPasswordEmail /> : <ForgotPasswordForm />;
};

export default ForgotPasswordWrapper;
