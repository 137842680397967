import { makeStyles, themeColors, themeTypography } from '@teamviewer/ui-library';

export const useLoginButtonStyle = makeStyles(
  ({ tokens }) =>
    ({
      buttonStyles: {
        width: '100%',
        border: `1px solid ${tokens.colors.greys.grey110}`,
        borderRadius: tokens.borderRadius.m,
        '.ms-Button-label': {
          fontSize: themeTypography.fontSizes.size14,
          fontWeight: themeTypography.fontWeights.regular,
          color: themeColors.type.typePrimary,
        },
        '.ms-Button-icon': {
          marginTop: '-4px',
        },
      },
    }) as const,
);
