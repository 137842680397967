import type { PropsWithChildren } from 'react';
import React from 'react';
import { Stack } from '@teamviewer/ui-library';

import { ScreenSize, useResponsive } from 'hooks/useResponsive';

import { bannerWrapperStyles, formWrapperStyles, mainStyles, modalWrapperStyles } from './Modal.styles';

export const ModalMain = ({ children }: PropsWithChildren<{}>) => (
  <Stack styles={formWrapperStyles} data-testid="modal-main">
    {children}
  </Stack>
);

export const ModalBanner = ({ children }: PropsWithChildren<{}>) => (
  <Stack styles={bannerWrapperStyles} data-testid="modal-banner">
    {children}
  </Stack>
);

const Modal = ({ children }: PropsWithChildren<{}>) => {
  const { screenSize } = useResponsive();
  return (
    <Stack styles={mainStyles} data-testid="modal" as="main">
      <Stack
        styles={modalWrapperStyles}
        horizontal={screenSize > ScreenSize.Laptop}
        horizontalAlign="center"
        data-testid="modal-container"
      >
        {children}
      </Stack>
    </Stack>
  );
};

Modal.Main = ModalMain;
Modal.Banner = ModalBanner;
export default Modal;
