import { makeStyles, themeColors, themeSpacings } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  () =>
    ({
      containerStyles: {
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '700px',
        padding: '48px 24px',
      },
      errorStyle: {
        marginTop: themeSpacings.s,
      },
      suggestionContainerStyles: {
        backgroundColor: themeColors.type.typeWhite,
        borderRadius: '16px',
        margin: themeSpacings.xl,
        padding: themeSpacings.l,
        alignSelf: 'center',
        alignItems: 'start',
        maxWidth: '600px',
      },
      suggestionTitleStyle: {
        fontWeight: 600,
      },
      suggestionTextStyle: {
        marginTop: themeSpacings.l,
        textAlign: 'left',
      },
    }) as const,
);
