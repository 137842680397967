import { useBoolean } from '@fluentui/react-hooks';

import { StayTunedDialog } from './StayTunedDialog';

export const useStayTunedDialog = () => {
  const [hidden, { toggle }] = useBoolean(true);

  return {
    toggle,
    render: () => <StayTunedDialog hidden={hidden} toggle={toggle} />,
  };
};
