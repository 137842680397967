const mockConfig: Partial<AppConfig> = {
  axe: {
    analysisDelayTime: 1000,
    config: {
      rules: [
        {
          id: 'skip-link',
          enabled: true,
        },
      ],
      disableDeduplicate: true,
    },
  },
};

export default mockConfig;
