export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const nameRegex = /^.*\S+.*$/u;

export const isNumericRegex = /^\d+$/;

export const passwordRegex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){6,}$/;

export const containsAtLeast6CharactersRegex = /.{6,}$/;

export const lowerCaseLetterRegex = /(?=.*[a-z]).*$/;

export const upperCaseLetterRegex = /(?=.*[A-Z]).*$/;

export const specialCharacterRegex = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

export const numberRegex = /(?=.*[0-9]).*$/;

export const wwwDomainRegex = /^(.*\s+)?www\.\S+\.\S+/iu;

export const schemaRegexps = ['http', 'https', 'mailto', 'ftp', 'ssh', 'file'].map((schema) =>
  RegExp(`^(.*\\s+)?${schema}://`, 'iu'),
);
