import React, { useEffect, useRef, useState } from 'react';
import type { FieldValues, UseFormProps } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import { useConfigManager } from 'config/useConfigManager';
import { useInitialServiceConfig } from 'config/useInitialServiceConfig';
import { useConfirmationToast } from 'hooks';
import AlibabaCaptcha from '../components/Captcha';
import { useRecaptcha } from './useRecaptcha';

const recaptchaContainerId = 'recaptcha-container';

interface UseFormWithCaptchaProps<TFieldValues extends FieldValues = FieldValues, TContext = any>
  extends UseFormProps<TFieldValues, TContext> {
  onSubmit: (data: TFieldValues & { captchaResponse: string }) => void;
  isVisible?: boolean;
}

function useFormWithCaptcha<TFieldValues extends FieldValues = FieldValues, TContext = any>({
  onSubmit,
  isVisible = false,
  ...formConfig
}: UseFormWithCaptchaProps<TFieldValues, TContext>) {
  const configManager = useConfigManager();
  const recaptchaSiteKey = configManager.get(isVisible ? 'recaptchaSiteKey' : 'invisibleRecaptchaSiteKey');

  const formMethods = useForm(formConfig);
  const onSubmitRef = useRef(onSubmit);
  const formMethodsRef = useRef(formMethods);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [captchaResponse, setCaptchaResponse] = useState('');
  const [alibabaCaptchaComponentKey, setAlibabaCaptchaComponentKey] = useState(0);

  const { requireCaptcha, isAlibabaCaptcha } = useInitialServiceConfig();

  const showAlibabaCaptcha = requireCaptcha && isAlibabaCaptcha;

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
    },
    resetAction: () => setErrorMessage(''),
    showError: errorMessage !== '',
  });

  useEffect(() => {
    onSubmitRef.current = onSubmit;
    formMethodsRef.current = formMethods;

    const captchaBadge = document.getElementById(recaptchaContainerId)?.querySelector<HTMLElement>('.grecaptcha-badge');
    if (captchaBadge) {
      // this will make the captcha element appear above footer
      captchaBadge.style.zIndex = '999';
    }
  });

  const successCallback = (captchaResponse: string) => {
    setCaptchaResponse(captchaResponse);
    if (isVisible || !requireCaptcha) {
      return;
    }
    return formMethodsRef.current.handleSubmit(
      (data) => onSubmitRef.current({ ...data, captchaResponse }),
      (errors) => {
        Object.values(errors).forEach((error: any) => {
          setErrorMessage(error.message);
        });
      },
    )();
  };

  const expiredCallback = () => {
    setCaptchaResponse('');
  };

  const { recaptchaLoaded, recaptchaWidget } = useRecaptcha({
    containerId: recaptchaContainerId,
    sitekey: recaptchaSiteKey,
    size: isVisible ? 'normal' : 'invisible',
    successCallback,
    expiredCallback,
  });

  useEffect(() => {
    if (recaptchaLoaded) {
      // Prevents the iframe from interfering with the tab index of the form

      document.getElementsByTagName('iframe')[0]!.tabIndex = -1;
    }
  }, [recaptchaLoaded]);

  const executeRecaptcha = (e: React.FormEvent) => {
    e.preventDefault();
    if (isVisible || !requireCaptcha || showAlibabaCaptcha) {
      if (showAlibabaCaptcha) {
        setAlibabaCaptchaComponentKey(alibabaCaptchaComponentKey + 1);
      }
      return formMethodsRef.current.handleSubmit(
        (data) => onSubmitRef.current({ ...data, captchaResponse }),
        (errors) => {
          Object.values(errors).forEach((error: any) => {
            setErrorMessage(error.message);
          });
        },
      )();
    }
    if (recaptchaWidget !== null) {
      window.grecaptcha.reset();
      window.grecaptcha.execute(recaptchaWidget);
    }
  };

  const resetRecaptcha = () => {
    if (recaptchaWidget !== null) {
      window.grecaptcha.reset();
    }
  };

  const isReCaptchaSolved = !!captchaResponse;

  const alibabacaptchaComponent = React.useMemo(() => {
    if (showAlibabaCaptcha) {
      return (
        <AlibabaCaptcha
          key={`ac_${alibabaCaptchaComponentKey}`}
          onSolved={(captchaResponse) => setCaptchaResponse(captchaResponse)}
        />
      );
    }
  }, [showAlibabaCaptcha, alibabaCaptchaComponentKey]);

  const captchaComponent = showAlibabaCaptcha ? alibabacaptchaComponent : <div id={recaptchaContainerId} />;

  return {
    ...formMethods,
    recaptchaLoaded: recaptchaLoaded || !requireCaptcha || showAlibabaCaptcha,
    captchaComponent,
    isReCaptchaSolved,
    executeRecaptcha,
    resetRecaptcha,
  };
}

export default useFormWithCaptcha;
