import { useThemes } from '@teamviewer/ui-library';

interface StatusBarStyles {
  statusBarIndicatorStyles: React.CSSProperties;
}

export const useStatusBarStyles = (): StatusBarStyles => {
  const { teamViewer } = useThemes();
  return {
    statusBarIndicatorStyles: {
      height: '8px',
      width: '8px',
      borderRadius: '50%',
      backgroundColor: teamViewer.colors.status.statusErrorTypeOrIcon,
      marginRight: '8px',
    },
  };
};
