import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IRequestTfaActivationResponse, ITfaEnforcementError, ITfaEnforcementState } from 'models';

import { TfaEnforcementResponseCodes, TfaEnforcementStatus } from 'models';
import { confirmTfaActivation, requestTfaActivation } from 'store/enforce-tfa/enforce-tfa.async-actions';

const initialState: ITfaEnforcementState = {
  status: TfaEnforcementStatus.Initial,
  error: {
    isError: false,
    responseCode: TfaEnforcementResponseCodes.NoError,
  },
  nErrors: 0,
};

const isErrorPayloadTfa = (payload: unknown): payload is ITfaEnforcementError =>
  (payload as ITfaEnforcementError)?.isError !== undefined &&
  (payload as ITfaEnforcementError)?.responseCode !== undefined;

const enforceTfaSlice = createSlice({
  name: 'enforceTfa',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestTfaActivation.pending, (state) => {
        state.status = TfaEnforcementStatus.InProgress;
      })
      .addCase(requestTfaActivation.fulfilled, (state, action: PayloadAction<IRequestTfaActivationResponse>) => {
        state.requestTfaActivationResponse = action.payload; // Assuming the payload has requestTfaActivationResponse properties
        state.status = TfaEnforcementStatus.Requested;
        state.error.isError = false;
      })
      .addCase(requestTfaActivation.rejected, (state, action: PayloadAction<unknown>) => {
        const payload = action.payload;

        state.status = TfaEnforcementStatus.Failed;

        if (!isErrorPayloadTfa(payload)) {
          state.error = {
            isError: true,
            responseCode: TfaEnforcementResponseCodes.UnspecifiedError,
          };
          return;
        }

        state.error = payload;
        state.nErrors++;
      })

      .addCase(confirmTfaActivation.pending, (state) => {
        state.status = TfaEnforcementStatus.InProgress;
      })
      .addCase(confirmTfaActivation.fulfilled, (state, action: PayloadAction<{ status: TfaEnforcementStatus }>) => {
        state.status = action.payload.status;
        state.error.isError = false;
      })
      .addCase(confirmTfaActivation.rejected, (state, action: PayloadAction<unknown>) => {
        const payload = action.payload;
        state.status = TfaEnforcementStatus.Failed;

        if (!isErrorPayloadTfa(payload)) {
          state.error = {
            isError: true,
            responseCode: TfaEnforcementResponseCodes.UnspecifiedError,
          };
          return;
        }

        state.error = payload;
        state.nErrors++;
      });
  },
});

export const enforceTfaActions = enforceTfaSlice.actions;
export const enforceTfaReducer = enforceTfaSlice.reducer;
