import { useEffect, useRef } from 'react';

type Props = {
  onSolved: (capcode: string) => void;
};

const AlibabaCaptcha = ({ onSolved }: Props) => {
  const _inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (_inputRef.current) {
      const input = _inputRef.current;
      (input as any)._value = input.value;
      Object.defineProperty(input, 'value', {
        get() {
          return this._value;
        },
        set(v) {
          onSolved(v);
          this._value = v;
        },
      });
    }
  }, [onSolved]);

  return (
    <div id="alibabacaptcha">
      <input id="alibabacaptcha-response" type="hidden" defaultValue="" ref={_inputRef} />
      <iframe
        id="captcha_iframe"
        src={`${process.env.PUBLIC_URL}/alibabaCaptchaContent.html`}
        style={{ overflow: 'hidden', border: '0', width: 305, height: 55 }}
        key="captcha_iframe"
        frameBorder="0"
        scrolling="no"
      />
    </div>
  );
};

export default AlibabaCaptcha;
