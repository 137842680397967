const rcStagingConfig: Partial<AppConfig> = {
  defaultRedirectUri: 'https://rc.staging.web.tvwebservices.org/authenticate/withtoken',
  webClientUri: 'https://release.staging.web.tvwebservices.org/',
  invisibleRecaptchaSiteKey: '6LfQAOEUAAAAAPZdpGdeDOClQd_2NDWjPDJg_cDp',
  recaptchaSiteKey: '6Le6AOEUAAAAAGZwPSdtdtzqFlQOwTUtKe4TN3jk',
  isTestMaster: false,
  adobeDtmUrl: 'https://assets.adobedtm.com/439d99b3b8f0/eec744300fc5/launch-2ca96c33f9ad-staging.min.js',
  masterPublicKey: {
    kty: 'RSA',
    e: 'AQAB',
    n: '42aAkXhpKwMr_QldLalDjSw1l2x52vQ-L15FTc6lM6SGzyJZk7KoUNpvvAS-Lk3HAtc3Y-otvEAgkwaQDoSuynmkhkoVoZGAgYqq4rWTX-bbex8VOdW9cg8wAyAb056-KDYarODqOSgphuPBq6eB-RvPUgnic7AuiKAoTkF0H4c',
  },
};

export default rcStagingConfig;
