import { useContext } from 'react';

import { configContext, createConfigManager } from 'config/config-manager';

export const useConfigManager = () => {
  const ctx = useContext(configContext);

  if (!ctx?.configManager) {
    if (process.env.NODE_ENV === 'production') {
      throw Error('Attempt to access config manager before its provider has been initialized!');
    }
    // Only for unit tests
    return createConfigManager('test');
  }

  return ctx.configManager;
};
