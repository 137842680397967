import { useCallback, useState } from 'react';

export const useSingleAndDoubleClicks = () => {
  const [clickTimer, setClickTimer] = useState<NodeJS.Timeout | null>(null);

  const handleResetTimerAndDoubleClick = useCallback(
    (doubleClick: () => unknown) => {
      clearTimeout(clickTimer!);
      setClickTimer(null);
      doubleClick();
    },
    [clickTimer],
  );

  const handleResetTimerAndSingleClick = useCallback((singleClick: () => unknown) => {
    setClickTimer(null);
    singleClick();
  }, []);

  const handleClicks = useCallback(
    (singleClick: () => unknown, doubleClick: () => unknown) => {
      if (clickTimer !== null) {
        // second click
        handleResetTimerAndDoubleClick(doubleClick);
      } else {
        // first click, wait if there is a second click
        const timeoutId = setTimeout(() => {
          // no second click
          handleResetTimerAndSingleClick(singleClick);
        }, 400);
        // update due to first click
        setClickTimer(timeoutId);
      }
    },
    [clickTimer, handleResetTimerAndDoubleClick, handleResetTimerAndSingleClick],
  );

  return { handleClicks };
};
