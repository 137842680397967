import type { IDialogStyles } from '@teamviewer/ui-library';
import { Subject } from 'rxjs';

export interface IDialogProps {
  title?: string;
  content: (closeDialog: () => void) => React.ReactNode;
  styles?: Partial<IDialogStyles>;
  minWidth?: string;
  onDismissed?: () => void;
}

export const dialogEventEmitter = new Subject<IDialogProps>();

export function showDialog(props: IDialogProps) {
  dialogEventEmitter.next(props);
}
