import { makeStyles, spacing, themeTypography } from '@teamviewer/ui-library';

import { themeBreakpoints } from 'components/Theme';

export const useFullPageLayotStyles = makeStyles(
  ({ tokens }) =>
    ({
      fullPageLayoutStyles: {
        padding: `0 ${tokens.spacings.m}`,
        textAlign: 'center',
      },
      titleStyles: {
        marginTop: spacing(11),
        marginBottom: 0,
        fontSize: themeTypography.fontSizes.size32,
        whiteSpace: 'pre-line',
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          fontSize: themeTypography.fontSizes.size28,
        },
      },
      buttonContainerStyles: {
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
        },
      },
      descriptionStackSyles: {
        width: spacing(204),
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
        },
      },
      descriptionStyles: {
        marginTop: tokens.spacings.m,
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: themeTypography.fontSizes.size16,
      },
      buttonStyles: {
        marginTop: spacing(10),
        [`@media (max-width: ${themeBreakpoints.mobile}px)`]: {
          width: '100%',
        },
      },
      optionalTextStyles: {
        marginTop: tokens.spacings.l,
        whiteSpace: 'pre-line',
        textAlign: 'center',
        fontSize: tokens.typography.fontSizes.size12,
        fontWeight: tokens.typography.fontWeights.regular,
      },
    }) as const,
);
