import { makeStyles } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      textWrapStyles: {
        whiteSpace: 'normal',
      },
      titleStyles: {
        fontSize: tokens.typography.fontSizes.size20,
        fontWeight: tokens.typography.fontWeights.semibold,
      },
      descriptionStyles: {
        marginTop: tokens.spacings.s,
        marginBottom: tokens.spacing(6),
        fontSize: tokens.typography.fontSizes.size14,
        fontWeight: tokens.typography.fontWeights.regular,
        color: tokens.colors.greys.grey130,
      },
      optionalTextStyles: {
        marginTop: tokens.spacings.xl,
      },
      toastContainerStyles: {
        marginTop: tokens.spacing(6),
      },
      descriptionTextStyles: {
        lineHeight: tokens.spacing(5),
      },
      emailTextStyles: {
        fontWeight: tokens.typography.fontWeights.semibold,
        lineHeight: tokens.spacing(5),
        color: tokens.colors.type.typePrimary,
      },
      checkboxStyles: {
        marginTop: tokens.spacing(6),
      },
      buttonStyles: {
        marginTop: tokens.spacings.l,
      },
    }) as const,
);
