export class Guid {
  private readonly guidString: string;

  constructor(fromString?: string) {
    this.guidString = fromString || generateGuidString();
  }

  public static Null = new Guid('00000000-0000-0000-0000-000000000000');

  public isNullGuid = () => this.guidString === '00000000-0000-0000-0000-000000000000';

  public toString = () => this.guidString;

  public toJSON = () => this.guidString;

  public valueOf = () => this.guidString;

  public isEqual = (otherGuid: Guid) => this.toString() === otherGuid.toString();
}

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const GUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

export function generateGuidString() {
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export const isGuid = (value: string): boolean => !!value && GUID_REGEX.test(value);
