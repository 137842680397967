import { useState } from 'react';
import type { DirectionalHint } from '@teamviewer/ui-library';
import { Icon, Stack, TooltipHint } from '@teamviewer/ui-library';

import {
  infoIconHoveredStyles,
  newsletterInformationItemStyles,
  newsletterInformationStackStyles,
} from './InfoIconWithTextAndTooltip.styles';

interface IInfoIconWithTextAndTooltipProps {
  showTooltip: boolean;
  tooltipContent: string | JSX.Element;
  tooltipDirection?: DirectionalHint;
  content: string | JSX.Element;
  tooltipDelay?: number;
}

const InfoIconWithTextAndTooltip = ({
  content,
  showTooltip,
  tooltipContent,
  tooltipDelay,
  tooltipDirection,
}: IInfoIconWithTextAndTooltipProps) => {
  const [showFilledIcon, setShowFilledIcon] = useState(false);

  return (
    <Stack horizontal styles={newsletterInformationStackStyles}>
      <Stack.Item>
        <TooltipHint
          hide={!showTooltip || !tooltipContent}
          content={tooltipContent}
          direction={tooltipDirection}
          delay={tooltipDelay ?? 0}
        >
          <span onMouseEnter={() => setShowFilledIcon(true)} onMouseLeave={() => setShowFilledIcon(false)}>
            {showFilledIcon ? (
              <Icon iconName="Info16FilledIcon" styles={infoIconHoveredStyles} />
            ) : (
              <Icon iconName="Info16RegularIcon" />
            )}
          </span>
        </TooltipHint>
      </Stack.Item>
      <Stack.Item styles={newsletterInformationItemStyles}>{content}</Stack.Item>
    </Stack>
  );
};

export default InfoIconWithTextAndTooltip;
