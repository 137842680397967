import type * as Models from 'models';

import { Banner, Modal, ModalBanner, ModalMain } from 'components';
import TwoFactorForm from './TwoFactorForm';
import TwoFactorHeader from './TwoFactorHeader';

export interface ILocationState {
  tfaFor: Models.TfaFor;
  params?: any;
}

const TwoFactorAuthentication = () => (
  <Modal>
    <ModalMain>
      <TwoFactorHeader />
      <TwoFactorForm />
    </ModalMain>
    <ModalBanner>
      <Banner.JustAStepAway />
    </ModalBanner>
  </Modal>
);

export default TwoFactorAuthentication;
