import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { spacing, Stack, TextFormInput, TextPrimary, TextSecondary, themeSpacings } from '@teamviewer/ui-library';

import { Banner, Modal, ModalBanner, ModalMain, SubmitButton } from 'components';
import { useConfigManager } from 'config/useConfigManager';
import { useAppDispatch, useAppSelector, useConfirmationToast, useErrorMessage, useFormWithCaptcha } from 'hooks';
import { ResetPasswordStatus, ResetPasswordType } from 'models';
import { resetPasswordActions, sendForgotPasswordEmailAction } from 'store';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';
import { getEmailValidationRules } from 'utils/validationRules';

import { useStyles } from './ForgotPasswordForm.styles';

const ForgotPassword = () => <ForgotPasswordInternal />;

export const ForgotPasswordInternal = ({ skipCaptcha }: { skipCaptcha?: boolean }) => {
  const { t } = useTranslation(['forgotPassword', 'common', 'validation', 'login', 'banner']);
  const dispatch = useAppDispatch();
  const { status } = useAppSelector((state) => state.resetPasswod);
  const [searchParams] = useSearchParams();
  const preSetEmail = searchParams.get('email') ?? '';
  const configManager = useConfigManager();
  const redirectUri = searchParams.get('redirect_uri') ?? configManager.get('defaultRedirectUri');
  const isLoading = status === ResetPasswordStatus.InProgress;
  const { errorMessage, errorCode } = useErrorMessage((state) => state.resetPasswod.error);

  const { formSectionStyles, optionalTextStyles, subtitleStyles, titleStyles } = useStyles();

  useConfirmationToast({
    errorProps: {
      icon: 'WarningIcon',
      message: errorMessage,
      errorCode,
    },
    showError: errorMessage !== '',
    resetAction: () => dispatch(resetPasswordActions.setError({ isError: false })),
  });

  const {
    control,
    watch,
    captchaComponent,
    recaptchaLoaded,
    isReCaptchaSolved,
    executeRecaptcha,
    formState: { isValid, dirtyFields, submitCount },
  } = useFormWithCaptcha<any>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    isVisible: true,
    defaultValues: { email: preSetEmail },
    onSubmit(data) {
      dispatch(
        sendForgotPasswordEmailAction({
          passwordType: ResetPasswordType.Password,
          email: data.email,
          redirectUri,
          'captcha-response': data.captchaResponse,
        }),
      );
    },
  });

  const email = watch('email');

  const { linkStyles } = useLinkStyles();

  useEffect(() => {
    if (email) {
      dispatch(resetPasswordActions.setEmail(email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  return (
    <Modal>
      <ModalMain>
        <Stack>
          <Stack.Item>
            <Stack as="header">
              <TextPrimary
                variant="xxLarge"
                nowrap
                block
                className={titleStyles}
                data-testid="forgot-password-reset-title"
              >
                {t('title')}
              </TextPrimary>
              <TextSecondary
                variant="medium"
                className={subtitleStyles}
                nowrap
                block
                data-testid="forgot-password-reset-subtitle"
              >
                {t('subtitle')}
              </TextSecondary>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <form onSubmit={executeRecaptcha} noValidate data-testid="forgot-password-reset-email-form">
              <Stack className={formSectionStyles} tokens={{ childrenGap: themeSpacings.l }}>
                <Stack.Item>
                  <TextFormInput
                    control={control}
                    name="email"
                    autoFocus
                    tabIndex={1}
                    inputMode="email"
                    rules={getEmailValidationRules(t)}
                    autoComplete="email"
                    label={t('emailInput')}
                    ariaLabel={t('emailInput')}
                    data-testid="forgot-password-reset-email-input"
                    disabled={isLoading}
                  />
                </Stack.Item>
                <Stack.Item>{captchaComponent}</Stack.Item>
                <Stack tokens={{ childrenGap: spacing(8) }}>
                  <Stack.Item>
                    <SubmitButton
                      label={t('send')}
                      tabIndex={2}
                      checkedLabel={t('sent')}
                      isValid={
                        isValid ||
                        (Object.values(dirtyFields).length === 1 &&
                          submitCount === 0 &&
                          (isReCaptchaSolved || !!skipCaptcha))
                      }
                      isLoading={isLoading || !recaptchaLoaded}
                      isCheckmarkVisible={false}
                      data-testid="forgot-password-reset-submit-btn"
                    />
                  </Stack.Item>
                  <Stack.Item className={optionalTextStyles}>
                    {splitAndStyleString(
                      t('rememberedThePassword', {
                        link: `<> ${t('signIn')}</>`,
                      }),
                      /<>|<\/>/,
                      linkStyles,
                      `/?email=${email}`,
                      3,
                      '_self',
                      'forgot-password-reset-remembered',
                      isLoading,
                    )}
                  </Stack.Item>
                </Stack>
              </Stack>
            </form>
          </Stack.Item>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default ForgotPassword;
