import { BigInteger } from 'jsbn';

import { clientProofSize, g, N, privateKeySize, rawNSize, saltSize } from './Parameters';
import {
  BigIntegerToBase64,
  BigIntegerToUint8Array,
  generateRandomBytes,
  H,
  Uint8ArrayToBigInteger,
} from './utilities';

export function isServerPublicEphemeral_BValid(serverPublicEphemeral_B: string) {
  const value = new BigInteger(serverPublicEphemeral_B, 16);

  return value.bitLength() <= N.bitLength() && !value.equals(BigInteger.ZERO) && !value.equals(N);
}

export function generateSalt(size: number = saltSize) {
  const saltByteArray = generateRandomBytes(size);

  return Uint8ArrayToBigInteger(saltByteArray);
}

export async function calculateClientPrivateKey_x(identifier: string, password: string, salt: Uint8Array) {
  const text = identifier + ':' + password;
  const textByteArray = new TextEncoder().encode(text);

  // x = H(salt || H(username || ":" || password))

  const x = await H(salt, await H(textByteArray));
  const xSliced = x.slice(0, privateKeySize);

  return Uint8ArrayToBigInteger(xSliced);
}

export function calculateVerifier(x: BigInteger) {
  // v = g^x (mod N)
  return g.modPow(x, N);
}

export function generateClientSecretEphemeral_a(size: number = rawNSize) {
  let a: BigInteger;

  do {
    const aByteArray = generateRandomBytes(size);
    a = Uint8ArrayToBigInteger(aByteArray);
  } while (a.equals(BigInteger.ZERO) || a.equals(N.subtract(BigInteger.ONE)) || a.compareTo(N) > 0);

  return a;
}

export function calculateClientPublicEphemeral_A(a: BigInteger) {
  // A = g^a (mod N)
  return g.modPow(a, N);
}

export async function calculate_u(B: Uint8Array) {
  // u = first 4 bytes of H(B) byte array

  const BHash = await H(B);
  const u = BHash.slice(0, 4);

  // If the received server B results in u being Zero exception will be thrown and the authentication will be aborted
  if (Uint8ArrayToBigInteger(u).equals(BigInteger.ZERO)) {
    throw new Error('Faild to complete authentication');
  }

  return u;
}

export async function calculateSessionKey_K(
  B: BigInteger,
  x: BigInteger,
  a: BigInteger,
  u: BigInteger,
  keysMapIn: Map<string, string>,
) {
  // exp = (a + ux) (mod N-1)
  const exp = a.add(u.multiply(x)).mod(N.subtract(BigInteger.ONE));

  // S = (B - g^x (mod N)) ^ ((a + ux) (mod N-1)) (mod N)
  const S = B.subtract(g.modPow(x, N)).modPow(exp, N);
  const SByteArray = BigIntegerToUint8Array(S);

  const K = await H(SByteArray);

  // generate the secrets that can be used for various encryption purposes
  // between those two steps here we can securely fill the keys
  const keysMapOut = await fillKeys(K, keysMapIn);

  const KSecret = await H(K);

  const KSecretSliced = KSecret.slice(0, clientProofSize);

  return {
    K: KSecretSliced,
    keysMapOut,
  };
}

export async function calculateClientProof_M(
  identifier: string,
  salt: Uint8Array,
  A: Uint8Array,
  B: Uint8Array,
  K: Uint8Array,
) {
  const I_ByteArray = new TextEncoder().encode(identifier);

  const M1 = await H(BigIntegerToUint8Array(N));
  const M2 = await H(BigIntegerToUint8Array(g));
  const M3 = await H(I_ByteArray);

  const M12 = Uint8Array.from(M2, (v, i) => v ^ M1[i]!);

  // M = H(H(N) xor H(g), H(I), s, A, B, K)
  const M = await H(M12, M3, salt, A, B, K);

  return M;
}

async function fillKeys(secret: Uint8Array, keysMap: Map<string, string>) {
  for (const key of keysMap.keys()) {
    const keyArray = new TextEncoder().encode(key);
    const mergedArray = new Uint8Array(secret.length + keyArray.length);
    mergedArray.set(secret);
    mergedArray.set(keyArray, secret.length);
    keysMap.set(key, BigIntegerToBase64(Uint8ArrayToBigInteger(await H(mergedArray))));
  }
  return keysMap;
}
