import { useTranslation } from 'react-i18next';
import type { ListChildComponentProps } from 'react-window';
import { FixedSizeList } from 'react-window';
import { IconButton, Modal } from '@teamviewer/ui-library';
import { chunk } from 'lodash';

import { useIconButtonStyles } from './CopyrightModal.styles';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const ROWS = chunk(COPYRIGHT_ATTRIBUTION.split('\n'), 10);
const Row = ({ index, style }: ListChildComponentProps) => <div style={style}>{ROWS[index]!.join('\n')}</div>;

const CopyrightModal = ({ isOpen, close }: Props) => {
  const { t } = useTranslation('common');
  const classes = useIconButtonStyles();

  return (
    <div>
      <Modal
        titleAriaId="copyright-modal"
        isOpen={isOpen}
        onDismiss={close}
        isBlocking={false}
        containerClassName={classes.container}
      >
        <div className={classes.header} data-testid="footer-copyright-dialog">
          <h1>{t('copyright')}</h1>
          <IconButton
            className={classes.iconStyle}
            size="large"
            iconProps={{ iconName: 'Cancel' }}
            ariaLabel="Close popup modal"
            onClick={close}
            data-testid="footer-copyright-close-btn"
          />
        </div>
        <div className={classes.body}>
          <h2>{t('copyrightViewHeadline').toLocaleUpperCase()}</h2>
          <pre>
            <FixedSizeList height={700} itemCount={ROWS.length} itemSize={170} width={650}>
              {Row}
            </FixedSizeList>
          </pre>
        </div>
      </Modal>
    </div>
  );
};

export default CopyrightModal;
