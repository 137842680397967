import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { spacing, Stack, TextPrimary, TextSecondary } from '@teamviewer/ui-library';

import { Banner, InfoContainer, Modal, ModalBanner, ModalMain } from 'components';
import { IconSize } from 'components/InfoContainer/InfoContainer.model';
import { useAppSelector, useResponsive } from 'hooks';
import { ScreenSize } from 'hooks/useResponsive';
import { useLinkStyles } from 'utils/commonStyles';
import { splitAndStyleString } from 'utils/stringUtils';

import { useStyles } from './ForgotEncryptionKeyEmailSent.styles';

const ForgotEncryptionKeyEmailSent = () => {
  const { t } = useTranslation(['forgotEncryptionKey', 'toastMessage']);
  const { screenSize } = useResponsive();
  const { email } = useAppSelector((state) => state.resetPasswod);
  const [searchParams] = useSearchParams();
  const idProvider = searchParams.get('id_provider') || '';
  const idToken = searchParams.get('id_token');

  const { descriptionTextStyles, emailTextStyles, optionalTextStyles, titleStyles, textWrapStyles } = useStyles();

  const { linkStyles } = useLinkStyles();

  return (
    <Modal>
      <ModalMain>
        <Stack tokens={{ childrenGap: spacing(2) }}>
          <TextPrimary data-testid="forgot-encryption-key-verify-title" className={titleStyles}>
            {t('checkEmailTitle')}
          </TextPrimary>
          <Stack>
            <InfoContainer
              icon="/assets/email_inbox.svg"
              iconAccessibility={t('emailSent')}
              iconSize={IconSize.big}
              text={
                <TextSecondary
                  variant="medium"
                  data-testid="forgot-encryption-key-verify-email"
                  className={descriptionTextStyles}
                >
                  {splitAndStyleString(
                    t('checkEmailDescription', { email: `<b>${email}</b>` }),
                    /<b>|<\/b>/,
                    emailTextStyles,
                  )}
                </TextSecondary>
              }
              vertical={screenSize <= ScreenSize.Laptop}
              textWrapStyles={textWrapStyles}
            />
          </Stack>
          <Stack>
            <Stack.Item className={optionalTextStyles}>{t('checkSpamFolder')}</Stack.Item>
          </Stack>
          <Stack>
            <Stack.Item className={optionalTextStyles}>
              {splitAndStyleString(
                t('rememberedEncryptionKey', {
                  link: `<>${t('signIn')}</>`,
                }),
                /<>|<\/>/,
                linkStyles,
                `/sociallogin/signin/?email=${email}&id_provider=${idProvider}&id_token=${idToken}`,
                2,
                '_self',
                'forgot-encryption-key-verify-remembered',
              )}
            </Stack.Item>
          </Stack>
        </Stack>
      </ModalMain>
      <ModalBanner>
        <Banner.Security />
      </ModalBanner>
    </Modal>
  );
};

export default ForgotEncryptionKeyEmailSent;
