import { makeStyles, themeSpacings } from '@teamviewer/ui-library';

export const useStyles = makeStyles(
  ({ tokens }) =>
    ({
      containerStyles: {
        alignItems: 'center',
        alignSelf: 'center',
        maxWidth: '700px',
      },
      imgStyle: {
        maxWidth: '312px',
        width: '100%',
      },
      helpStyles: {
        marginTop: themeSpacings.m,
        fontSize: tokens.typography.fontSizes.size16,
        lineHeight: '20px',
      },
    }) as const,
);
