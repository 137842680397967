import { ofType } from 'redux-observable';
import { catchError, from, map, of, switchMap } from 'rxjs';

import type { VerifyAccountStatus } from './verify-account.slice';
import { verifyAccountActions } from './verify-account.slice';

export const verifyAccountEpic: Epic = (action$, _state$, { api }) =>
  action$.pipe(
    ofType(verifyAccountActions.fetch.type),
    switchMap((_action) =>
      from(api.get<{ status: VerifyAccountStatus }>('/verify')).pipe(
        map((response) => verifyAccountActions.update(response?.data?.status)),
        catchError(() => of(verifyAccountActions.failed())),
      ),
    ),
  );
