import type { IDropdownStyles, IStackStyles } from '@teamviewer/ui-library';
import { themeColors, themeSpacings, themeTypography } from '@teamviewer/ui-library';

export const footerContainerStyles: IStackStyles = {
  root: {
    position: 'absolute',
    bottom: themeSpacings.sm,
    paddingLeft: themeSpacings.m,
    paddingRight: themeSpacings.m,
    left: 0,
    right: 0,
    width: '100%',
  },
};

export const changeRegion: Partial<IDropdownStyles> = {
  root: {
    '.ms-Dropdown': {
      paddingRight: 7,
    },
  },
  title: {
    selectors: {
      ':active': {
        textDecoration: 'none',
      },
    },
    border: 'none',
    backgroundColor: 'none',
    fontStyle: 'normal',
    fontWeight: themeTypography.fontWeights.regular,
    fontSize: themeTypography.fontSizes.size12,
    color: themeColors.greys.grey130,
    lineHeight: '16px',
    display: 'inline-flex',
    alignItems: 'center',
    paddingLeft: '0 !important',
    paddingRight: `${themeSpacings.xl} !important`,
  },
  caretDownWrapper: {
    paddingRight: `${themeSpacings.s} !important`,
  },
};
