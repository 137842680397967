export enum ResetPasswordStatus {
  EmailSent = 'EmailSent',
  Verifying = 'Verifying',
  InvalidToken = 'InvalidToken',
  ValidToken = 'ValidToken',
  InProgress = 'InProgress',
  TfaSecurityCodeRequired = 'TfaSecurityCodeRequired',
  AuthenticationFailed = 'AuthenticationFailed',
  Successed = 'Successed',
  Failed = 'Failed',
}
